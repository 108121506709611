/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import FormItem from 'antd/es/form/FormItem'
import React, { useCallback, useEffect, useState } from 'react'
import useGetQuestions, {
  URL_GET_WORK_INVEST,
  URL_GET_WORK_NEEDS,
} from '../../../api/application/use-get-questions'
import { Form } from '../../form/form'
import { Checkbox, Spin } from 'antd'
import { Title } from '../../typography'
import { Input } from '../../input'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { useGetPublic } from '../../../api/application/use-get-public'
import { URL_GET_EIGHTH_PAGE_DATA } from '../../../api/application/constants'

const createName = (id) => `item${id}`

const WorkInvestment = ({ parentProp, disabled }) => {
  const { data: needQuestions, isLoading: isLoadingNeeds } =
    useGetQuestions(URL_GET_WORK_NEEDS)
  const { data: investQuestions, isLoading: isLoadingInvest } =
    useGetQuestions(URL_GET_WORK_INVEST)
  const form = Form.useFormInstance()
  const [needCheckedIds, setNeedCheckedIds] = useState({})
  const [investCheckIds, setInvestCheckIds] = useState({})

  /* eslint-disable no-console */

  const { data, isFetching } = useGetPublic(
    URL_GET_EIGHTH_PAGE_DATA,
    {
      householdId: form.getFieldValue('appId'),
    },
    {
      // enabled: shouldFetch && !!getHouseHold && (status === 1 || status === 2),
      enabled: !form.getFieldValue('appId'),
    },
  )

  useEffect(() => {
    if (data) {
      const personWorksData = data?.personWorks?.reduce((acc, person) => {
        person?.afterWorkTypes?.forEach((workType) => {
          workType?.workNeeds?.forEach((workNeed) => {
            acc[`item${workNeed.id}`] = {
              ...workNeed,
            }
          })
        })
        return acc
      }, {})

      setNeedCheckedIds(personWorksData)
    }
  }, [data, form])

  /* eslint-disable no-console */

  // useEffect(() => {
  //   const setInitialCheckedState = (items) => {
  //     const initialCheckedIds = {}
  //     const traverse = (itemList) => {
  //       itemList.forEach((item) => {
  //         const itemName = createName(item.id)
  //         const isChecked = form.getFieldValue([...parentProp, 'isActive'])
  //         initialCheckedIds[itemName] = !!isChecked
  //
  //         if (item.children && item.children.length > 0) {
  //           traverse(item.children)
  //         }
  //       })
  //     }
  //     traverse(items)
  //     return initialCheckedIds
  //   }
  //
  //   const setInitialCheckedStateInvest = (items) => {
  //     const initialCheckedInvestIds = {}
  //     const traverse = (itemList) => {
  //       itemList.forEach((item) => {
  //         const itemName = createName(item.id)
  //         const isChecked = form.getFieldValue([...parentProp, 'isActive'])
  //         initialCheckedInvestIds[itemName] = !!isChecked
  //
  //         if (item.children && item.children.length > 0) {
  //           traverse(item.children)
  //         }
  //       })
  //     }
  //     traverse(items)
  //     return initialCheckedInvestIds
  //   }
  //
  //   const initialCheckedIds = setInitialCheckedState(needQuestions)
  //   const initialCheckedIdsInvest =
  //     setInitialCheckedStateInvest(investQuestions)
  //   setNeedCheckedIds(initialCheckedIds)
  //   setInvestCheckIds(initialCheckedIdsInvest)
  // }, [form, needQuestions, investQuestions, parentProp])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, parent, item) => {
      // Update the parent state
      if (parent === 'workNeeds') {
        setNeedCheckedIds((prevCheckedIds) => ({
          ...prevCheckedIds,
          [itemName]: checked,
        }))
      } else {
        setInvestCheckIds((prevCheckedIds) => ({
          ...prevCheckedIds,
          [itemName]: checked,
        }))
      }

      // Update the comment field value when the checkbox is checked
      form.setFields([
        {
          name: [...parentProp, 'id'],
          value: checked ? itemId : false,
        },
        {
          name: [...parentProp, 'comment'],
          value: checked ? item.comment || '' : '', // Update comment based on checkbox state
        },
        {
          name: [...parentProp, 'isActive'],
          value: checked ? true : false,
        },
      ])

      if (!checked && item?.children?.length > 0) {
        item.children.forEach((child) => {
          const childName = createName(child.id)
          if (parent === 'workNeeds') {
            setNeedCheckedIds((prevCheckedIds) => ({
              ...prevCheckedIds,
              [childName]: false,
            }))
          } else {
            setInvestCheckIds((prevCheckedIds) => ({
              ...prevCheckedIds,
              [childName]: false,
            }))
          }
          form.setFields([
            {
              name: [...parentProp, 'id'],
              value: false,
            },
            {
              name: [...parentProp, 'comment'],
              value: '', // Reset comment when unchecking
            },
            {
              name: [...parentProp, 'isActive'],
              value: false, // Reset comment when unchecking
            },
          ])
        })
      }
      const aaa = ['personWorks', 0, 'afterWorkTypes', 'item1', 'workNeeds']
      form.setFieldsValue({
        [aaa]: needCheckedIds,
      })
    },
    [form, needCheckedIds, parentProp],
  )

  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  // eslint-disable-next-line no-unused-vars
  const handleInputChange = useCallback(
    (itemName, value, parent) => {
      form.setFields([
        {
          name: [...parentProp, parent, itemName, 'comment'],
          value: value || '',
        },
      ])
    },
    [form, parentProp],
  )

  const validateInput = (parent, itemName) => {
    const val = form.getFieldValue([...parentProp, parent, itemName, 'comment'])
    return val?.length > 0
  }

  const renderItems = (items, parent, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)

      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentProp, parent, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(
                    items,
                    parent === 'workNeeds' ? needCheckedIds : investCheckIds,
                  )
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={
                parent === 'workNeeds'
                  ? needCheckedIds[itemName]
                  : investCheckIds[itemName]
              }
              disabled={disabled}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  parent,
                  item,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {(parent === 'workNeeds'
            ? needCheckedIds[itemName]
            : investCheckIds[itemName]) &&
            item.hasComment && (
              <>
                <FormItem
                  name={[...parentProp, parent, itemName, 'comment']}
                  style={{ marginLeft }}
                  rules={[
                    {
                      validator: () =>
                        validateInput(parent, itemName)
                          ? Promise.resolve()
                          : Promise.reject(new Error('Պարտադիր դաշտ')),
                      validateTrigger: 'onSubmit',
                    },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      handleInputChange(itemName, e.target.value, parent)
                    }
                    value={1}
                    disabled={disabled}
                    placeholder="Նշել"
                  />
                </FormItem>
              </>
            )}
          {item.children &&
            item.children.length > 0 &&
            (parent === 'workNeeds'
              ? needCheckedIds[itemName]
              : investCheckIds[itemName]) &&
            renderItems(item.children, parent, level + 1)}
        </React.Fragment>
      )
    })
  }

  return (
    <Spin spinning={isLoadingNeeds || isLoadingInvest}>
      <FormSectionFullCard>
        <FormItem
          name={[...parentProp, 'workNeeds']}
          label={
            <Title>Զբաղվածության ապահովման հարցում ի՞նչ կարիքներ ունեք</Title>
          }
        >
          {renderItems(needQuestions, 'workNeeds')}
        </FormItem>

        <FormItem
          name={[...parentProp, 'workInvests']}
          label={
            <Title>
              Ի՞նչ ռեսուրսներ կարող եք ներդնել Ձեր զբաղվածության կազմակերպման
              հարցում
            </Title>
          }
        >
          {renderItems(investQuestions, 'workInvests')}
        </FormItem>
      </FormSectionFullCard>
    </Spin>
  )
}

export default WorkInvestment
