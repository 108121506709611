import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_FOOD_SOURCE } from './constant'
import React, { useCallback, useEffect, useState } from 'react'
import { Form, Input } from 'antd'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import { FormSectionCard } from '../../card/form-section-card'

const createName = (id) => `item${id}`

export const WhatThreeMainSources = ({ getData, disabled }) => {
  const { data: questions } = useGetQuestions(URL_GET_FOOD_SOURCE)

  const [checkedIds, setCheckedIds] = useState({})
  const [disable, setDisable] = useState(false)
  const [disableLast, setDisableLast] = useState(false)
  const form = Form.useFormInstance()
  const appliancesChecklist = Form.useWatch('foodSource', {
    preserve: true,
  })

  useEffect(() => {
    if (getData) {
      const foodSourceTypes = getData.foodSource || []

      const formValues = { foodSource: {} }
      const updatedCheckedIds = {}

      foodSourceTypes.forEach((item) => {
        const itemName = createName(item.id)
        formValues.foodSource[itemName] = {
          id: item.id,
          comment: item.comment || '',
        }
        updatedCheckedIds[itemName] = true
      })

      form.setFieldsValue(formValues)
      setCheckedIds(updatedCheckedIds)
    }
  }, [getData, form])

  const foodSourceValue = []

  useEffect(() => {
    const checkedKeys = Object.keys(checkedIds)

    if (checkedIds?.item11 && checkedKeys.length > 1) {
      form.setFields([
        {
          name: ['foodSource'],
          value: { item11: { id: 11 } },
        },
      ])
      setCheckedIds({ item11: true })
    } else if (checkedIds?.item11) {
      setDisable(true)
    } else if (checkedKeys.some((key) => key !== 'item11' && checkedIds[key])) {
      setDisableLast(true)
    } else {
      setDisableLast(false)
      setDisable(false)
    }
  }, [checkedIds, form])

  if (appliancesChecklist) {
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(appliancesChecklist)) {
      if (
        value &&
        value.id !== false &&
        value.id !== 12 &&
        value.id !== 13 &&
        value.id !== 14
      ) {
        foodSourceValue.push({
          id: value.id,
          comment: value.comment || '', // If comment is not provided, default to an empty string
        })
      }
    }
  }

  form.setFieldsValue({
    foodSourceValue: foodSourceValue,
  })

  const validateCheckboxGroup = (items) => {
    const checkedItems = Object.entries(checkedIds)
      .filter(
        ([key, value]) =>
          value && !['item12', 'item13', 'item14'].includes(key),
      )
      .map(([key]) => key)

    if (checkedItems.length < 1) {
      return false
    }

    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }

      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }

    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      if (itemId === 6 && !checked) {
        // Find the item with itemName in the questions array
        const parentItem = questions.find(
          (item) => createName(item.id) === itemName,
        )
        if (parentItem && parentItem.children) {
          parentItem.children.forEach((child) => {
            const childName = createName(child.id)
            setCheckedIds((prevCheckedIds) => ({
              ...prevCheckedIds,
              [childName]: false,
            }))

            form.setFieldsValue({
              useCashValue: false,
              useCashlessValue: false,
              useCreditValue: false,
            })
          })
        }
      }
      if (itemId === 12) {
        form.setFieldsValue({
          useCashValue: checked,
        })
      }
      if (itemId === 13) {
        form.setFieldsValue({
          useCashlessValue: checked,
        })
      }
      if (itemId === 14) {
        form.setFieldsValue({
          useCreditValue: checked,
        })
      }

      form.setFields([
        {
          name: ['foodSource', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: ['foodSource', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form, questions],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem hidden name={'foodSourceValue'} />
          <FormItem hidden name={'useCashValue'} />
          <FormItem hidden name={'useCashlessValue'} />
          <FormItem hidden name={'useCreditValue'} />
          <FormItem
            name={['foodSource', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error('Անհրաժեշտ է նշել նվազագույնը մեկ արժեք'),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
              disabled={disabled || (item.id !== 11 ? disable : disableLast)}
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={['foodSource', itemName, 'comment']}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input disabled={disabled} />
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }
  return (
    <FormSectionCard
      title={
        'Որո՞նք են Ձեր ընտանիքի համար սնունդ ձեռք բերելու հիմնական երեք աղբյուրները'
      }
      showRequired={true}
    >
      {renderItems(questions)}
    </FormSectionCard>
  )
}
