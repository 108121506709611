import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  nonPsychicSupportReasons,
  psychicSpecialistTypes,
  psychicSupportTerminationReasons,
} from '../../../fill-application/constatns'
import { FormSectionFullCard } from '../../../card/form-section-full-card'
import { FormItem } from '../../form-item'
import { Checkbox, Radio } from 'antd'
import { SecondaryText, Title } from '../../../typography'
import { Form } from '../../form'
import { Input } from '../../../input'

const createName = (id) => `item${id}`

const PsychicSupportForm = ({ parentProp, field, getData }) => {
  const [specialstIds, setSpecialistIds] = useState({})
  const [terminationIds, setTerminationtIds] = useState({})
  const [notApplingIds, setNotApplingIds] = useState({})
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)
  const form = Form.useFormInstance()

  const initialState = useMemo(() => {
    if (getData) {
      const familyMembers = getData?.familyData?.familyMembers || []
      const displacedPersons = getData?.familyData?.displacedPersons || []
      const nonResidePersons = getData?.familyData?.nonResidePersons || []

      // Combine all persons into a single array
      const allPersons = [
        ...familyMembers,
        ...displacedPersons,
        ...nonResidePersons,
      ]

      const initialCheckedState = allPersons.reduce((acc, item) => {
        item?.personPsychicSupportTerminationReasons?.forEach((supportType) => {
          acc[`item${supportType.id}`] = {
            ...supportType,
          }
        })
        return acc
      }, {})

      const initialCheckedStateNonPsychic = allPersons.reduce((acc, item) => {
        item?.personNonPsychicSupportReasons?.forEach((supportType) => {
          acc[`item${supportType.id}`] = {
            ...supportType,
          }
        })
        return acc
      }, {})

      const initialCheckedPsychicSupportTypes = allPersons.reduce(
        (acc, item) => {
          item?.personPsychicSupportTypes?.forEach((supportType) => {
            acc[`item${supportType.id}`] = {
              ...supportType,
            }
          })
          return acc
        },
        {},
      )

      return {
        initialCheckedState,
        initialCheckedStateNonPsychic,
        initialCheckedPsychicSupportTypes,
      }
    }
    return {}
  }, [getData])

  // useEffect to set states once initialState is calculated
  useEffect(() => {
    if (initialState) {
      setTerminationtIds(initialState.initialCheckedState || {})
      setNotApplingIds(initialState.initialCheckedStateNonPsychic || {})
      setSpecialistIds(initialState.initialCheckedPsychicSupportTypes || {})
    }
  }, [initialState])

  const psychicInjury = Form.useWatch(
    [...parentProp, field.name, 'psychicInjury'],
    {
      preserve: true,
    },
  )
  const psychicSupportId = Form.useWatch(
    [...parentProp, field.name, 'psychicSupportId'],
    {
      preserve: true,
    },
  )

  const specSupport = Form.useWatch(
    [...parentProp, field.name, 'continuePsychicSupport'],
    {
      preserve: true,
    },
  )

  useEffect(() => {
    // item7 - Մասնագիտական աջակցության կարիքն այլևս չկա
    if (notApplingIds?.item7 && Object.keys(notApplingIds).length > 1) {
      form.setFields([
        {
          name: [...parentProp, field.name, 'personNonPsychicSupportReasons'],
          value: { item7: { id: 7 } },
        },
      ])
      setNotApplingIds({ item7: true })
    } else if (notApplingIds?.item7) {
      setDisableCheckboxes(true)
      return
    } else {
      setDisableCheckboxes(false)
    }
  }, [field.name, form, notApplingIds, parentProp])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, itemProp) => {
      setState((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [...parentProp, field.name, itemProp, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [...parentProp, field.name, itemProp, itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form, parentProp, field.name],
  )

  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = useMemo(
    () =>
      (items, level = 0, state, setState, itemProp) => {
        const marginLeft = level * 20
        return items.map((item) => {
          const itemName = createName(item.id)
          return (
            <React.Fragment key={item.id}>
              <FormItem
                name={[field.name, itemProp, itemName]}
                style={{ marginLeft }}
                rules={[
                  {
                    validator: () =>
                      validateCheckboxGroup(items, state)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                            ),
                          ),
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Checkbox
                  disabled={
                    itemProp === 'personNonPsychicSupportReasons' &&
                    disableCheckboxes &&
                    item.id !== 7
                  }
                  checked={!!state[itemName]}
                  onChange={(e) =>
                    handleCheckboxChange(
                      itemName,
                      e.target.checked,
                      item.id,
                      setState,
                      itemProp,
                    )
                  }
                >
                  {item.label}
                </Checkbox>
              </FormItem>
              {item.hasComment && state[itemName] && (
                <FormItem
                  name={[field.name, itemProp, itemName, 'comment']}
                  // rules={[{ required: true }]}
                  style={{ marginLeft }}
                >
                  <Input
                    placeholder="Նշել"
                    defaultValue={state[itemName]?.comment || ''}
                  />
                </FormItem>
              )}
              {item.children &&
                item.children.length > 0 &&
                state[itemName] &&
                renderItems(item.children, level + 1, itemName)}
            </React.Fragment>
          )
        })
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [field.name, disableCheckboxes, handleCheckboxChange],
  )

  return (
    <FormSectionFullCard>
      <FormItem
        name={[field.name, 'psychicInjury']}
        label={
          <Title>
            Արդյոք բռնի տեղահանման հետևանքով/ընթացքում ձեռք է բերել հոգեկան
            առողջության խնդիրներ կամ ենթադրում եք, որ նրա մոտ նկատվող
            փոփոխությունը կարող է հոգեկան առողջության խնդիր լինել կամ վերածվել
            հոգեկան առողջության խնդրի
          </Title>
        }
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio value={true}>Այո</Radio>
          <Radio value={false}>Ոչ</Radio>
        </Radio.Group>
      </FormItem>
      {psychicInjury && (
        <FormItem
          name={[field.name, 'psychicSupportId']}
          rules={[{ required: true }]}
          label={<Title>Արդյո՞ք դիմել է մասնագիտական աջակցության</Title>}
        >
          <Radio.Group>
            <Radio value={1}>Այո</Radio>
            <Radio value={2}>Ոչ</Radio>
            <Radio value={3}>Հրաժարվում է պատասխանել</Radio>
          </Radio.Group>
        </FormItem>
      )}
      {psychicSupportId === 1 && psychicInjury && (
        <FormItem
          name={[field.name, 'personPsychicSupportTypes']}
          label={<SecondaryText>Ի՞նչ մասնագետի է դիմել</SecondaryText>}
        >
          <>
            {renderItems(
              psychicSpecialistTypes,
              0,
              specialstIds,
              setSpecialistIds,
              'personPsychicSupportTypes',
            )}
            <FormItem
              name={[field.name, 'continuePsychicSupport']}
              label={
                <SecondaryText>
                  Արդյո՞ք շարունակում է մասնագիտական աջակցություն ստանալ
                </SecondaryText>
              }
              rules={[{ required: true }]}
            >
              <Radio.Group>
                <Radio value={true}>Այո</Radio>
                <Radio value={false}>Ոչ</Radio>
              </Radio.Group>
            </FormItem>
            {specSupport === false && (
              <FormItem
                name={[field.name, 'personPsychicSupportTerminationReasons']}
                label={<SecondaryText>Որն է դադարեցման պատճառը</SecondaryText>}
              >
                {renderItems(
                  psychicSupportTerminationReasons,
                  0,
                  terminationIds,
                  setTerminationtIds,
                  'personPsychicSupportTerminationReasons',
                )}
              </FormItem>
            )}
          </>
        </FormItem>
      )}
      {psychicSupportId === 2 && psychicInjury && (
        <FormItem
          name={[field.name, 'personNonPsychicSupportReasons']}
          label={
            <SecondaryText>
              Ո՞րն է մասնագիտական աջակցության չդիմելու պատճառը
            </SecondaryText>
          }
        >
          {renderItems(
            nonPsychicSupportReasons,
            0,
            notApplingIds,
            setNotApplingIds,
            'personNonPsychicSupportReasons',
          )}
        </FormItem>
      )}
    </FormSectionFullCard>
  )
}

export default PsychicSupportForm
