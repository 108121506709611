import dayjs from 'dayjs'

export const transformObjectToList = (obj) => {
  if (!obj) return []
  return (
    Object.entries(obj)
      // eslint-disable-next-line no-unused-vars
      .map(([_, value]) => {
        if (
          value &&
          Object.prototype.hasOwnProperty.call(value, 'agreeRedCross')
        ) {
          return {
            applyRedCrossTypeId: value.applyRedCrossTypeId,
            agreeRedCross: value.agreeRedCross,
            id: value.id,
            comment: value.comment || '',
          }
        }
        if (!value || typeof value.id !== 'number') return null
        return {
          id: value.id,
          comment: value?.comment?.toString() || '',
        }
      })
      .filter((item) => item !== null || item !== undefined)
  )
}

export function transformDataArray(dataArray) {
  const transformAfterWorkTypes = (afterWorkTypesObj) => {
    if (!afterWorkTypesObj) return []
    return (
      Object.entries(afterWorkTypesObj)
        // eslint-disable-next-line no-unused-vars
        .map(([_, value]) => {
          if (!value || value.id === undefined) return null
          return {
            id: value.id,
            comment: value.comment || '',
            workNeeds: transformObjectToList(value?.workNeeds).filter(
              (item) => item !== null && item?.id,
            ),
            workInvests: transformObjectToList(value?.workInvests).filter(
              (item) => item !== null && item?.id,
            ),
          }
        })
        .filter((item) => item !== null)
    )
  }

  return dataArray.map((item) => ({
    id: item.personId,
    personId: item.personId,
    beforeHasWork: item.beforeHasWork,
    afterHasWork: item.afterHasWork,
    nonWork: item.wantsToWork,
    beforeWorkTypes: transformObjectToList(item.beforeWorkTypes).filter(
      (item) => item !== null && item?.id,
    ),
    nonWorkReasons: transformObjectToList(item.nonWorkReasons).filter(
      (item) => item !== null && item?.id,
    ),
    afterWorkTypes: transformAfterWorkTypes(item.afterWorkTypes).filter(
      (item) => item !== null && item?.id,
    ),
  }))
}

export function transformDataToArray(dataArray) {
  const transformAfterIncomeTypes = (afterWorkTypesObj) => {
    if (!afterWorkTypesObj) return []
    return (
      Object.entries(afterWorkTypesObj)
        // eslint-disable-next-line no-unused-vars
        .map(([_, value]) => {
          if (!value || value.id === undefined) return null
          return {
            id: value.id,
            comment: value.comment || '',
          }
        })
        .filter((item) => item !== null)
    )
  }

  return transformAfterIncomeTypes(dataArray).filter(
    (item) => item?.id !== false,
  )
}

export function transformBeforeDataToArray(dataArray) {
  const transformAfterIncomeTypes = (afterWorkTypesObj) => {
    if (!afterWorkTypesObj) return []
    return (
      Object.entries(afterWorkTypesObj)
        // eslint-disable-next-line no-unused-vars
        .map(([_, value]) => {
          if (!value || value.id === undefined) return null
          return {
            id: value.id,
            comment: value.comment || '',
          }
        })
        .filter((item) => item !== null)
    )
  }

  return transformAfterIncomeTypes(dataArray).filter(
    (item) => item?.id !== false,
  )
}

export const modifyPage2Data = (values, applicationId) => {
  // const membersList = [
  //   ...(values.familyData?.familyMembers
  //     ? values.familyData.familyMembers
  //     : []),
  //   ...(values.familyData?.displacedPersons
  //     ? values.familyData.displacedPersons
  //     : []),
  //   ...(values.familyData?.nonResidePersons
  //     ? values.familyData.nonResidePersons
  //     : []),
  // ]

  const transformMembersList = (membersList) => {
    return membersList.map((member) => {
      return {
        fname: '',
        lname: '',
        ...member,
        birthdate: member?.birthdate
          ? dayjs(member.birthdate).format('YYYY-MM-DD')
          : null,
        householdNotResidePersonAccommodationTypes:
          member.householdNotResidePersonAccommodationTypes
            ? transformObjectToList(
                member.householdNotResidePersonAccommodationTypes,
              ).filter((item) => item !== null)
            : [],
        personSocialStatuses: member.personSocialStatuses
          ? transformObjectToList(member.personSocialStatuses).filter(
              (item) => item !== null,
            )
          : [],
        personNotResideReasons: member.personNotResideReasons
          ? transformObjectToList(member.personNotResideReasons).filter(
              (item) => item !== null,
            )
          : [],
        personMedicalSupportTypes: member.personMedicalSupportTypes
          ? transformObjectToList(member.personMedicalSupportTypes).filter(
              (item) => item !== null,
            )
          : [],
        hasGuardian: member?.hasGuardian,
        guardianName: member?.guardianName || null,
        guardianFamilyMember: member?.guardianFamilyMember || null,
        personNonMedicalSupportReasons: member.personNonMedicalSupportReasons
          ? transformObjectToList(member.personNonMedicalSupportReasons).filter(
              (item) => item !== null,
            )
          : [],
        personNonPsychicSupportReasons: member.personNonPsychicSupportReasons
          ? transformObjectToList(member.personNonPsychicSupportReasons).filter(
              (item) => item !== null,
            )
          : [],
        personPsychicSupportTerminationReasons:
          member.personPsychicSupportTerminationReasons
            ? transformObjectToList(
                member.personPsychicSupportTerminationReasons,
              ).filter((item) => item !== null)
            : [],
        personPsychicSupportTypes: member.personPsychicSupportTypes
          ? transformObjectToList(member.personPsychicSupportTypes).filter(
              (item) => item !== null,
            )
          : [],
        personChildNotWithParentTypes: member.personChildNotWithParentTypes
          ? transformObjectToList(member.personChildNotWithParentTypes).filter(
              (item) => item !== null,
            )
          : [],
        reuniteTypeId: member?.reuniteTypeId || null,
        nonReside: member?.nonReside ? true : false,
        personNotReuniteReasons: transformObjectToList(
          member?.personNotReuniteReasons || {},
        )
          .filter((item) => !!item)
          .map((item) => {
            return {
              id: item.id,
              comment: item.comment ? item?.comment.toString() : '',
            }
          }),
        personReuniteSupportTypes: transformObjectToList(
          member?.personReuniteSupportTypes || [],
        )
          .filter((item) => !!item)
          .map((item) => {
            return {
              id: item.id,
              comment: item.comment ? item?.comment.toString() : '',
            }
          }),
      }
    })
  }

  const familyMembersList = transformMembersList(
    values.familyData?.familyMembers || [],
  )
  const displacedPersonsList = transformMembersList(
    values.familyData?.displacedPersons || [],
  )
  const nonResidePersonsList = transformMembersList(
    values.familyData?.nonResidePersons || [],
  )

  let result = {
    notResidePersonsStayInSameAccommodation:
      values?.familyData?.notResidePersonsStayInSameAccommodation,
    hasNonResidePerson: values?.hasNonResidePerson,
    reuniteTypeId: values?.reuniteTypeId || null,
    hasDiedPerson: values?.hasDiedPerson,
    note: values?.page2notes || '',
    personDiedReasons: transformObjectToList(values?.personDiedReasons || {})
      .filter((item) => !!item)
      .map((item) => {
        return {
          id: item.id,
          comment: item.comment ? item?.comment.toString() : '',
        }
      }),
    householdNotReuniteReasons:
      transformObjectToList(values?.householdNotReuniteReasons).filter(
        (item) => item !== null,
      ) || null,
    householdReuniteSupportTypes:
      transformObjectToList(values?.householdReuniteSupportTypes).filter(
        (item) => item !== null,
      ) || null,
    familyData: {
      familyMembers: [...familyMembersList],
      displacedPersons: [...displacedPersonsList],
      nonResidePersons: [...nonResidePersonsList],
    },
    householdId: applicationId,
  }

  return result
}

export const modifyPage3Data = (values) => {
  // function removeDuplicates(dataArray) {
  //   const seen = new Map()
  //   return dataArray.filter((item) => {
  //     if (!seen.has(item.buildingTypeId)) {
  //       seen.set(item.buildingTypeId, true)
  //       return true
  //     }
  //     return false
  //   })
  // }

  const data = {
    ...values,
    // householdBuildingTypes: removeDuplicates(values?.householdBuildingTypes),
  }

  const householdBuildingTypes = Object.values(
    data?.householdBuildingTypes || {},
  )
    .filter((item) => item !== undefined)
    .map((item) => ({
      ...item,
      id: item?.buildingTypeId,
      hbtNcgtrs: Object.values(item?.hbtNcgtrs || {}).filter((i) => i.id),
    }))

  const filteredItems = Object.values(data?.householdNonSafeReasons || {})
    .filter((item) => item?.id && item !== undefined)
    .map((item) => ({ ...item, nonSafeReasonId: item.id }))

  const householdNonSafeReasons = Object.values(filteredItems).filter(
    (item) => item.id !== undefined,
  )

  data.accommodationUseTypeId =
    data.accommodationUseTypeParentId || data.accommodationUseTypeId
  delete data.accommodationUseTypeParentId
  data.householdBuildingTypes
  data.accommodationRiskTypeId =
    data.accommodationRiskTypeParentId || data.accommodationRiskTypeId
  delete data.accommodationRiskTypeParentId
  data.householdBuildingTypes = householdBuildingTypes
  data.householdNonSafeReasons = householdNonSafeReasons

  const keys = Object.keys(data)

  keys.forEach((key) => {
    if (key.includes(':')) {
      delete data[key]
    }
  })

  return data
}

export const modifyPage7Data = (values) => {
  const formaattedData = Object.values(values || {}).filter((item) => item?.id)

  const dataToSave = formaattedData.map((item) => {
    const memberEdu = item?.memberEducation?.map((member) => {
      return {
        id: item.id,
        personId: member?.personId,
        educationSubtypeId: member?.educationSubtypeId || null,
        educationSupportId: member?.educationSupportId || null,
        educationInterruptedPeriodId:
          member?.educationInterruptedPeriodId || null,
        personEducationNotAttendReasons: Object.values(
          member?.personEducationNotAttendReasons || {},
        ).filter((item) => item.id && item.checked !== false),
        householdEducationNeeds: Object.values(
          member?.householdEducationNeeds || {},
        ).filter((item) => item.id && item.checked !== false),
      }
    })
    return memberEdu
  })

  return {
    householdPersonEducationTypes: dataToSave.flat(),
  }
}

export const modifyPage10Data = (values) => {
  const data = { ...values }

  const keys = Object.keys(data)

  const hoseholdSupportEntities = Object.values(
    data?.hoseholdSupportEntities || {},
  )
    .filter((item) => item?.id)
    .map((item) => ({
      ...item,
      householdEntitySupportTypes: Object.values(
        item?.householdEntitySupportTypes || {},
      ).filter(
        (supportItem) =>
          supportItem.id !== undefined && supportItem.id !== null,
      ),
    }))

  const householdFriendSupportTypeIds = Array.isArray(
    data?.householdFriendSupportTypeIds,
  )
    ? data?.householdFriendSupportTypeIds.filter(
        (item) => item !== null && item !== undefined && item.id !== false,
      )
    : []

  const householdReceiptSupportTypes = Array.isArray(
    data?.householdReceiptSupportTypes,
  )
    ? data?.householdReceiptSupportTypes.filter(
        (item) => item !== null && item !== undefined && item.id !== false,
      )
    : []

  data.hoseholdSupportEntities = hoseholdSupportEntities
  data.householdFriendSupportTypeIds = householdFriendSupportTypeIds
  data.householdReceiptSupportTypes = householdReceiptSupportTypes
  keys.forEach((key) => {
    if (key.includes(':')) {
      delete data[key]
    }
  })

  return data
}

export const modifyPage12Data = (values) => {
  const data = { ...values }

  const formatHouseholdViolences = (data, isFamilyMember) => {
    if (!data) {
      return []
    }
    const res = Object.values(data)
    return res.map((i) => {
      if (typeof i?.id !== 'number') {
        return null
      }
      return {
        id: i.id,
        comment: i?.comment || '',
        isFamilyMember,
        ...(i.householdViolencePersonTypes?.id
          ? {
              householdViolencePersonTypes: [
                {
                  ...i?.householdViolencePersonTypes,
                  comment: i?.householdViolencePersonTypes?.comment || '',
                  householdViolenceSupports:
                    i?.householdViolencePersonTypes?.householdViolenceSupports?.checkIds.map(
                      (r) => {
                        return {
                          id: r,
                          comment:
                            i?.householdViolencePersonTypes
                              ?.householdViolenceSupports?.comment || null,
                        }
                      },
                    ),
                },
              ],
            }
          : {}),
      }
    })
  }

  const formattedData = [
    ...formatHouseholdViolences(data?.nonMemberViolences, false),
    ...formatHouseholdViolences(data?.memberViolences, true),
  ].filter((i) => i !== null)

  // eslint-disable-next-line no-unused-vars
  const createPlainList = (obj) => {
    let res = Object.values(obj)
      .filter((item) => item?.id)
      .map((x) => {
        return {
          ...x,
          householdBadAttitudeDemonstrationTypes: Object.values(
            x.householdBadAttitudeDemonstrationTypes,
          ).filter((item) => item?.id),
        }
      })
    return res
  }

  const badAttitudePersonTypes = Object.values(
    data?.householdBadAttitudePersonTypes || {},
  )
    .filter((item) => item?.id)
    .map((item) => {
      return {
        id: item.id,
        comment: item?.comment || '',
        householdBadAttitudeTypes:
          createPlainList(item?.householdBadAttitudeTypes || {}) || [],
      }
    })

  const dataToSave = {
    householdViolences: formattedData,
    householdChildrenInteractingTypes:
      Object.values(values?.householdChildrenInteractingTypes)?.filter(
        (item) => item?.id !== undefined,
      ) || [],
    attitudeId: data.attitudeId,
    familyMemberSafetyId: data.familyMemberSafetyId,
    homeRelationshipId: data.homeRelationshipId,
    familyRelationshipId: data.familyRelationshipId,
    violenceInAccommodation: data.violenceInAccommodation,
    violenceInCommunity: data.violenceInCommunity,
    // homeRelationshipComment: data.homeRelationshipComment || '',
    // familyRelationshipComment: data.familyRelationshipComment || '',
    householdBadAttitudePersonTypes: badAttitudePersonTypes || [],
    householdNonSafetyPeople: data?.householdNonSafetyPeople,
  }

  delete data.memberViolences
  delete data.nonMemberViolences

  return dataToSave
}

export const modifyPage14Data = (values) => {
  const data = { ...values }
  data.householdInformationAccessibilityNeeds =
    Object.values(data?.householdInformationAccessibilityNeeds)?.filter(
      (item) => item?.id !== undefined && item?.id !== false,
    ) || []
  data.householdInformationLanguages =
    Object.values(data?.householdInformationLanguages)?.filter(
      (item) =>
        item?.id !== undefined && item?.id !== false && item !== undefined,
    ) || []
  data.householdInformationReceiveTypes =
    Object.values(data?.householdInformationReceiveTypes)?.filter(
      (item) =>
        item?.id !== undefined && item?.id !== false && item !== undefined,
    ) || []

  const keys = Object.keys(data)

  keys.forEach((key) => {
    if (key.includes(':')) {
      delete data[key]
    }
  })

  return data
}
