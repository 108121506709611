import React from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import Input from 'antd/es/input/Input'
import { SecondaryText, Title } from '../../typography'
import { COLORS } from '../../../helpers/constants'

import styled from 'styled-components'

const Fieldset = styled.fieldset`
  border: 2px solid #456fae;
  border-radius: 10px;
  padding: 29px 39px 19px;
  @media (max-width: 801px) {
    padding: 29px 19px;
  }
  cursor: not-allowed;
  background-color: #f0eded;
`

const PersonAgreementView = ({ data }) => {
  return (
    <VerticalSpace>
      {data?.periodHistory?.map((item, index) => {
        return (
          <VerticalSpace key={item.creationDate}>
            <Fieldset>
              <VerticalSpace>
                <Title>
                  Անձի համաձայնությունը՝ մասնակցելու գնահատմանը{' '}
                  <SecondaryText color={COLORS.PRIMARY.BLUE}>
                    (Այցելություն N {index + 1})
                  </SecondaryText>
                </Title>
                <SecondaryText>
                  Համաձայն չէ մասնակցել գնաահատմանը այս պահին, սակայն հետագայում
                  հնարավոր է համարում
                </SecondaryText>
              </VerticalSpace>
              <FormItem label={item.name}>
                <Input
                  value={item.comment || ''}
                  style={{ width: '235px' }}
                  disabled
                />
              </FormItem>
            </Fieldset>
          </VerticalSpace>
        )
      })}
    </VerticalSpace>
  )
}

export default PersonAgreementView
