import React, { useEffect, useState } from 'react'
import FormItem from 'antd/es/form/FormItem'
import { utilityServices } from '../constatns'
import { Radio } from 'antd'
import { SecondaryText, SmallText, Text, Title } from '../../typography'
import { VerticalSpace } from '../../space/vertical-space'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { Info } from '../../typography/info'
import { COLORS } from '../../../helpers/constants'
import { Input } from '../../input'
import { Form } from 'antd'

const createName = (id) => `item${id}`

const UtilityFeesHelp = ({ getData, disabled }) => {
  const form = Form.useFormInstance()
  const [utility, setUtility] = useState([])

  useEffect(() => {
    const utilityServicesList = getData?.utilityServices || []
    const formValues = { utilityServices: {} }
    utilityServicesList.forEach((item) => {
      const itemName = createName(item.id)
      formValues.utilityServices[itemName] = {
        id: item.id,
        compensationId: item.compensationId,
        isActive: item.isActive,
      }
    })
  }, [getData, form])

  const handleRadioChange = (item) => {
    const selectedResponse = {
      id: item.id,
      compensationId: item.compensationId,
      isActive: item.isActive,
    }

    setUtility((prevState) => {
      const updatedActions = prevState.filter((action) => action.id !== item.id)
      return [...updatedActions, selectedResponse]
    })
  }

  form.setFieldsValue({ utility: utility })

  return (
    <FormSectionFullCard>
      <VerticalSpace>
        <Title>
          Հետևյալ կոմունալ վարձավճարների հարցում ունե՞ք աջակցության կարիք։
        </Title>
        <Info
          text={
            <SmallText color={COLORS.PRIMARY.GRAY_LIGHT}>
              ԶՐՈՒՑԱՎԱՐ ԿԱՐԴԱԼ ՏԱՐԲԵՐԱԿՆԵՐԸ։
            </SmallText>
          }
        />
      </VerticalSpace>

      <VerticalSpace>
        <FormItem name={'utilityServices'}>
          {utilityServices.map((item, index) => (
            <React.Fragment key={item.id}>
              <Text color={COLORS.PRIMARY.BLUE}>
                {index + 1}. {item.label}
              </Text>
              <FormItem
                name={['utilityServices', createName(item.id)]}
                label={<SecondaryText>Ինչ չափով</SecondaryText>}
                style={{ marginLeft: '20px' }}
                rules={[{ required: true }]}
              >
                <Radio.Group
                  disabled={disabled}
                  onChange={(e) => handleRadioChange(e.target.value, item.id)}
                >
                  <VerticalSpace>
                    <Radio value={1}>Այո, ամբողջական փոխհատուցման կարիք</Radio>
                    <Radio value={2}>Այո, մասնակի փոխհատուցման կարիք</Radio>
                    <Radio value={3}>Ոչ, կարիք չունեմ</Radio>
                  </VerticalSpace>
                </Radio.Group>
              </FormItem>
            </React.Fragment>
          ))}
        </FormItem>
      </VerticalSpace>
      <FormItem hidden name={'utilityServices'}>
        <Input />
      </FormItem>
    </FormSectionFullCard>
  )
}

export default UtilityFeesHelp
