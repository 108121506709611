import React from 'react'
import { FormItem } from '../../form/form-item'
import { Title } from '../../typography'
import { Radio } from 'antd'
import { Form } from '../../form/form'
import NonWorkReasons from './non-work-reasons'
import WorkNeedsForm from './work-needs-form'

const NonWorkForm = ({ field, questions, disabled }) => {
  const form = Form.useFormInstance()
  const wantsToWork = Form.useWatch(['personWorks', field.name, 'wantsToWork'])
  return (
    <>
      <FormItem
        label={<Title>Ցանկանում ե՞ք իրականացնել որևէ աշխատանք</Title>}
        name={[field.name, 'wantsToWork']}
        rules={[{ required: true }]}
      >
        <Radio.Group
          onChange={() => {
            form.resetFields([['personWorks', field.name, 'nonWorkReasons']])
          }}
          disabled={disabled}
        >
          <Radio value={1}>Այո, ցանկանում եմ և կարող եմ</Radio>
          <Radio value={2}>Այո, ցանկանում եմ, բայց չեմ կարող</Radio>
          <Radio value={3}>Ոչ, չեմ ցանկանում</Radio>
        </Radio.Group>
      </FormItem>
      {wantsToWork === 2 && (
        <NonWorkReasons field={field} disabled={disabled} />
      )}

      {wantsToWork === 3 && (
        <NonWorkReasons field={field} disabled={disabled} />
      )}

      {wantsToWork === 1 && (
        <WorkNeedsForm
          field={field}
          questions={questions}
          disabled={disabled}
        />
      )}
    </>
  )
}

export default NonWorkForm
