import React, { useCallback, useEffect, useState } from 'react'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import { Form, Radio, Spin } from 'antd'
import { Input } from '../../input'
import { FormSectionCard } from '../../card/form-section-card'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_NON_SAFE_REASONS } from './constants'
import { Title } from '../../typography'

const createName = (id) => `item${id}`

const NonSafeReasons = ({ getData, disabled }) => {
  const { data, isLoading } = useGetQuestions(URL_NON_SAFE_REASONS)
  const form = Form.useFormInstance()
  const [checkIds, setCheckIds] = useState({})
  const safeAccommodation = Form.useWatch(['safeAccommodation'])

  useEffect(() => {
    form.resetFields(['householdNonSafeReasons'])
  }, [form, safeAccommodation])

  useEffect(() => {
    if (getData && getData.householdNonSafeReasons) {
      const updatedCheckedIds = {}
      const householdNonSafeReasons = getData?.householdNonSafeReasons?.reduce(
        (acc, curr) => {
          acc[`item${curr.id}`] = {
            ...curr,
          }
          updatedCheckedIds[`item${curr.id}`] = curr.isActive
          return acc
        },
        {},
      )

      setCheckIds(householdNonSafeReasons)

      setTimeout(function () {
        form.setFieldsValue({
          householdNonSafeReasons: householdNonSafeReasons,
        })
      }, 10)
    }
  }, [getData, form])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFieldsValue({
        householdNonSafeReasons: {
          ...form.getFieldValue('householdNonSafeReasons'),
          [itemName]: {
            ...form.getFieldValue(['householdNonSafeReasons', itemName]),
            id: checked ? itemId : false,
            comment: checked
              ? form.getFieldValue([
                  'householdNonSafeReasons',
                  itemName,
                  'comment',
                ])
              : '',
          },
        },
      })
    },
    [form],
  )

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentName, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkIds[itemName]}
              disabled={disabled}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  item.children,
                  setState,
                  parentName,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && state[itemName] && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input disabled={disabled} />
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            state[itemName] &&
            renderItems(
              item.children,
              level + 1,
              state,
              setState,
              parentName,
              itemName,
            )}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionCard
      title="Արդյո՞ք ձեր կացարանը (գտնվելու վայրը) համարում եք անվտանգ վայր (ներառյալ երեխաների համար)"
      showRequired={true}
    >
      <Spin spinning={isLoading}>
        <FormItem name={'safeAccommodation'} rules={[{ required: true }]}>
          <Radio.Group
            onChange={() => {
              form.resetFields(['householdNonSafeReasons'])
              setCheckIds({})
            }}
            disabled={disabled}
          >
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>
        {safeAccommodation === false && (
          <>
            <FormItem
              name="householdNonSafeReasons"
              label={<Title>Խնդրում եմ նշել պատճառները</Title>}
              rules={[{ required: true }]}
            >
              {renderItems(data, 0, checkIds, setCheckIds, [
                'householdNonSafeReasons',
              ])}
            </FormItem>
          </>
        )}
      </Spin>
    </FormSectionCard>
  )
}

export default NonSafeReasons
