import React, { useCallback, useState, useEffect } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { Title } from '../../typography'
import { FormItem } from '../../form/form-item'
import useGetQuestions from '../../../api/application/use-get-questions'
import {
  GET_OBSERVATION_DIRECTION,
  observationDirectionAnswers,
} from './constants'
import { Form, Spin } from 'antd'
import { COLORS } from '../../../helpers/constants'
import { Checkbox } from '../../checkbox'

export const ObservationDirection = ({ backendData, disabled }) => {
  const form = Form.useFormInstance()
  const [checkedIds, setCheckedIds] = useState({})

  const { data: questions, isLoading } = useGetQuestions(
    GET_OBSERVATION_DIRECTION,
  )

  useEffect(() => {
    if (backendData) {
      const initialCheckedIds = backendData?.reduce((acc, item) => {
        acc[item.id] = item?.answerIds?.reduce((innerAcc, answerId) => {
          innerAcc[answerId] = true
          return innerAcc
        }, {})
        return acc
      }, {})

      setCheckedIds(initialCheckedIds)

      const initialFormValues = backendData?.reduce((acc, item) => {
        acc[item.id] = item.answerIds
        return acc
      }, {})

      form.setFieldsValue({ householdObservationDirections: initialFormValues })
    }
  }, [form, backendData])

  const handleCheckboxChange = useCallback(
    (questionId, answerId, checked) => {
      setCheckedIds((prevState) => {
        // Update the checkedIds state
        const updatedCheckedIds = {
          ...prevState,
          [questionId]: {
            ...(prevState[questionId] || {}),
            [answerId]: checked, // Add or remove based on checked status
          },
        }

        // Extract only the IDs that are currently checked
        const updatedAnswerIds = Object.entries(updatedCheckedIds[questionId])
          // eslint-disable-next-line no-unused-vars
          .filter(([_, isChecked]) => isChecked) // Keep only checked IDs
          .map(([id]) => Number(id)) // Convert to number

        // Get the current form values for the specific question
        const currentValues =
          form.getFieldValue('householdObservationDirections') || {}

        // Create the new value structure to be set
        const updatedValue = {
          answerId: null, // You can set it to null or modify based on your requirements
          answerIds: updatedAnswerIds, // The list of selected answers
          id: questionId, // This is the ID of the question
          isActive: true, // Set to true or based on your condition
          personId: null, // Set to null or your required value
        }

        // Update the form field with the new value structure
        form.setFieldsValue({
          householdObservationDirections: {
            ...currentValues,
            [questionId]: updatedValue, // Update the specific question with the new structure
          },
        })

        return updatedCheckedIds
      })
    },
    [form],
  )

  const renderItems = (items, question) => {
    return items.map((item) => (
      <FormItem
        key={item.id}
        name={['householdObservationDirections', question.id]}
      >
        <Checkbox
          checked={checkedIds[question.id]?.[item.id] || false}
          disabled={disabled}
          onChange={(e) =>
            handleCheckboxChange(question.id, item.id, e.target.checked)
          }
        >
          {item.name}
        </Checkbox>
      </FormItem>
    ))
  }

  return (
    <FormSectionCard title="Դիտարկման արդյունքներ" showRequired={true}>
      <Spin spinning={isLoading}>
        <Title>
          Սոցիալական աշխատողի կողմից իրականացվող դիտարկման ուղղությունները
        </Title>
        <Title style={{ marginBottom: 20 }}>Փոխհարաբերությունները </Title>
        {questions &&
          questions.map((question) => (
            <FormItem name={'householdObservationDirections'} key={question.id}>
              <React.Fragment>
                <Title
                  style={{ marginTop: 20, marginBottom: 20 }}
                  color={COLORS.PRIMARY.BLUE}
                >
                  {question.id}. {question.name}
                </Title>
                {renderItems(observationDirectionAnswers, question)}
              </React.Fragment>
            </FormItem>
          ))}
      </Spin>
    </FormSectionCard>
  )
}
