import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Form } from '../../form/form'
import { Button } from '../../button'
import { PlusCircleOutlined, DeleteOutlined } from '@ant-design/icons'
import { FormItem } from '../../form/form-item'
import { Select } from '../../select'
import { createName, createOptionsFromArray } from '../../../helpers/utils'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Collapse, Space, Checkbox, Radio } from 'antd'
import { SecondaryText, Title } from '../../typography'
import { COLORS } from '../../../helpers/constants'
import {
  URL_EDUCATION_NEED,
  URL_EDUCATION_NOT_REASON,
  URL_EDUCATION_SUB_TYPES,
  URL_PERIOD,
} from './constants'
import { Input } from '../../input'
import { VerticalSpace } from '../../space/vertical-space'

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false)

  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add()
      hasInitialized.current = true
    }
  }, [add, fields.length])
}

export const FamilyMemberEducation = ({
  parentFormItem,
  questionId,
  familyMembers,
  disabled,
}) => {
  return (
    <Form.List name={[...parentFormItem, 'memberEducation']}>
      {(fields, { add, remove }) => {
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ManageFields fields={fields} add={add} />
            {fields.map((field) => (
              <PersonEducation
                key={field.key}
                field={field}
                remove={remove}
                parentFormItem={[...parentFormItem, 'memberEducation']}
                questionId={questionId}
                familyMembers={familyMembers}
                disabled={disabled}
              />
            ))}
            {!disabled && (
              <Button
                type="primary"
                size="large"
                shape="round"
                onClick={() => add()}
                style={{ margin: '20px auto' }}
              >
                <PlusCircleOutlined />
                Ավելացնել ընտանիքի անդամ
              </Button>
            )}
          </div>
        )
      }}
    </Form.List>
  )
}

const PersonEducation = ({
  field,
  remove,
  parentFormItem,
  questionId,
  familyMembers,
  disabled,
}) => {
  const form = Form.useFormInstance()
  const { data: periods } = useGetQuestions(URL_PERIOD)

  const { data: notAttendedQuestions } = useGetQuestions(
    URL_EDUCATION_NOT_REASON,
  )
  const { data: eduNeedsQuestions } = useGetQuestions(URL_EDUCATION_NEED)
  const { data: eduSubTypes } = useGetQuestions(URL_EDUCATION_SUB_TYPES)

  const [notAttendedReasons, setNotAttendedReasons] = useState({})
  const [eduNeeds, setEduNeeds] = useState({})

  useEffect(() => {
    const setInitialCheckedState = (items) => {
      const initialCheckedIds = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = createName(item.id)
          const isChecked = form.getFieldValue([
            'personEducationNotAttendReasons',
            field.name,
            itemName,
            'isActive',
          ])
          initialCheckedIds[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children) // Recursively handle children
          }
        })
      }
      traverse(items)
      return initialCheckedIds
    }

    const initialCheckedIds = setInitialCheckedState(notAttendedQuestions)
    setNotAttendedReasons(initialCheckedIds)
  }, [form, field.name, notAttendedQuestions])

  // const validateCheckboxGroup = (items, state) => {
  //   for (const item of items) {
  //     const itemName = createName(item.id)
  //     if (state[itemName]) {
  //       return true
  //     }
  //     if (item.children && item.children.length > 0) {
  //       // Recursively check children with the state
  //       if (validateCheckboxGroup(item.children, state)) {
  //         return true
  //       }
  //     }
  //   }
  //   return false
  // }

  const uncheckChildren = (children) => {
    if (!Array.isArray(children)) return // Add this check to ensure children is an array
    children.forEach((child) => {
      const childName = createName(child.id)
      setNotAttendedReasons((prev) => ({ ...prev, [childName]: false }))
      form.setFields([
        {
          name: [
            'personEducationNotAttendReasons',
            field.name,
            childName,
            'id',
          ],
          value: false,
        },
      ])
      if (child.children) {
        uncheckChildren(child.children)
      }
    })
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, children) => {
      setNotAttendedReasons((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [
            'personEducationNotAttendReasons',
            field.name,
            // 'beforeWorkTypes',
            itemName,
            'id',
          ],
          value: true,
        },
      ])

      if (!checked) {
        form.setFields([
          {
            name: [
              'personEducationNotAttendReasons',
              field.name,
              itemName,
              'comment',
            ],
            value: '',
          },
        ])
        if (children) {
          uncheckChildren(children)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, field.name],
  )

  const renderItems = (
    items,
    level = 0,
    state,
    setState,
    parentName,
    componentName,
  ) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          {item.name !== 'Այլ (նշել)' && (
            <FormItem
              name={[...parentName, itemName, 'checked']}
              valuePropName="checked"
              initialValue={state[itemName] || false}
              style={{ marginLeft }}
              // rules={[
              //   {
              //     validator: () =>
              //       validateCheckboxGroup(items, state)
              //         ? Promise.resolve()
              //         : Promise.reject(
              //             new Error(
              //               'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
              //             ),
              //           ),
              //     validateTrigger: 'onSubmit',
              //   },
              // ]}
            >
              <Checkbox
                disabled={disabled}
                checked={state[itemName]}
                onChange={(e) =>
                  handleCheckboxChange(
                    itemName,
                    e.target.checked,
                    item.id,
                    setState,
                    parentName,
                  )
                }
              >
                {item.name}
              </Checkbox>
            </FormItem>
          )}
          <FormItem
            name={[...parentName, itemName, 'id']}
            hidden
            initialValue={item.id}
          />

          {componentName === 'educationNotAttend' && item.id === 22 && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label={'Այլ (նշել)'}
              // rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input disabled={disabled} />
            </FormItem>
          )}
          <FormItem name={[...parentName, itemName, 'id']} hidden />
          {item.children &&
            item.children.length > 0 &&
            state[itemName] &&
            renderItems(
              item.children,
              level + 1,
              state,
              setState,
              parentName,
              itemName,
            )}
        </React.Fragment>
      )
    })
  }

  return (
    <Collapse defaultActiveKey={[field.name]} key={field.name}>
      <Collapse.Panel
        key={field.name}
        header={'Ընտանիքի անդամի տվյալներ'}
        extra={
          <Space>
            {field.name !== 0 && !disabled && (
              <Button
                text="Հեռացնել"
                icon={<DeleteOutlined />}
                style={{ color: COLORS.ALERT.RED }}
                onClick={() => remove(field.name)}
                type="text"
              >
                Հեռացնել
              </Button>
            )}
          </Space>
        }
      >
        <VerticalSpace>
          <FormItem
            name={[field.name, 'personId']}
            rules={[{ required: true }]}
            label={<SecondaryText>Ընտրել ընտանիքի անդամին</SecondaryText>}
          >
            <Select
              options={createOptionsFromArray(familyMembers)}
              disabled={disabled}
            />
          </FormItem>
          {questionId !== 6 && (
            <>
              <FormItem
                name={[
                  ...parentFormItem,
                  field.name,
                  'personEducationNotAttendReasons',
                ]}
              >
                {renderItems(
                  notAttendedQuestions,
                  0,
                  notAttendedReasons,
                  setNotAttendedReasons,
                  [field.name, 'personEducationNotAttendReasons'],
                  'educationNotAttend',
                )}
              </FormItem>
              <Form.Item
                name={[field.name, 'educationInterruptedPeriodId']}
                rules={[{ required: true }]}
                label={
                  <Title>
                    Ինչքա՞ն ժամանակ է ընդհատվել հաճախումը / ուսուցումը
                  </Title>
                }
              >
                <Radio.Group disabled={disabled}>
                  <VerticalSpace>
                    {periods.map((period) => (
                      <Radio key={period.id} value={period.id}>
                        {period.name}
                      </Radio>
                    ))}
                  </VerticalSpace>
                </Radio.Group>
              </Form.Item>
            </>
          )}
          {questionId === 6 && (
            <Form.Item
              name={[field.name, 'educationSubtypeId']}
              style={{ marginBottom: 0 }}
              rules={[{ required: true }]}
              label={<Title>Նշել, թե ի՞նչ հաստատությունում</Title>}
            >
              <Radio.Group disabled={disabled}>
                <VerticalSpace>
                  {eduSubTypes.map((subType) => (
                    <Radio value={subType.id} key={subType.id}>
                      {subType.name}
                    </Radio>
                  ))}
                </VerticalSpace>
              </Radio.Group>
            </Form.Item>
          )}
          <FormItem
            name={[...parentFormItem, field.name, 'householdEducationNeeds']}
            // rules={[{ required: true }]}
            label={
              <Title>
                <span
                  style={{
                    color: COLORS.ALERT.RED,
                    marginRight: '5px',
                    fontWeight: 100,
                  }}
                >
                  *
                </span>
                Կրթական գործընթացն արդյունավետ կազմակերպելու նպատակով ի՞նչ
                կարիքներ ունի Ձեր ընտանիքի նշված անդամը
              </Title>
            }
          >
            {renderItems(
              eduNeedsQuestions,
              0,
              eduNeeds,
              setEduNeeds,
              [field.name, 'householdEducationNeeds'],
              'education',
            )}
          </FormItem>
        </VerticalSpace>
      </Collapse.Panel>
    </Collapse>
  )
}

export default PersonEducation
