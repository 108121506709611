import React, { useCallback, useEffect, useState } from 'react'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { Info } from '../../typography/info'
import { FormItem } from '../../form/form-item'
import { Title } from '../../typography'
import { VerticalSpace } from '../../space/vertical-space'
import { Input, Radio } from 'antd'
import { Form } from '../../form/form'
import { deathReasons as options } from '../constatns'
import { Checkbox } from '../../checkbox'

const createName = (id) => `item${id}`

const radioOptions = [
  { label: 'Այո', value: 1 },
  { label: 'Ոչ', value: 2 },
  { label: 'Դժվարանում է պատասխանել', value: 3 },
]

const LostMemberForm = ({ getData }) => {
  const hasDiedPersonId = Form.useWatch('hasDiedPerson', {
    preserve: true,
  })

  const [checkedIds, setCheckedIds] = useState({})
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      const diedPersonReason =
        getData?.personDiedReasons?.reduce((acc, diedReasons) => {
          acc[`item${diedReasons.id}`] = {
            ...diedReasons,
          }
          return acc
        }, {}) || {}

      form.setFieldsValue({
        hasDiedPerson: getData?.hasDiedPerson,
        personDiedReasons: diedPersonReason,
      })

      setCheckedIds(diedPersonReason)
    }
  }, [form, getData])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }
  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      form.setFields([
        {
          name: ['personDiedReasons', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: ['personDiedReasons', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form],
  )

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={['personDiedReasons', itemName]}
            style={{ marginLeft }}
            // valuePropName="checked"
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.label}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={['personDiedReasons', itemName, 'comment']}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input defaultValue={checkedIds[itemName]?.comment || ''} />
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }
  return (
    <FormSectionFullCard>
      <VerticalSpace>
        <Info
          text={`ՑՈՒՑՈՒՄ.Եթե անձը գտնվում է անհանգիստ, մտահոգ, հուզված, լարված վիճակում և դժվարանում է պատասխանել ${'`'} անհրաժեշտ է զրուցել հետը, հանգստացնել, հարցնել թե արդյոք ցանկանում է շարունակել հարցազրույցը, թե՞ ${'`'}  ոչ: Գուցե ընդմիջման կարիք ունենա կամ մեկ այլ ժամանակ շարունակի`}
        />
        <FormItem
          label={
            <Title>
              Ներողություն եմ խնդրում զգայուն հարց տալու համար։ Եթե չցանկանաք,
              կարող եք չպատասխանել։ Ձեր ընտանիքում վերջին 1 տարվա ընթացքում
              մարդկային կորուստ ունեցե՞լ եք.
            </Title>
          }
          name="hasDiedPerson"
        >
          <Radio.Group options={radioOptions} />
        </FormItem>
      </VerticalSpace>
      {hasDiedPersonId === 1 && renderItems(options)}
    </FormSectionFullCard>
  )
}

export default LostMemberForm
