import React from 'react'
import { Radio } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { averageMonthlyIncome } from './constant'

export const IncomeAfterDisplacement = ({ disabled }) => {
  return (
    <FormSectionCard
      title={
        'Տեղահանվելուց հետո որքա՞ն է կազմել Ձեր ընտանիքի ամսական միջին եկամուտը'
      }
      showRequired={true}
    >
      <FormItem name="afterMonthlyIncomePeriodId" rules={[{ required: true }]}>
        <Radio.Group>
          <VerticalSpace>
            {averageMonthlyIncome.map((item) => {
              return (
                <Radio key={item.id} value={item.id} disabled={disabled}>
                  {item.label}
                </Radio>
              )
            })}
          </VerticalSpace>
        </Radio.Group>
      </FormItem>
    </FormSectionCard>
  )
}
