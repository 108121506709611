import React, { useCallback, useEffect, useState } from 'react'
import { FormItem } from '../../form/form-item'
import { utilityProducts } from '../constatns'
import { Checkbox } from '../../checkbox'
import { Form, InputNumber, Radio } from 'antd'
import { Input } from '../../input'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { Title } from '../../typography'

const createName = (id) => `item${id}`

const UtilityNeeds = ({ getData, disabled }) => {
  const form = Form.useFormInstance()
  const [checkIds, setCheckIds] = useState({})
  const [disableCheckboxes, setDisableCheckboxes] = useState(false)
  const hasHousekeepingNeeds = Form.useWatch('hasHousekeepingNeeds')

  useEffect(() => {
    form.resetFields(['hasHousekeepingNeeds'])
  }, [form])

  useEffect(() => {
    if (getData && getData.products) {
      const updatedCheckedIds = {}
      const productsList = getData?.products?.reduce((acc, curr) => {
        acc[`item${curr.id}`] = {
          ...curr,
        }
        updatedCheckedIds[`item${curr.id}`] = curr.isActive
        return acc
      }, {})

      setCheckIds(productsList)

      setTimeout(function () {
        form.setFieldsValue({
          products: productsList,
        })
      }, 10)
    }
  }, [getData, form])

  useEffect(() => {
    if (checkIds?.item52 && Object.keys(checkIds).length > 1) {
      form.setFields([
        {
          name: ['products'],
          value: { item52: { id: 52 } },
        },
      ])
      setCheckIds({ item52: true })
    } else if (checkIds?.item52) {
      setDisableCheckboxes(true)
    } else {
      setDisableCheckboxes(false)
    }
  }, [checkIds, form])

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const uncheckChildren = (children) => {
    children.forEach((child) => {
      const childName = createName(child.id)
      setCheckIds((prev) => ({ ...prev, [childName]: false }))
      form.setFields([
        {
          name: ['products', childName, 'id'],
          value: false,
        },
      ])
      if (child.children) {
        uncheckChildren(child.children)
      }
    })
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, children) => {
      setCheckIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['products', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])

      if (!checked) {
        form.setFields([
          {
            name: ['products', itemName, 'comment'],
            value: '',
          },
        ])
        if (children) {
          uncheckChildren(children)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form],
  )

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentName, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkIds[itemName]}
              disabled={disabled || (disableCheckboxes && item.id !== 52)}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  item.children,
                )
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && state[itemName] && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label={item.label || 'Նշել '}
              style={{ marginLeft }}
              rules={[{ required: true }]}
            >
              {item.isCommentNumeric ? (
                <InputNumber disabled={disabled} min={1} />
              ) : (
                <Input disabled={disabled} />
              )}
            </FormItem>
          )}
          {item.children &&
            item.children.length > 0 &&
            state[itemName] &&
            renderItems(
              item.children,
              level + 1,
              state,
              setState,
              parentName,
              itemName,
            )}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionFullCard>
      <FormItem
        name={'hasHousekeepingNeeds'}
        label={
          <Title>
            Արդյո՞ք կենցաղի կազմակերպման հարցում ունեք կարիքներ, այդ թվում՝
            սնունդ, դեղեր, տան կահավորանք և պարագաներ.
          </Title>
        }
        rules={[{ required: true }]}
      >
        <Radio.Group
          onChange={(e) => {
            const value = e.target.value

            if (!value) {
              form.setFieldsValue({ products: [] })
              setCheckIds({})
            }

            form.resetFields(['products'])
          }}
          disabled={disabled}
        >
          <Radio value={true}>Այո</Radio>
          <Radio value={false}>Ոչ</Radio>
        </Radio.Group>
      </FormItem>
      {hasHousekeepingNeeds && (
        <>
          <FormItem name="products" rules={[{ required: true }]}>
            {renderItems(utilityProducts, 0, checkIds, setCheckIds, [
              'products',
            ])}
          </FormItem>
        </>
      )}
    </FormSectionFullCard>
  )
}

export default UtilityNeeds
