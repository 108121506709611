import React, { useEffect } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_ACCOMMONDATION_USE_TYPE } from './constants'
import { FormItem } from '../../form/form-item'
import { Form, Radio, Spin } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { Input } from '../../input'
import useGetQuestions from '../../../api/application/use-get-questions'
import NestedRadio from './nested-radio'

const AccommodationUseType = ({ disabled }) => {
  let { data, isLoading } = useGetQuestions(URL_ACCOMMONDATION_USE_TYPE)
  const form = Form.useFormInstance()
  const selectedId = Form.useWatch(['accommodationUseTypeId'])

  useEffect(() => {
    if (selectedId !== 13) {
      form.resetFields([
        'accommodationUseTypeParentId',
        'accommodationUseTypeComment',
      ])
    }
  }, [selectedId, form])

  return (
    <FormSectionCard
      title="Նշեք կացարանի (բնակելի տարածության) օգտագործման ձևը"
      showRequired={true}
    >
      <Spin spinning={isLoading}>
        <FormItem name="accommodationUseTypeId" rules={[{ required: true }]}>
          <Radio.Group disabled={disabled}>
            <VerticalSpace>
              {data.map((row) => (
                <NestedRadio
                  key={row.id}
                  row={row}
                  level={1}
                  selectedId={selectedId}
                  nestedFormItemName="accommodationUseTypeParentId"
                  disabled={disabled}
                />
              ))}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>

        {selectedId === 13 && (
          <FormItem
            name="accommodationUseTypeComment"
            rules={[{ required: true }]}
          >
            <Input placeholder="Նշել" disabled={disabled} />
          </FormItem>
        )}
      </Spin>
    </FormSectionCard>
  )
}

export default AccommodationUseType
