/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_NON_CAN_GO_THERE_REASON } from './constants'
import { FormItem } from '../../form/form-item'
import { createName } from '../../../helpers/utils'
import { Form } from '../../form/form'
import { Checkbox } from 'antd'
import { Input } from '../../input'

const Difficulties = ({ parentFormItem, disabled }) => {
  const { data } = useGetQuestions(URL_NON_CAN_GO_THERE_REASON)
  const [reasons, setReasons] = useState({})
  const form = Form.useFormInstance()

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, parentName, children = []) => {
      setState((prevCheckedIds) => {
        const newCheckedIds = {
          ...prevCheckedIds,
          [itemName]: checked,
        }

        if (!checked) {
          const unsetChildren = (childItems) => {
            for (const child of childItems) {
              const childItemName = createName(child.id)
              newCheckedIds[childItemName] = false

              form.setFields([
                {
                  name: [...parentName, childItemName, 'id'],
                  value: false,
                },
                {
                  name: [...parentName, childItemName, 'comment'],
                  value: undefined,
                },
              ])

              if (child.children && child.children.length > 0) {
                unsetChildren(child.children)
              }
            }
          }

          unsetChildren(children)
          form.setFields([
            {
              name: [...parentName, itemName, 'comment'],
              value: undefined,
            },
          ])
        }

        return newCheckedIds
      })

      form.setFields([
        {
          name: [...parentName, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
    },
    [form],
  )

  const validateCheckboxGroup = (items, formValues) => {
    return items.some((item) => formValues?.[createName(item.id)]?.id)
  }

  const renderItems = (items, level = 0, state, setState, parentName) => {
    const marginLeft = level * 20
    const formValues = form.getFieldValue(parentName)

    return items.map((item) => {
      const itemName = createName(item.id)
      const checked = formValues?.[itemName]?.id ? true : false

      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[...parentName, itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items, formValues)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <FormItem hidden name={[...parentName, itemName, 'uniqueId']}>
              <Input type="hidden" />
            </FormItem>
            <Checkbox
              checked={checked}
              onChange={(e) =>
                handleCheckboxChange(
                  itemName,
                  e.target.checked,
                  item.id,
                  setState,
                  parentName,
                  item.children,
                )
              }
              disabled={disabled}
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checked && (
            <FormItem
              name={[...parentName, itemName, 'comment']}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input disabled={disabled} />
            </FormItem>
          )}
          <FormItem name={[...parentName, itemName, 'id']} hidden />
          {item.children &&
            item.children.length > 0 &&
            checked &&
            renderItems(item.children, level + 1, state, setState, parentName)}
        </React.Fragment>
      )
    })
  }

  return renderItems(data, 0, reasons, setReasons, parentFormItem)
}

export default Difficulties
