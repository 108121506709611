export const URL_GET_HEALTH_SITUATION =
  'api/ProfessionalHealthcare/GetHealthSituation'
export const URL_GET_HEALTH_NEED = 'api/ProfessionalHealthcare/GetHealthNeed'
export const URL_GET_NON_VISIT_DOCTOR_REASON =
  'api/ProfessionalHealthcare/GetNonVisitDoctorReason'
export const URL_GET_NON_SATISFIED_POLYCLINIC_REASON =
  'api/ProfessionalHealthcare/GetNonSatisfiedPolyclinicReason'
export const URL_GET_NON_REGISTERED_POLYCLINIC_REASON =
  'api/ProfessionalHealthcare/GetNonRegisteredPolyclinicReason'
export const URL_GET_PHARMACY_TYPE =
  'api/ProfessionalHealthcare/GetPharmacyType'
export const URL_GET_NON_CHILDREN_VISIT_POLYCLINIC_REASON =
  'api/ProfessionalHealthcare/GetNonChildrenVisitPolyclinicReason'
export const URL_GET_HOUSE_HOLD_PERSONS = 'api/Person/GetHouseholdPersons'
export const URL_GET_HOUSE_HOLD_PERSONS_CHILDRENS =
  'api/Person/GetHouseholdChildren'
export const URL_GET_HOUSE_HOLD_PERSONS_ADULTS = 'api/Person/GetHouseholdAdults'
