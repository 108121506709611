import { FormSectionCard } from '../../card/form-section-card'
import useGetQuestions from '../../../api/application/use-get-questions'
import {
  URL_GET_SUPPORT_DIRECTION,
  URL_GET_SUPPORT_DIRECTION_INFORMATION,
} from './constant'
import React from 'react'
import { Form, Spin } from 'antd'
import RadioButtons from './radio-buttons'
import { FormItem } from '../../form/form-item'
import { useCallback } from 'react'

const SupportDirection = ({ getData }) => {
  const { data, isLoading } = useGetQuestions(URL_GET_SUPPORT_DIRECTION)
  const { data: information } = useGetQuestions(
    URL_GET_SUPPORT_DIRECTION_INFORMATION,
  )

  const form = Form.useFormInstance()

  const onRadioChange = useCallback(
    (checked, id) => {
      const householdSupportDirections =
        form.getFieldValue('householdSupportDirections') || []

      form.setFieldsValue({
        householdSupportDirections: [
          ...householdSupportDirections.filter((field) => field.id !== id), // Remove existing entry for the same id (if any)
          {
            id,
            hasInformation: checked,
            householdSupportDirectionInformations: [], // Empty info array if required
          },
        ],
      })
    },
    [form],
  )

  const onCheckboxChange = useCallback(
    (value, row, id) => {
      const householdSupportDirections = form.getFieldValue(
        'householdSupportDirections',
      )
      const fieldRow = householdSupportDirections.find(
        (field) => field.id === id,
      )

      if (!fieldRow) {
        return // Exit if no matching field is found
      }

      if (value) {
        fieldRow.householdSupportDirectionInformations =
          fieldRow.householdSupportDirectionInformations || [] // Initialize if undefined
        fieldRow.householdSupportDirectionInformations.push({
          id: row.id,
          comment: null,
        })
      } else {
        fieldRow.householdSupportDirectionInformations = (
          fieldRow.householdSupportDirectionInformations || []
        ).filter((field) => field.id !== row.id)
      }

      form.setFieldsValue({
        householdSupportDirections: [...householdSupportDirections],
      })
    },
    [form],
  )

  const onInputChange = useCallback(
    (value, row, parentId) => {
      const householdSupportDirections =
        form.getFieldValue('householdSupportDirections') || []
      const parentField = householdSupportDirections.find(
        (field) => field.id === parentId,
      )

      if (!parentField) {
        // console.error(`Parent field with id ${parentId} not found.`)
        return
      }

      if (!Array.isArray(parentField.householdSupportDirectionInformations)) {
        parentField.householdSupportDirectionInformations = []
      }

      const field = parentField.householdSupportDirectionInformations.find(
        (field) => field.id === row.id,
      )

      if (field) {
        field.comment = value
        form.setFieldsValue({
          householdSupportDirections: [...householdSupportDirections],
        })
      } else {
        // console.error(
        //   `Field with id ${row.id} not found in householdSupportDirectionInformations.`,
        // )
      }
    },
    [form],
  )

  return (
    <FormSectionCard
      title="Գի՞տեք որ կառույցին/ում դիմել, եթե ունենաք/կամ ունեք կարիքներ՝ հետևյալ ուղղություններով"
      showRequired={true}
    >
      <FormItem name="householdSupportDirections" hidden initialValue={[]} />

      <Spin spinning={isLoading}>
        {data.map((row, index) => {
          return (
            <RadioButtons
              key={row.id}
              row={row}
              index={index}
              information={information}
              onCheckboxChange={onCheckboxChange}
              onRadioChange={onRadioChange}
              onInputChange={onInputChange}
              form={form}
              getData={getData}
            />
          )
        })}
      </Spin>
    </FormSectionCard>
  )
}

export default SupportDirection
