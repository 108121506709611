import React, { useCallback, useEffect, useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { Checkbox, Col, Form, Radio, Row, Spin } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import { URL_GET_DRIVING_LICENSE } from './constants'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Select } from '../../select'

const DrivingLicense = ({ dataFamily, getData, disabled }) => {
  const form = Form.useFormInstance()
  const [value, setValue] = useState(null)
  const [disableLast, setDisableLast] = useState(false)
  const [disable, setDisable] = useState(false)

  const { data: dataDrivingLicence, isLoading } = useGetQuestions(
    URL_GET_DRIVING_LICENSE,
  )

  const radioDrivingLicensePart = Form.useWatch('hasDrivingLicense', {
    preserve: true,
  })

  // eslint-disable-next-line no-unused-vars
  const checkDrivingLicense = Form.useWatch('checkboxDrivingLicense', {
    preserve: true,
  })

  useEffect(() => {
    if (getData) {
      const updatedCheckedIds = {}
      const checkboxLanguageList = getData?.householdDrivingLicenses?.reduce(
        (acc, curr) => {
          acc[`DrivingLicense${curr.id}`] = {
            ...curr,
            personIds: curr.personIds
              ? curr.personIds.map((person) => person)
              : [],
          }
          updatedCheckedIds[`DrivingLicense${curr.id}`] = curr.isActive
          return acc
        },
        {},
      )

      if (
        checkboxLanguageList &&
        Object.keys(checkboxLanguageList).length > 0
      ) {
        const personIdByLanguage = Object.keys(checkboxLanguageList).reduce(
          (acc, key) => {
            const languageData = checkboxLanguageList[key]
            if (languageData.personIds?.length > 0) {
              acc[key] = languageData.personIds
            }
            return acc
          },
          {},
        )

        form.setFieldsValue({
          checkboxDrivingLicense: checkboxLanguageList,
          personId: personIdByLanguage,
        })
      }
    }
  }, [form, getData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeDrivingLicenses = useCallback((checked, row) => {
    if (checked && row.id === 8) {
      setDisableLast(true)
    } else if (checked && row.id !== 8) {
      setDisable(true)
    } else {
      setDisableLast(false)
      setDisable(false)
    }

    if (checked) {
      form.setFieldsValue({
        householdDrivingLicenses: [
          ...form.getFieldValue('householdDrivingLicenses'),
          { id: row.id, personIds: [] },
        ],
      })
    } else {
      form.setFieldsValue({
        householdDrivingLicenses: form
          .getFieldValue('householdDrivingLicenses')
          .filter((r) => r.id !== row.id),
      })
    }
  })

  const onInputChangeDrivingSelect = useCallback(
    (value, id) => {
      const licenses = form.getFieldValue('householdDrivingLicenses') || []
      const index = licenses.findIndex((r) => r.id === id)

      if (index !== -1) {
        const newData = [...licenses]
        newData[index].personIds = value
        form.setFieldsValue({
          householdDrivingLicenses: newData,
        })
      }
    },
    [form],
  )

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Ընտանիքում կա՞ անձ, ով ունի վարորդական իրավունք"
        showRequired={true}
      >
        <FormItem name="householdDrivingLicenses" hidden initialValue={[]} />

        <Row>
          <FormItem
            name={'hasDrivingLicense'}
            style={{ width: '100%' }}
            rules={[{ required: true }]}
          >
            <Radio.Group
              disabled={disabled}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
                form.resetFields(['checkboxDrivingLicense'])
                form.setFieldsValue({ householdDrivingLicenses: [] })
              }}
              style={{ width: '100%' }}
            >
              <VerticalSpace>
                <Radio value={true}>Այո</Radio>
                {radioDrivingLicensePart === true ? (
                  <FormItem
                    name={['checkboxDrivingLicense']}
                    style={{ marginLeft: '25px' }}
                    rules={[{ required: true }]}
                  >
                    {dataDrivingLicence?.map((row) => {
                      return (
                        <Row key={row.id} style={{ alignItems: 'center' }}>
                          <Col span={8}>
                            <FormItem
                              name={['checkboxDrivingLicense', row.label]}
                              valuePropName="checked"
                            >
                              <Checkbox
                                disabled={
                                  disabled ||
                                  (row.id === 8 ? disable : disableLast)
                                }
                                value={row.id}
                                onChange={(e) => {
                                  onChangeDrivingLicenses(e.target.checked, row)
                                  form.resetFields([['personId', row.label]])
                                }}
                              >
                                {row.name}
                              </Checkbox>
                            </FormItem>
                          </Col>
                          {checkDrivingLicense &&
                          checkDrivingLicense[row.label] ? (
                            <Col span={12}>
                              <FormItem
                                name={['personId', row.label]}
                                rules={[{ required: true }]}
                              >
                                <Select
                                  disabled={disabled}
                                  mode={'multiple'}
                                  placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                                  onChange={(e) => {
                                    onInputChangeDrivingSelect(e, row.id)
                                  }}
                                >
                                  {dataFamily?.map((item) => (
                                    <Select.Option
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.firstName + ' ' + item.lastName}
                                    </Select.Option>
                                  ))}
                                </Select>
                              </FormItem>
                            </Col>
                          ) : (
                            <FormItem hidden />
                          )}
                        </Row>
                      )
                    })}
                  </FormItem>
                ) : (
                  <FormItem hidden />
                )}
                <Radio value={false}>Ոչ</Radio>
              </VerticalSpace>
            </Radio.Group>
          </FormItem>
        </Row>
      </FormSectionCard>
    </Spin>
  )
}

export default DrivingLicense
