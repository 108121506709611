import React, { useCallback } from 'react'
import { FormItem } from '../../../form/form-item'
import { Select } from '../../../select'
import { Form } from 'antd'

const FmSelect = ({ nameItem, dataFamily, item, indexRow, style }) => {
  const form = Form.useFormInstance()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeSelectValue = useCallback(
    (checked, id, indexRow) => {
      const selectChange = form.getFieldValue('personHealthSituations') || []
      const index = selectChange.findIndex((r) => r.id === id)

      if (index !== -1) {
        const newData = [...selectChange]

        if (!Array.isArray(newData[index].healthSituationPeople)) {
          newData[index].healthSituationPeople = []
        }

        if (!newData[index].healthSituationPeople[indexRow]) {
          newData[index].healthSituationPeople[indexRow] = {
            personId: null,
            periodId: null,
            // id: indexRow,
          }
        }

        newData[index].healthSituationPeople[indexRow].personId = checked
        form.setFieldsValue({ personHealthSituations: newData })
      }
    },
    [form],
  )

  return (
    <FormItem name={nameItem} style={style} rules={[{ required: true }]}>
      <Select
        placeholder={'Նշել ընտանիքի անդամին(ներին)'}
        onChange={(e) => {
          changeSelectValue(e, item.id, indexRow)
        }}
      >
        {dataFamily?.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.firstName + ' ' + item.lastName}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  )
}

export default FmSelect
