import React, { useState, useCallback, useEffect } from 'react'
import { Checkbox, Col, Form, Row, Spin } from 'antd'
import { FormItem } from '../../../form/form-item'
import { URL_GET_NON_REGISTERED_POLYCLINIC_REASON } from '../constants'
import { COLORS } from '../../../../helpers/constants'
// eslint-disable-next-line no-unused-vars
import { Text } from '../../../typography'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { FormSectionCard } from '../../../card/form-section-card'
import { Button } from '../../../button'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Select } from '../../../select'
// eslint-disable-next-line no-unused-vars
import { Input } from '../../../input'

const PolyclinicServing = ({ dataFamily, nameItemSelect, getData }) => {
  const form = Form.useFormInstance()
  const [getSelectPs, setSelectPs] = useState(false)
  // const [checkedItems, setCheckedItems] = useState(false)
  const { data: nonRegisteredPolyclinicReason, isLoading } = useGetQuestions(
    URL_GET_NON_REGISTERED_POLYCLINIC_REASON,
  )

  /* eslint-disable no-console */

  useEffect(() => {
    if (getData) {
      const groupByPersonId = (array) =>
        array?.reduce((acc, item) => {
          let existingPerson = acc?.find((p) => p.personId === item.personId)

          if (existingPerson) {
            existingPerson?.checkboxAnswers?.push({
              comment: item.comment,
              id: item.id,
              isActive: item.isActive,
            })
          } else {
            acc.push({
              personId: item.personId,
              isAdult: item.isAdult,
              isChild: item.isChild,
              hasCommentPart: item.comment ? true : false,
              checkboxAnswers: [
                {
                  comment: item.comment,
                  id: item.id,
                  isActive: item.isActive,
                },
              ],
            })
          }

          return acc
        }, [])

      const groupedReasons = groupByPersonId(
        getData?.householdNonRegisteredPolyclinicReasons,
      )

      groupedReasons?.checkboxAnswers?.forEach((reason) => {
        form.setFieldsValue({
          [`polyclinicServingTheAreaInput${reason.id}`]:
            reason.isActive || false, // Set initial checkbox state
        })
      })

      const adultReasons = groupedReasons
        ?.filter((item) => item.isAdult)
        ?.map((item, index) => ({
          ...item,
          [`nameItemSelect${index}`]: item.personId || null,
          [`hasCommentPart${index}`]: item.hasCommentPart,
          ...item.checkboxAnswers.reduce((acc, answer) => {
            acc[`polyclinicServingTheAreaInput${answer.id}`] = answer?.isActive
            acc[`polyclinicServingTheAreaComment${answer.id}`] = answer?.comment
            return acc
          }, {}),
        }))

      const childReasons = groupedReasons
        ?.filter((item) => item.isChild)
        ?.map((item, index) => ({
          ...item,
          [`nameItemSelect${index}`]: item.personId || null,
          [`hasCommentPart${index}`]: item.hasCommentPart,
          ...item.checkboxAnswers.reduce((acc, answer) => {
            acc[`polyclinicServingTheAreaInput${answer.id}`] = answer?.isActive
            acc[`polyclinicServingTheAreaComment${answer.id}`] = answer?.comment
            return acc
          }, {}),
        }))

      const initialCheckedItemsState = {}
      groupedReasons.forEach((group) =>
        group.checkboxAnswers.forEach((answer) => {
          initialCheckedItemsState[answer.id] = answer.isActive
        }),
      )

      // setCheckedItems(initialCheckedItemsState)

      form.setFieldsValue({
        requestMemberItemsreasonsForNotBeingAdult: adultReasons,
        requestMemberItemsselectChildren: childReasons,
      })
    }
  }, [getData, form])

  const onCheckboxChange = useCallback(
    (checked, row) => {
      if (checked) {
        if (!row.children.length) {
          form.setFieldsValue({
            householdNonRegisteredPolyclinicReasons: [
              ...form.getFieldValue('householdNonRegisteredPolyclinicReasons'),
              { id: row.id, personId: getSelectPs, comment: null },
            ],
          })
        }
      } else {
        form.setFieldsValue({
          householdNonRegisteredPolyclinicReasons: form
            .getFieldValue('householdNonRegisteredPolyclinicReasons')
            .filter((r) => r.id !== row.id),
        })
      }
    },
    [form, getSelectPs],
  )

  const onInputChange = useCallback(
    (value, id) => {
      const reasons =
        form.getFieldValue('householdNonRegisteredPolyclinicReasons') || []
      const index = reasons.findIndex((r) => r.id === id)
      if (index === -1) {
        return
      }
      const newData = [...reasons]
      newData[index] = {
        ...newData[index],
        comment: value,
      }
      form.setFieldsValue({ householdNonRegisteredPolyclinicReasons: newData })
    },
    [form],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps,no-unused-vars
  const changeSelectValue = useCallback((checked, indexRow) => {
    setSelectPs(checked)
  })

  return (
    <Spin spinning={isLoading}>
      <FormItem
        name="householdNonRegisteredPolyclinicReasons"
        hidden
        initialValue={[]}
      />
      <Form.List
        name={`requestMemberItems${nameItemSelect}`}
        initialValue={[{}]}
      >
        {(fields, { add, remove }) =>
          fields.map((field, index) => (
            // eslint-disable-next-line react/jsx-key
            <FormSectionCard
              title={
                fields.length > 1 && index !== 0 ? (
                  <Col
                    span={2}
                    style={{
                      alignSelf: 'center',
                      marginBottom: '-10px',
                    }}
                  >
                    <Button
                      style={{ borderRadius: '0' }}
                      onClick={() => {
                        remove(field.name)
                      }}
                    >
                      <MinusOutlined />
                    </Button>
                  </Col>
                ) : (
                  <Col
                    span={2}
                    style={{
                      alignSelf: 'center',
                      marginBottom: '-10px',
                    }}
                  >
                    <Button style={{ borderRadius: '0' }} onClick={() => add()}>
                      <PlusOutlined
                        style={{
                          color: COLORS.PRIMARY.BLUE,
                          padding: 0,
                        }}
                      />
                    </Button>
                  </Col>
                )
              }
              showRequired={false}
              style={{ marginBottom: '20px' }}
            >
              <Row gutter={16} key={index}>
                <Col span={24} style={{ alignSelf: 'center' }}>
                  <FormItem
                    name={[field.name, `nameItemSelect${index}`]}
                    style={{ width: '350px' }}
                    rules={[{ required: true }]}
                  >
                    <Select
                      placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                      onChange={(e) => {
                        changeSelectValue(e, index)
                      }}
                    >
                      {dataFamily?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.firstName + ' ' + item.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={24} style={{ alignSelf: 'center' }}>
                  <Text color={COLORS.PRIMARY.GRAY_DARK}>
                    Խնդրում եմ նշել՝ որոնք են հաշվառված չլինելու պատճառները
                  </Text>
                  {nonRegisteredPolyclinicReason?.map((row) => {
                    return (
                      <FormItem
                        key={row.id}
                        name={[
                          field.name,
                          `polyclinicServingTheAreaInput${row.id}`,
                        ]}
                        valuePropName="checked"
                      >
                        {/*{row.name !== 'Այլ (նշել)' && (*/}
                        <Checkbox
                          // checked={checkedItems}
                          onChange={(e) => {
                            // setChecked(e.target.checked)
                            onCheckboxChange(e.target.checked, row)
                          }}
                          value={row.id}
                          style={{ marginLeft: 30 }}
                        >
                          {row.name}
                        </Checkbox>
                        {/*)}*/}

                        {row.hasComment && (
                          <Form.Item
                            name={[
                              field.name,
                              `polyclinicServingTheAreaComment${row.id}`,
                            ]}
                            label={'Այլ (նշել)'}
                            // rules={[{ required: true }]}
                            style={{ marginLeft: 30 }}
                          >
                            <Input
                              onChange={(e) =>
                                onInputChange(e.target.value, row)
                              }
                            />
                          </Form.Item>
                        )}
                      </FormItem>
                    )
                  })}
                </Col>
              </Row>
            </FormSectionCard>
          ))
        }
      </Form.List>
    </Spin>
  )
}

export default PolyclinicServing
