import { useMutation } from '@tanstack/react-query'
import { client } from '../client'

export const createUrlForCurrentStep = (optionPath, page) => {
  return `/api/${optionPath}/SavePage${page}`
}

export const useSaveApplication = (url, options) => {
  const mutation = useMutation({
    mutationFn: (values) => {
      return client.post(url, values)
    },
    ...options,
  })
  return mutation
}
