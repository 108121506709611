import React, { useCallback, useState, useEffect } from 'react'
import { Form } from 'antd'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import useGetQuestions from '../../../api/application/use-get-questions'
import { SmallInput } from '../../input/small-input'
import { GET_INFORMATION_AFFECTED_FACTOR } from './constants'
import { Title } from '../../typography'
import { COLORS } from '../../../helpers/constants'

export const InformationAffectedFactor = ({ disabled }) => {
  const form = Form.useFormInstance()
  const { data: questions } = useGetQuestions(GET_INFORMATION_AFFECTED_FACTOR)
  const [checkedIds, setCheckedIds] = useState({})

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['householdInformationAffectedFactors', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])

      if (!checked) {
        form.setFields([
          {
            name: ['householdInformationAffectedFactors', itemName, 'comment'],
            value: '',
          },
        ])
      }

      if (!checked) {
        const uncheckChildren = (parentId) => {
          const parentItem = questions.find((item) => item.id === parentId)
          if (parentItem && parentItem.children) {
            parentItem.children.forEach((child) => {
              const childName = child.id
              setCheckedIds((prevCheckedIds) => ({
                ...prevCheckedIds,
                [childName]: false,
              }))
              form.setFields([
                {
                  name: [
                    'householdInformationAffectedFactors',
                    childName,
                    'id',
                  ],
                  value: false,
                },
                {
                  name: [
                    'householdInformationAffectedFactors',
                    childName,
                    'comment',
                  ],
                  value: '',
                },
              ])
              if (child.children && child.children.length > 0) {
                uncheckChildren(childName)
              }
            })
          }
        }
        uncheckChildren(itemName)
      }
    },
    [form, questions],
  )

  useEffect(() => {
    const initializeCheckedIds = (items) => {
      const initialChecked = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = item.id
          const isChecked = form.getFieldValue([
            'householdInformationAffectedFactors',
            itemName,
          ])
          initialChecked[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children)
          }
        })
      }
      traverse(items)
      return initialChecked
    }
    const initialCheckedIds = initializeCheckedIds(questions)
    setCheckedIds(initialCheckedIds)
  }, [form, questions])

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = item.id
      return (
        <React.Fragment key={item.id}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormItem
              name={['householdInformationAffectedFactors', itemName]}
              style={{ marginLeft }}
              rules={[
                {
                  validator: () =>
                    Object.values(checkedIds).some(Boolean)
                      ? Promise.resolve()
                      : Promise.reject(new Error('Պարտադիր դաշտ')),
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Checkbox
                checked={checkedIds[itemName]}
                disabled={disabled}
                onChange={(e) =>
                  handleCheckboxChange(itemName, e.target.checked, item.id)
                }
              >
                {item.name}
              </Checkbox>
            </FormItem>
            {item.hasComment && checkedIds[itemName] && (
              <FormItem
                name={[
                  'householdInformationAffectedFactors',
                  itemName,
                  'comment',
                ]}
                rules={[{ required: true }]}
              >
                <SmallInput disabled={disabled} size="small" />
              </FormItem>
            )}
          </div>
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1)}
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <Title>
        Գործոններ, որոնք կարող են ազդել տեղեկատվության հավաստիության վրա
        <span
          style={{
            color: COLORS.ALERT.RED,
            marginLeft: '5px',
            fontWeight: 100,
          }}
        >
          *
        </span>
      </Title>
      <br />
      {renderItems(questions)}
    </>
  )
}

export default InformationAffectedFactor
