import React, { useCallback, useEffect, useState } from 'react'
import { Form, Radio, Spin } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { GET_TERMINATION_REASON } from './constants'
import useGetQuestions from '../../../api/application/use-get-questions'
import { SecondaryText } from '../../typography'
import { InformationAffectedFactor } from './information-affected-factor'
import { Info } from '../../typography/info'
import { Checkbox } from '../../checkbox'
import { SmallInput } from '../../input/small-input'
import { COLORS } from '../../../helpers/constants'

export const NeedsAssessmentTerminationCheck = ({ backendData, disabled }) => {
  const form = Form.useFormInstance()
  const [value, setValue] = useState(false)
  const [checkedIds, setCheckedIds] = useState({})
  const { data: questions, isLoading } = useGetQuestions(GET_TERMINATION_REASON)

  const onCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['householdTerminationReasons', itemName],
          value: checked ? itemId : false,
        },
      ])

      if (!checked) {
        form.setFields([
          {
            name: ['householdTerminationReasons', itemName, 'comment'],
            value: '',
          },
        ])
      }

      if (!checked) {
        const uncheckChildren = (parentId) => {
          const parentItem = questions.find((item) => item.id === parentId)
          if (parentItem && parentItem.children) {
            parentItem.children.forEach((child) => {
              const childName = child.id
              setCheckedIds((prevCheckedIds) => ({
                ...prevCheckedIds,
                [childName]: false,
              }))
              form.setFields([
                {
                  name: ['householdTerminationReasons', childName],
                  value: false,
                },
                {
                  name: ['householdTerminationReasons', childName, 'comment'],
                  value: '',
                },
              ])
              if (child.children && child.children.length > 0) {
                uncheckChildren(childName)
              }
            })
          }
        }
        uncheckChildren(itemName)
      }
    },
    [form, questions],
  )

  const onInputChange = useCallback(
    (value, id) => {
      const terminationReasons = form.getFieldValue('terminationReasons') || []
      const index = terminationReasons.findIndex((reason) => reason.id === id)

      if (index !== -1) {
        const updatedReasons = [...terminationReasons]
        updatedReasons[index].comment = value

        form.setFieldsValue({ terminationReasons: updatedReasons })
      }
    },
    [form],
  )

  useEffect(() => {
    setValue(backendData?.isTermination)
  }, [backendData])

  useEffect(() => {
    const setInitialCheckedState = (items) => {
      const initialCheckedIds = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = item.id
          const isChecked = form.getFieldValue([
            'householdTerminationReasons',
            itemName,
          ])
          initialCheckedIds[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children) // Recursively handle children
          }
        })
      }
      traverse(items)
      return initialCheckedIds
    }

    const initialCheckedIds = setInitialCheckedState(questions)
    setCheckedIds(initialCheckedIds)
  }, [form, questions])

  return (
    <VerticalSpace>
      <Info
        text={
          'ՑՈՒՑՈՒՄ .Ամփոփման հատվածը լրացնել տնայցի ավարտին, շահառուի տանից դուրս գալուց հետո՝ ոչ հարցվողի ներկայությամբ։'
        }
      />
      <FormSectionCard
        showRequired={true}
        title="Արդյոք կարիքների գնահատման ընթացքը դադարեցվել է նախատեսված ժամանակից շուտ (հարցաշարը լրացվել է ոչ ամբողջական)"
      >
        <FormItem name="householdTerminationReasons" hidden initialValue={[]} />
        <Spin spinning={isLoading}>
          <FormItem name={'isTermination'} rules={[{ required: true }]}>
            <Radio.Group
              style={{ marginBottom: 20 }}
              disabled={disabled}
              value={value}
              onChange={(e) => {
                setValue(e.target.value)
              }}
            >
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </Radio.Group>
          </FormItem>

          {value === true ? (
            <React.Fragment>
              <FormItem
                label={
                  <SecondaryText>
                    Ո՞րն էր պատճառը
                    <span style={{ color: COLORS.ALERT.RED }}>*</span>
                  </SecondaryText>
                }
                style={{ marginLeft: '20px' }}
                rules={[
                  {
                    validator: () =>
                      Object.values(checkedIds).some(Boolean)
                        ? Promise.resolve()
                        : Promise.reject(new Error('Պարտադիր դաշտ')),
                    validateTrigger: 'onSubmit',
                  },
                ]}
                name={['householdTerminationReasons', 'terminationReasonId']}
              >
                <VerticalSpace>
                  {questions.map((row) => (
                    <React.Fragment key={row.id}>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Form.Item
                          name={[
                            'householdTerminationReasons',
                            row.id,
                            'terminationReasonId',
                          ]}
                        >
                          <Checkbox
                            checked={checkedIds[row.id]}
                            disabled={disabled}
                            onChange={(e) =>
                              onCheckboxChange(row.id, e.target.checked, row)
                            }
                          >
                            {row.name}
                          </Checkbox>
                        </Form.Item>

                        {row.hasComment && checkedIds[row.id] && (
                          <Form.Item
                            name={[
                              'householdTerminationReasons',
                              row.id,
                              'comment',
                            ]}
                          >
                            <SmallInput
                              size="small"
                              disabled={disabled}
                              onChange={onInputChange}
                            />
                          </Form.Item>
                        )}
                      </div>
                    </React.Fragment>
                  ))}
                </VerticalSpace>
              </FormItem>
            </React.Fragment>
          ) : null}
          <InformationAffectedFactor disabled={disabled} />
        </Spin>
      </FormSectionCard>
    </VerticalSpace>
  )
}
