import React, { useEffect } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import SupportFriend from './support-friends'
import SupportWays from './support-ways'
import LastMonthSupport from './last-month-support'
import { Form } from 'antd'

export const TenthStep = ({ getData, disabled }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      form.setFieldsValue({
        hasSupportFriend: getData?.hasSupportFriend,
      })
    }
  }, [form, getData])
  return (
    <VerticalSpace>
      <LastMonthSupport getData={getData} disabled={disabled} />
      <SupportFriend getData={getData} disabled={disabled} />
      <SupportWays getData={getData} disabled={disabled} />
    </VerticalSpace>
  )
}
