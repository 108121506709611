/* eslint-disable */
import { Steps as StepsComponent } from 'antd'
import styled from 'styled-components'
import { CheckCircleFilled } from '@ant-design/icons'
import { ReactComponent as StepIn } from '../../icons/step-in.svg'
import { ReactComponent as StepNotVisited } from '../../icons/step-not-visited.svg'
import { useMemo } from 'react'
import { useApplicationSteps } from '../../context/applicaton-steps-context'
import {
  URL_GET_EIGHTH_PAGE_DATA,
  URL_GET_ELEVENTH_PAGE_DATA,
  URL_GET_FIFTEEN_PAGE_DATA,
  URL_GET_FIFTH_PAGE_DATA,
  URL_GET_FIRST_PAGE_DATA,
  URL_GET_FORTH_PAGE_DATA,
  URL_GET_FOURTEEN_PAGE_DATA,
  URL_GET_NINTH_PAGE_DATA,
  URL_GET_SECOND_PAGE_DATA,
  URL_GET_SEVENTH_PAGE_DATA,
  URL_GET_SIXTH_PAGE_DATA,
  URL_GET_TENTH_PAGE_DATA,
  URL_GET_THIRD_PAGE_DATA,
  URL_GET_THIRTEENTH_PAGE_DATA,
  URL_GET_TWELFTH_PAGE_DATA,
} from '../../api/application/constants'

const Steps = styled(StepsComponent)`
  .ant-steps-item-container {
    padding-bottom: 40px;
  }
`

export const steps = [
  {
    title: 'Անձի համաձայնությունը՝ մասնակցելու գնահատմանը',
    url: URL_GET_FIRST_PAGE_DATA,
  },
  {
    title: 'Ընտանիքի անդամների տվյալներ',
    url: URL_GET_SECOND_PAGE_DATA,
  },
  {
    title: 'Ներկայիս բնակավայրը',
    url: URL_GET_THIRD_PAGE_DATA,
  },
  {
    title: 'Կոմունալ կենցաղային պայմաններ',
    url: URL_GET_FORTH_PAGE_DATA,
  },
  {
    title: 'Սննդապահովման կարիքի հրատապություն',
    url: URL_GET_FIFTH_PAGE_DATA,
  },
  {
    title: 'Մասնագիտական աջակցության և առողջապահական կարիքներ',
    url: URL_GET_SIXTH_PAGE_DATA,
  },
  {
    title: 'Կրթության կազմակերպում և կրթական կարիքներ',
    url: URL_GET_SEVENTH_PAGE_DATA,
  },
  {
    title: 'Զբաղվածություն',
    url: URL_GET_EIGHTH_PAGE_DATA,
  },
  {
    title: 'Ընտանիքի եկամուտները և խնդիրների հաղթահարման ռազմավարությունը',
    url: URL_GET_NINTH_PAGE_DATA,
  },
  {
    title: 'Աջակցող ցանց',
    url: URL_GET_TENTH_PAGE_DATA,
  },
  {
    title: 'Ընտանիքի անդամների կարողությունները ունակությունները',
    url: URL_GET_ELEVENTH_PAGE_DATA,
  },
  {
    title: 'Փոխհարաբերությունների բնույթը',
    url: URL_GET_TWELFTH_PAGE_DATA,
  },
  {
    title: 'Կարգավիճակ և տեղաշարժի միտումներ',
    url: URL_GET_THIRTEENTH_PAGE_DATA,
    style: { display: 'none' },
  },
  {
    title: 'Իրազեկվածությունը և տեղեկատվության ստացման նախընտրելի եղանակները',
    url: URL_GET_FOURTEEN_PAGE_DATA,
  },
  {
    title: 'Ամփոփում',
    url: URL_GET_FIFTEEN_PAGE_DATA,
  },
]

export const ApplicationSteps = ({ collapsed }) => {
  const { step, jump, finishedSteps } = useApplicationSteps()
  const jumpValue = localStorage.getItem('jumpValue')
  const onChange = (value) => {
    // jump(value)
    if(jumpValue === null) {
      jump(value)
    } if(jumpValue === 'view') {
      jump(value)
    } else {
      if(value <= jumpValue) {
        jump(value)
      }
    }
  }

  const items = useMemo(
    () =>
      steps.map((item, index) => ({
        ...(collapsed ? { title: '' } : item),
        ...(finishedSteps.includes(index)
          ? { icon: <CheckCircleFilled style={{ fontSize: 32 }} /> }
          : index === step
            ? { icon: <StepIn /> }
            : { icon: <StepNotVisited /> }),
      })),
    [finishedSteps, step, collapsed],
  )

  return (
    <Steps
      direction="vertical"
      size="default"
      current={step}
      onChange={onChange}
      items={items}
    />
  )
}
