import React, { useCallback } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { Col, Form, Radio, Row, Spin } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Button } from '../../button'
import { COLORS } from '../../../helpers/constants'
import { URL_GET_QUESTIONS } from './constants'
import useGetQuestions from '../../../api/application/use-get-questions'
import { Text } from '../../typography'
import { FormItem } from '../../form/form-item'
import { Select } from '../../select'
import { VerticalSpace } from '../../space/vertical-space'

//! Page should be rewritten
const RequestMemberName = ({ dataFamily, disabled }) => {
  const form = Form.useFormInstance()
  const { data: dataQuestions, isLoading } = useGetQuestions(URL_GET_QUESTIONS)

  const handleRemoveMember = (fieldName) => {
    const currentData =
      form.getFieldValue('householdPersonAbilityQuestions') || []

    const updatedData = currentData.filter((item, index) => index !== fieldName)

    form.setFieldsValue({
      householdPersonAbilityQuestions: updatedData,
    })
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeSelectRequestMemberData = useCallback(
    (checked, id) => {
      const index = form
        .getFieldValue('householdPersonAbilityQuestions')
        ?.findIndex((r) => r.rowId === id)

      const newData = [...form.getFieldValue('householdPersonAbilityQuestions')]

      if (index === undefined || newData.length === 0 || index === -1) {
        if (checked) {
          form.setFieldsValue({
            householdPersonAbilityQuestions: [
              ...form.getFieldValue('householdPersonAbilityQuestions'),
              {
                rowId: id,
                personId: checked,
                abilityQuestions: [],
              },
            ],
          })
        } else {
          form.setFieldsValue({
            householdPersonAbilityQuestions: form
              .getFieldValue('householdPersonAbilityQuestions')
              .filter((r) => r.rowId !== id),
          })
        }
      } else {
        newData[index].personId = checked
        form.setFieldsValue({
          householdPersonAbilityQuestions: newData,
        })
      }
    },
    [form],
  )

  const changeSelectRadioGroup = useCallback(
    (checked, questionId, memberIndex) => {
      const currentData =
        form.getFieldValue('householdPersonAbilityQuestions') || []

      if (!currentData[memberIndex]) {
        return
      }

      const updatedData = [...currentData]

      const memberData = updatedData[memberIndex]

      if (!memberData.personId) {
        memberData.personId = form.getFieldValue([
          'requestMemberItems',
          memberIndex,
          'personId',
        ])
      }

      if (!Array.isArray(memberData.abilityQuestions)) {
        memberData.abilityQuestions = []
      }

      const questionIndex = memberData.abilityQuestions.findIndex(
        (q) => q.id === questionId,
      )

      if (questionIndex > -1) {
        memberData.abilityQuestions[questionIndex].answerId = checked
      } else {
        memberData.abilityQuestions.push({
          id: questionId,
          answerId: checked,
        })
      }

      updatedData[memberIndex] = memberData

      form.setFieldsValue({
        householdPersonAbilityQuestions: updatedData,
      })
    },
    [form],
  )

  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // const changeSelectRadioGroup = useCallback(
  //   (checked, rowId, id) => {
  //     /* eslint-disable no-console */
  //     const currentData = form.getFieldValue('householdPersonAbilityQuestions')
  //     const person = currentData ? currentData[id] : null
  //
  //     // Get personId
  //     const personId = person?.personId
  //
  //     const index = form
  //       .getFieldValue('householdPersonAbilityQuestions')
  //       ?.findIndex((r) => r.rowId === id)
  //
  //     const newData = [...form.getFieldValue('householdPersonAbilityQuestions')]
  //
  //     if (index === undefined || newData.length === 0 || index === -1) {
  //       if (checked) {
  //         form.setFieldsValue({
  //           householdPersonAbilityQuestions: [
  //             ...form.getFieldValue('householdPersonAbilityQuestions'),
  //             {
  //               rowId: id,
  //               personId: personId,
  //               abilityQuestions: [
  //                 {
  //                   id: rowId,
  //                   answerId: checked,
  //                 },
  //               ],
  //             },
  //           ],
  //         })
  //       } else {
  //         form.setFieldsValue({
  //           householdPersonAbilityQuestions: form
  //             .getFieldValue('householdPersonAbilityQuestions')
  //             .filter((r) => r.rowId !== id),
  //         })
  //       }
  //     } else {
  //       const existingIndex = newData[index].abilityQuestions.findIndex(
  //         (item) => item.id === rowId,
  //       )
  //
  //       if (existingIndex > -1) {
  //         newData[index].abilityQuestions[existingIndex] = {
  //           id: rowId,
  //           answerId: checked,
  //         }
  //       } else {
  //         newData[index].abilityQuestions.push({
  //           id: rowId,
  //           answerId: checked,
  //         })
  //       }
  //
  //       form.setFieldsValue({
  //         householdPersonAbilityQuestions: newData,
  //       })
  //     }
  //   },
  //   [form],
  // )

  return (
    <Spin spinning={isLoading}>
      <FormItem
        name="householdPersonAbilityQuestions"
        hidden
        initialValue={[]}
      />

      <Form.List name="requestMemberItems" initialValue={[{}]}>
        {(fields, { add, remove }) =>
          fields.map((field, index) => (
            // eslint-disable-next-line react/jsx-key
            <FormSectionCard
              title={
                fields.length > 1 && index !== 0 ? (
                  <Col
                    span={2}
                    style={{ alignSelf: 'center', marginBottom: '-10px' }}
                  >
                    {!disabled && (
                      <Button
                        style={{ borderRadius: '0' }}
                        onClick={() => {
                          remove(field.name)
                          handleRemoveMember(index)
                        }}
                      >
                        <MinusOutlined />
                      </Button>
                    )}
                  </Col>
                ) : (
                  <Col
                    span={2}
                    style={{ alignSelf: 'center', marginBottom: '-10px' }}
                  >
                    {!disabled && (
                      <Button
                        style={{ borderRadius: '0' }}
                        onClick={() => add()}
                      >
                        <PlusOutlined
                          style={{ color: COLORS.PRIMARY.BLUE, padding: 0 }}
                        />
                      </Button>
                    )}
                  </Col>
                )
              }
              showRequired={false}
              style={{ marginBottom: '20px' }}
            >
              <Row gutter={16} key={index}>
                <Col
                  xs={{ span: 24 }}
                  lg={{ span: 12 }}
                  style={{ alignSelf: 'center' }}
                >
                  <Text
                    style={{ fontSize: '20px' }}
                    color={COLORS.PRIMARY.BLUE}
                  >
                    Ցանկից ընտրել հարցվող անդամի անունը
                    <span
                      style={{
                        color: COLORS.ALERT.RED,
                        marginLeft: '5px',
                        fontWeight: 100,
                      }}
                    >
                      *
                    </span>
                  </Text>
                  <FormItem
                    name={[field.name, 'personId']}
                    rules={[{ required: true }]}
                    style={{ marginTop: '20px' }}
                  >
                    <Select
                      disabled={disabled}
                      placeholder={'Նշել ընտանիքի անդամին(ներին)'}
                      onChange={(e) => {
                        changeSelectRequestMemberData(e, index)
                      }}
                    >
                      {dataFamily?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.firstName + ' ' + item.lastName}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={24} style={{ alignSelf: 'center' }}>
                  {dataQuestions?.map((item) => {
                    return (
                      <React.Fragment key={item.id}>
                        <FormItem
                          name={['requestMemberFamilyRadio', item.name]}
                          style={{ width: '100%' }}
                          label={
                            <Text color={COLORS.PRIMARY.BLUE}>
                              {item.id}.
                              {item?.hasComment === true && (
                                <span
                                  style={{
                                    color: COLORS.ALERT.RED,
                                    marginLeft: '5px',
                                    fontWeight: 100,
                                  }}
                                >
                                  *
                                </span>
                              )}
                              {item.name}
                            </Text>
                          }
                        >
                          {item?.hasComment === true ? (
                            <FormItem
                              name={[
                                field.name,
                                `requestMemberFamilyRadio[${item.id}][]`,
                              ]}
                              rules={[{ required: true }]}
                              style={{ marginLeft: '20px' }}
                            >
                              <Radio.Group
                                disabled={disabled}
                                onChange={(e) => {
                                  changeSelectRadioGroup(
                                    e.target.value,
                                    item.id,
                                    index,
                                  )
                                }}
                              >
                                <VerticalSpace>
                                  <Radio value={1}>
                                    Ոչ, ոչ մի դժվարություն
                                  </Radio>
                                  <Radio value={2}>
                                    Այո, որոշ դժվարություններ
                                  </Radio>
                                  <Radio value={3}>
                                    Այո, շատ դժվարություններ
                                  </Radio>
                                  <Radio value={4}>Ընդհանրապես չեմ կարող</Radio>
                                </VerticalSpace>
                              </Radio.Group>
                            </FormItem>
                          ) : (
                            <FormItem
                              name={[
                                field.name,
                                `requestMemberFamilyYesNo${item.id}`,
                              ]}
                              style={{ marginLeft: '20px' }}
                            >
                              <Radio.Group
                                disabled={disabled}
                                onChange={(e) => {
                                  changeSelectRadioGroup(
                                    e.target.value,
                                    item.id,
                                    index,
                                  )
                                }}
                              >
                                <VerticalSpace>
                                  <Radio value={1}>Այո</Radio>
                                  <Radio value={2}>Ոչ</Radio>
                                  <Radio value={3}>
                                    Չի ցանկանում պատասխանել
                                  </Radio>
                                </VerticalSpace>
                              </Radio.Group>
                            </FormItem>
                          )}
                        </FormItem>
                      </React.Fragment>
                    )
                  })}
                </Col>
              </Row>
            </FormSectionCard>
          ))
        }
      </Form.List>
    </Spin>
  )
}

export default RequestMemberName
