import React, { useCallback } from 'react'
import { FormItem } from '../../../form/form-item'
import { Select } from '../../../select'
import { Form } from 'antd'

const SnSelect = ({ nameItem, dataFamily, row, style }) => {
  const form = Form.useFormInstance()

  // eslint-disable-next-line no-unused-vars
  const selectionSupportFamilyData = Form.useWatch(
    'selectionSupportFamilyData',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line no-unused-vars
  const selectSelectionSupportFamilyData = Form.useWatch(
    'selectSelectionSupportFamilyData',
    {
      preserve: true,
    },
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const changeSelectValue = useCallback((checked, row) => {
    const personHealthNeeds = form.getFieldValue('personHealthNeeds') || []
    const index = personHealthNeeds.findIndex((r) => r.id === row.id)

    if (index !== -1) {
      // If item exists, update personIds with selectedIds
      personHealthNeeds[index].personIds = checked
    } else {
      // If item doesn't exist, add a new entry
      personHealthNeeds.push({
        id: row.id,
        personIds: checked,
        comment: null,
      })
    }

    // Update the form field with the modified array
    form.setFieldsValue({ personHealthNeeds })

    // if (index !== -1) {
    //   const newData = [selectValues]
    //   newData[index].personIds = checked
    //   form.setFieldsValue({ personHealthNeeds: newData })
    // }

    // const index = form
    //   .getFieldValue('personHealthNeeds')
    //   .findIndex((r) => r.id === row.id)
    // const newData = [...form.getFieldValue('personHealthNeeds')]
    //
    // newData[index].personIds = checked
    // form.setFieldsValue({ personHealthNeeds: newData })
  })

  return (
    <FormItem name={nameItem} rules={[{ required: true }]} style={style}>
      <Select
        mode={'multiple'}
        placeholder={'Նշել ընտանիքի անդամին(ներին)'}
        onChange={(e) => {
          changeSelectValue(e, row)
        }}
      >
        {dataFamily?.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.firstName + ' ' + item.lastName}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  )
}

export default SnSelect
