export const URL_FAMILY_CHILDREN = 'api/Person/GetHouseholdTo6YearChilds'
export const URL_PERIOD =
  'api/EducationalOrgAndCare/GetEducationInterruptedPeriod'
export const URL_EDUCATION_SUB_TYPES =
  'api/EducationalOrgAndCare/GetEducationSubtype'
export const URL_EDUCATION_NEED = 'api/EducationalOrgAndCare/GetEducationNeed'
export const URL_EDUCATION_TYPES = 'api/EducationalOrgAndCare/GetEducationType'
export const URL_HOUSE_PERSONS = 'api/Person/GetHouseholdPersons'
export const URL_EDUCATION_SUPPORT =
  'api/EducationalOrgAndCare/GetEducationSupport'
export const URL_EDUCATION_NOT_REASON =
  'api/EducationalOrgAndCare/GetEducationNotAttendReason'
