// eslint-disable-next-line no-unused-vars
import React, { useId, useState } from 'react'
import { Title } from '../../typography'
import { Form } from '../../form/form'
import { FormItem } from '../../form/form-item'
import { Radio, Spin } from 'antd'
import { FamilyMemberEducation } from './person-education'
import { VerticalSpace } from '../../space/vertical-space'
import useGetQuestions, {
  URL_GET_FAMILY_MEMBERS,
} from '../../../api/application/use-get-questions'
import { COLORS } from '../../../helpers/constants'

const EducationForm = ({ question, parentFormItem, disabled }) => {
  // const id = useId()
  const id = `radioButton${question.id}`

  const isSelected = Form.useWatch([parentFormItem, id, 'id'], {
    preserve: true,
  })

  const form = Form.useFormInstance()
  const applicationId = form.getFieldValue('appId')
  const { data: members, isLoading } = useGetQuestions(
    `${URL_GET_FAMILY_MEMBERS}?householdId=${+applicationId}`,
  )

  const formattedMembers = members?.map((member) => {
    return {
      label: `${member.firstName} ${member.lastName} `,
      id: member.id,
    }
  })

  const handleRadioChange = (value, parentFormItem) => {
    form.setFieldsValue({
      [parentFormItem]: { [id]: { memberEducation: [] } },
    })
  }

  return (
    <Spin spinning={isLoading}>
      <VerticalSpace>
        <Title>
          {`${question.id}. ${question.name}`}
          <span style={{ color: COLORS.ALERT.RED, fontWeight: 100 }}>*</span>
        </Title>
        <FormItem
          name={[parentFormItem, id, 'id']}
          rules={[{ required: true }]}
        >
          <Radio.Group
            onChange={(e) => handleRadioChange(e.target.value, parentFormItem)}
            disabled={disabled}
          >
            <Radio value={question.id}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>
        {isSelected && (
          <FamilyMemberEducation
            parentFormItem={[parentFormItem, id]}
            questionId={question.id}
            familyMembers={formattedMembers}
            disabled={disabled}
          />
        )}
      </VerticalSpace>
    </Spin>
  )
}

export default EducationForm
