import React, { useCallback, useEffect, useState } from 'react'
import { Checkbox, Col, Form, Row, Spin } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { URL_GET_NON_VISIT_DOCTOR_REASON } from './constants'
import { Input } from '../../input'
import useGetQuestions from '../../../api/application/use-get-questions'

const createName = (id) => `item${id}`

const ReasonForNotSeeingDoctor = ({ getData }) => {
  const form = Form.useFormInstance()
  // eslint-disable-next-line no-unused-vars
  const [checked, setChecked] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [checkedIds, setCheckedIds] = useState({})

  const { data: seeingDoctorData, isLoading } = useGetQuestions(
    URL_GET_NON_VISIT_DOCTOR_REASON,
  )

  // eslint-disable-next-line no-unused-vars
  const selectFamilyNotSeeingDoctor = Form.useWatch(
    'reasonForNotSeeingDoctor',
    {
      preserve: true,
    },
  )

  useEffect(() => {
    if (getData) {
      const prepareNestedData = (array, keyPrefix) =>
        array?.reduce((acc, curr) => {
          acc[`${keyPrefix}${curr.id}`] = { ...curr }
          return acc
        }, {})
      const reasonsForNotSeeingDoctor = prepareNestedData(
        getData?.householdNonVisitDoctorReasons,
        'NonVisitDoctorReason',
      )

      setCheckedIds(reasonsForNotSeeingDoctor)

      form.setFieldsValue({
        reasonForNotSeeingDoctor: reasonsForNotSeeingDoctor,
      })
    }
  }, [form, getData])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeReasonForNotSeeingDoctor = useCallback((checked, row) => {
    if (checked) {
      form.setFieldsValue({
        householdNonVisitDoctorReasons: [
          ...form.getFieldValue('householdNonVisitDoctorReasons'),
          { id: row.id, comment: null },
        ],
      })
    } else {
      form.setFieldsValue({
        householdNonVisitDoctorReasons: form
          .getFieldValue('householdNonVisitDoctorReasons')
          .filter((r) => r.id !== row.id),
      })
    }
  })

  const onInputChangeNonVisitDoctor = useCallback(
    (value, id) => {
      const index = form
        .getFieldValue('householdNonVisitDoctorReasons')
        .findIndex((r) => r.id === id)
      const newData = [...form.getFieldValue('householdNonVisitDoctorReasons')]
      newData[index].comment = value
      form.setFieldsValue({ householdNonVisitDoctorReasons: newData })
    },
    [form],
  )

  const validateCheckboxGroup = () => {
    const householdNonVisitDoctorReasons =
      form.getFieldValue('householdNonVisitDoctorReasons') || []
    return householdNonVisitDoctorReasons.length > 0
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, row) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))
      setChecked(checked)
      setCheckedIds(checked)
      onChangeReasonForNotSeeingDoctor(checked, row)
      form.resetFields([`reasonForNotSeeingDoctorInput${itemId}`])
    },
    [form, setCheckedIds, onChangeReasonForNotSeeingDoctor],
  )

  return (
    <Spin spinning={isLoading}>
      <FormSectionCard
        title="Ինչն է եղել բժշկին չդիմելու պատճառը"
        showRequired={false}
      >
        <FormItem
          name="householdNonVisitDoctorReasons"
          hidden
          initialValue={[]}
        />

        <FormItem name={['reasonForNotSeeingDoctor']}>
          {seeingDoctorData?.map((row) => {
            const itemName = createName(row.id)

            return (
              <FormItem
                name={['reasonForNotSeeingDoctor', row.label]}
                key={row.id}
                rules={[
                  {
                    validator: () => {
                      return validateCheckboxGroup()
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                            ),
                          )
                    },
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <FormItem
                  name={['reasonForNotSeeingDoctor', row.label, 'id']}
                  valuePropName="checked"
                >
                  <Checkbox
                    onChange={(e) =>
                      handleCheckboxChange(
                        itemName,
                        e.target.checked,
                        row.id,
                        row,
                      )
                    }
                    value={row.id}
                  >
                    {row.name}
                  </Checkbox>
                </FormItem>
                {selectFamilyNotSeeingDoctor?.[row.label]?.id ? (
                  row?.hasComment === true ? (
                    <Row style={{ marginTop: '15px', marginLeft: '25px' }}>
                      <Col span={9}>
                        <FormItem
                          // rules={[{ required: true }]}
                          name={`reasonForNotSeeingDoctorInput${row.id}`}
                        >
                          <Input
                            defaultValue={
                              checkedIds[`NonVisitDoctorReason${row.id}`]
                                ?.comment
                            }
                            placeholder={'Նշել'}
                            onChange={(e) =>
                              onInputChangeNonVisitDoctor(
                                e.target.value,
                                row.id,
                              )
                            }
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  ) : (
                    <FormItem hidden />
                  )
                ) : (
                  <FormItem hidden />
                )}
              </FormItem>
            )
          })}
        </FormItem>
      </FormSectionCard>
    </Spin>
  )
}

export default ReasonForNotSeeingDoctor
