import React, { useEffect, useState } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import CurrentResidence from './current-residence'
import AccommodationUseType from './accommodation-use-type'
import StayPeriod from './stay-period'
import AccommodationRiskType from './accommodation-risk-type'
import NonSafeReasons from './non-safe-reasons'
import { Form } from 'antd'
import BuildingsNearby from './buildings-nearby'

const ThirdStepForm = ({ getData, disabled = false }) => {
  const form = Form.useFormInstance()
  const [countBuildingsNearby, setCountBuildingsNearby] = useState(0)
  const accommodationUseTypeId = Form.useWatch(['accommodationUseTypeId'])
  const accommodationTypeId = Form.useWatch(['accommodationTypeId'])
  const isHideUseType = [4, 9, 10, 11].includes(accommodationTypeId)
  const hasNoAccommodation = accommodationTypeId === 12

  useEffect(() => {
    if (getData) {
      const updatedCheckedIds = {}
      let countNearby = 0
      const householdBuildingTypesList =
        getData?.householdBuildingTypes?.reduce((acc, curr) => {
          if (curr.existsId !== null) {
            countNearby += 1
          }

          acc[curr.id] = {
            ...curr,
            hbtNcgtrs: curr?.hbtNcgtrs?.reduce((acc1, curr1) => {
              acc1[`item${curr1.id}`] = {
                ...curr1,
              }

              updatedCheckedIds[`item${curr1.id}`] = curr1.isActive
              return acc1
            }, {}),
          }
          return acc
        }, {})

      setCountBuildingsNearby(countNearby)

      form.setFieldsValue({
        ...getData,
        accommodationUseTypeId:
          getData?.accommodationUseTypeParentId ||
          getData?.accommodationUseTypeId,
        accommodationRiskTypeId:
          getData?.accommodationRiskTypeParentId ||
          getData?.accommodationRiskTypeId,
        householdBuildingTypes: householdBuildingTypesList,
        'householdBuildingTypes[hbtNcgtrs]': updatedCheckedIds,
      })

      setTimeout(function () {
        form.setFieldsValue({
          accommodationUseTypeParentId: getData?.accommodationUseTypeId || null,
          accommodationRiskTypeParentId:
            getData?.accommodationRiskTypeId || null,
        })
      }, 300)
    }
  }, [form, getData])

  return (
    <VerticalSpace>
      <CurrentResidence disabled={disabled} />

      {hasNoAccommodation === false && isHideUseType === false && (
        <AccommodationUseType getData={getData} disabled={disabled} />
      )}

      {hasNoAccommodation === false && <StayPeriod disabled={disabled} />}

      {hasNoAccommodation === false && accommodationUseTypeId !== 1 && (
        <AccommodationRiskType getData={getData} disabled={disabled} />
      )}

      {(disabled ? countBuildingsNearby !== 0 : true) &&
        hasNoAccommodation === false && <BuildingsNearby disabled={disabled} />}

      {/* {hasNoAccommodation === false && <BuildingTypes />} */}

      {hasNoAccommodation === false && (
        <NonSafeReasons getData={getData} disabled={disabled} />
      )}
    </VerticalSpace>
  )
}

export default ThirdStepForm
