import React, { useEffect, useRef } from 'react'
import { Form } from '../../form'
import NonResideMemberForm from './non-reside-member-form'
import { Button } from '../../../button'
import { PlusCircleOutlined } from '@ant-design/icons'

const ManageFields = ({ fields, add }) => {
  const hasInitialized = useRef(false)
  useEffect(() => {
    if (fields.length === 0 && !hasInitialized.current) {
      add()
      hasInitialized.current = true
    }
  }, [add, fields.length])
}
const NonResideMemberData = ({
  fields,
  add,
  remove,
  parentFormItem,
  getData,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ManageFields fields={fields} add={add} />
      {fields.map((field) => {
        return (
          <NonResideMemberForm
            field={field}
            remove={remove}
            parentFormItem={parentFormItem}
            key={field.name}
            getData={getData}
          />
        )
      })}
      <Button
        type="primary"
        size="large"
        shape="round"
        onClick={() => add()}
        style={{ margin: '20px auto' }}
      >
        <PlusCircleOutlined />
        Ավելացնել նույն վայրում չբնակվող ընտանիքի անդամ
      </Button>
    </div>
  )
}

const AddNonResideMemberForm = ({ getData }) => {
  return (
    <Form.List name={['familyData', 'nonResidePersons']}>
      {(fields, { add, remove }) => (
        <NonResideMemberData
          fields={fields}
          add={add}
          remove={remove}
          parentFormItem={['familyData', 'nonResidePersons']}
          getData={getData}
        />
      )}
    </Form.List>
  )
}

export default AddNonResideMemberForm
