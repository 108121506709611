import React, { useEffect } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { Form, Radio, Spin } from 'antd'
import Input from 'antd/es/input/Input'
import { Text } from '../../typography'
import { COLORS } from '../../../helpers/constants'
import UploadApplicationDocument from './agreement-file-upload'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_AGREEMENT } from './constans'

const PersonAgrrement = ({ householdId, getData, disabled }) => {
  const form = Form.useFormInstance()
  const { data, isLoading } = useGetQuestions(URL_GET_AGREEMENT)
  const selection = Form.useWatch('personAgreement')
  const selectionData = data.find((item) => item.id === selection)
  const possibleDate = Form.useWatch('possibleDate')
  const rejectionReason = Form.useWatch('rejectionReason')
  const personAgreement = Form.useWatch('personAgreement', {
    preserve: true,
  })

  useEffect(() => {
    if (householdId) {
      const findSelectionData = data.find(
        (item) => item.id === getData?.personAgreement?.id,
      )
      form.setFieldsValue({
        personAgreementHidden: findSelectionData,
      })
    }
  }, [data, form, getData, householdId])

  const onChangePersonAgreement = (personAgreementId) => {
    const findSelectionData = data.find((item) => item.id === personAgreementId)
    form.setFieldsValue({
      personAgreementHidden: findSelectionData,
    })
    form.resetFields([
      ['rejectionReason'],
      ['rejectionReasonComment'],
      ['possibleDate'],
      ['possibleDateComment'],
      ['page1notes'],
      ['userEmail'],
      ['hasNotEmail'],
      ['isYourEmail'],
      ['emailFamilyMemberTypeId'],
      ['emailOwnerName'],
      ['hasNotPhoneNumber'],
    ])
  }

  const onChangeRejectionReason = (rejectionReasonId) => {
    const findRejectionData = selectionData?.children?.find(
      (item) => item.id === rejectionReasonId,
    )

    form.setFieldsValue({
      rejectionReasonHidden: findRejectionData,
    })
  }

  const onChangePossibleDate = () => {
    if (householdId) {
      const findSelectionData = data.find(
        (item) => item.id === getData?.personAgreement?.id,
      )
      form.setFieldsValue({
        personAgreementHidden: findSelectionData,
      })
    }
  }

  return (
    <Spin spinning={isLoading}>
      <FormItem hidden name="location">
        <Input />
      </FormItem>
      <VerticalSpace>
        <FormSectionCard title="Անձի համաձայնությունը՝ մասնակցելու գնահատմանը">
          <FormItem name="personAgreementHidden" hidden />
          <FormItem name="rejectionReasonHidden" hidden />
          <FormItem name="personAgreement" rules={[{ required: true }]}>
            <Radio.Group disabled={disabled}>
              <VerticalSpace>
                {data?.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <Radio
                        key={item.id}
                        value={item.id}
                        onChange={(e) =>
                          onChangePersonAgreement(e.target.value)
                        }
                        disabled={disabled}
                      >
                        {item.label}
                      </Radio>
                      {!!(selectionData?.id === 3 && item.id === 3) && (
                        <FormItem
                          name={'possibleDate'}
                          style={{ marginLeft: '30px' }}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            onChange={() => onChangePossibleDate()}
                            disabled={disabled}
                          >
                            <VerticalSpace>
                              {selectionData?.children?.map((item) => {
                                return (
                                  <Radio key={item.id} value={item.id}>
                                    {item.label}
                                  </Radio>
                                )
                              })}
                            </VerticalSpace>
                          </Radio.Group>
                        </FormItem>
                      )}
                      {!!(selectionData?.id === 2 && item.id === 2) && (
                        <FormItem
                          name={'rejectionReason'}
                          style={{ marginLeft: '30px' }}
                          rules={[{ required: true }]}
                        >
                          <Radio.Group
                            onChange={(e) =>
                              onChangeRejectionReason(e.target.value)
                            }
                            disabled={disabled}
                          >
                            <VerticalSpace>
                              {selectionData?.children?.map((item) => {
                                return (
                                  <Radio key={item.id} value={item.id}>
                                    {item.label}
                                  </Radio>
                                )
                              })}
                            </VerticalSpace>
                          </Radio.Group>
                        </FormItem>
                      )}
                      {!!(rejectionReason === 6 && item.id === 2) && (
                        <FormItem
                          rules={[{ required: true }]}
                          name="rejectionReasonComment"
                        >
                          <Input disabled={disabled} />
                        </FormItem>
                      )}
                      {!!(rejectionReason === 5 && item.id === 2) && (
                        <FormItem
                          rules={[{ required: true }]}
                          name="rejectionReasonComment"
                          label={
                            <VerticalSpace>
                              <Text color={COLORS.PRIMARY.BLUE} strong>
                                Ձեռնարկված քայլեր
                              </Text>
                            </VerticalSpace>
                          }
                        >
                          <Input disabled={disabled} />
                        </FormItem>
                      )}
                    </React.Fragment>
                  )
                })}
              </VerticalSpace>
            </Radio.Group>
          </FormItem>
          {personAgreement === 1 && (
            <>
              <FormItem name="fileBase64" hidden />
              <UploadApplicationDocument
                householdId={householdId}
                getData={getData}
                disabled={disabled}
              />
            </>
          )}

          {possibleDate === 3 && (
            <FormItem rules={[{ required: true }]} name="possibleDateComment">
              <Input disabled={disabled} />
            </FormItem>
          )}
        </FormSectionCard>
      </VerticalSpace>
    </Spin>
  )
}

export default PersonAgrrement
