import React, { useEffect } from 'react'
import { NeedsAssessmentTerminationCheck } from './needs-assessment-termination-check'
import { ObservationDirection } from './observation-direction'
import { AnyFamilyMember } from './any-family-member'
import { RecidenceConditions } from './recidence-conditions'
import { Summary } from './summary'
import { Form } from '../../form/form'
import { VerticalSpace } from '../../space/vertical-space'

const FifteenStep = ({ getData, disabled }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      // const updatedCheckedIds = {}
      // const householdBuildingTypesList = data?.householdBuildingTypes?.reduce(
      //   (acc, curr) => {
      //     acc[curr.id] = {
      //       ...curr,
      //       hbtNcgtrs: curr?.hbtNcgtrs?.reduce((acc1, curr1) => {
      //         acc1[`item${curr1.id}`] = {
      //           ...curr1,
      //         }
      //
      //         updatedCheckedIds[`item${curr1.id}`] = curr1.isActive
      //         return acc1
      //       }, {}),
      //     }
      //     return acc
      //   },
      //   {},
      // )

      form.setFieldsValue({
        ...getData,
        householdInformationAffectedFactors:
          getData?.householdInformationAffectedFactors?.reduce(
            (acc, nonWork) => {
              acc[nonWork.id] = {
                ...nonWork,
              }
              return acc
            },
            {},
          ) || {},
        householdTerminationReasons:
          getData?.householdTerminationReasons?.reduce(
            (acc, terminationReason) => {
              acc[terminationReason.id] = {
                ...terminationReason,
              }
              return acc
            },
            {},
          ) || {},
        householdAnyFamilyMember:
          getData?.householdAnyFamilyMember?.reduce((acc, familyMember) => {
            acc[familyMember.id] = {
              ...familyMember,
            }
            return acc
          }, {}) || {},
        householdResidenceConditions:
          getData?.householdResidenceConditions?.reduce((acc, condition) => {
            acc[condition.id] = {
              ...condition,
            }
            return acc
          }, {}) || {},
        householdWorkerActions:
          getData?.householdWorkerActions?.reduce((acc, condition) => {
            acc[condition.id] = {
              ...condition,
            }
            return acc
          }, {}) || {},
        urgentNeeds:
          getData?.urgentNeeds?.reduce((acc, condition) => {
            acc[condition.index] = { ...condition }
            return acc
          }, {}) || {},
        isTermination: getData?.isTermination,
        description: getData?.description,
        // 'householdBuildingTypes[hbtNcgtrs]': updatedCheckedIds,
      })

      setTimeout(function () {
        form.setFieldsValue({
          nestedAccommodationUseTypeId:
            getData?.nestedAccommodationUseTypeId || null,
        })
      }, 300)
    }
  }, [form, getData])

  return (
    <VerticalSpace>
      <NeedsAssessmentTerminationCheck
        backendData={getData}
        disabled={disabled}
      />
      <ObservationDirection
        backendData={getData?.householdObservationDirections}
        disabled={disabled}
      />
      <AnyFamilyMember
        backendData={getData?.householdAnyFamilyMember}
        disabled={disabled}
      />
      <RecidenceConditions disabled={disabled} />
      <Summary backendData={getData?.urgentNeeds} disabled={disabled} />
    </VerticalSpace>
  )
}
export default FifteenStep
