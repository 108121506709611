import { Checkbox, Form, Radio } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import React, { useEffect, useState } from 'react'
import { Title } from '../../typography'
import NestedCheckboxes from './nested-checkboxes'

const RadioButtons = ({
  row,
  index,
  information,
  level = 1,
  onRadioChange,
  isNested,
  onCheckboxChange,
  onInputChange,
  form,
}) => {
  const radioName = `radio_${row.id}`
  const chechboxName = `checkbox_${row.id}`

  const hasChildren = !!row.children.length
  const [checked, setChecked] = useState(form?.getFieldValue(radioName))

  // Sync state with form values if they change externally
  useEffect(() => {
    const value = form.getFieldValue(radioName)
    setChecked(value)
  }, [form, radioName])

  return (
    <div key={row.id} style={{ marginLeft: 30 * level }}>
      <Title>
        {index + 1}. {row.name}
      </Title>

      {!hasChildren && (
        <Form.Item rules={[{ required: true }]} name={radioName}>
          <Radio.Group
            value={checked}
            onChange={(e) => {
              setChecked(e.target.value)
              onRadioChange(e.target.value, row.id, isNested)
            }}
          >
            <VerticalSpace style={{ margin: '15px 30px' }}>
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </VerticalSpace>
          </Radio.Group>
        </Form.Item>
      )}

      {checked && !hasChildren && (
        <div style={{ marginBottom: 30 }}>
          <VerticalSpace>
            <Title>Այս առումով ի՞նչ տեղեկատվության կարիք ունեք</Title>
            <Form.Item name={chechboxName} rules={[{ required: true }]}>
              <Checkbox.Group>
                <VerticalSpace>
                  {information.map((info) => (
                    <NestedCheckboxes
                      key={info.id}
                      row={info}
                      level={1}
                      onCheckboxChange={(value) =>
                        onCheckboxChange(value, info, row.id)
                      }
                      onInputChange={(value, infoId) =>
                        onInputChange(value, infoId, row.id)
                      }
                      form={form}
                      radioName={radioName}
                      id={row.id}
                    />
                  ))}
                </VerticalSpace>
              </Checkbox.Group>
            </Form.Item>
          </VerticalSpace>
        </div>
      )}

      {hasChildren && (
        <div style={{ marginTop: 30 }}>
          {row.children.map((child, idx) => (
            <RadioButtons
              key={child.id}
              row={child}
              index={idx}
              isNested
              information={information}
              level={level + 1}
              onCheckboxChange={onCheckboxChange}
              onInputChange={onInputChange}
              onRadioChange={onRadioChange}
              form={form}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default RadioButtons
