import { Form, Radio, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { COLORS } from '../../../helpers/constants'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_GET_FOOD_ACTION } from './constant'
import { VerticalSpace } from '../../space/vertical-space'
import useGetQuestions from '../../../api/application/use-get-questions'
import { BoldText } from '../../typography/text'

export const NotEnoughMoneyHousehold = ({ getData, disabled }) => {
  const form = Form.useFormInstance()
  const { data: questions } = useGetQuestions(URL_GET_FOOD_ACTION)
  const [foodActions, setFoodActions] = useState({})

  useEffect(() => {
    if (getData) {
      const initialFoodActions = getData?.foodActions?.reduce((acc, curr) => {
        acc[`FoodAction${curr.id}`] = {
          id: curr.id,
          answerId: curr.answerId,
        }
        return acc
      }, {})
      setFoodActions(initialFoodActions)
      form.setFieldsValue({ foodActions: initialFoodActions })
    }
  }, [getData, form])

  const handleRadioChange = (item, selectedValue) => {
    const updatedActions = {
      ...foodActions,
      [`FoodAction${item.id}`]: {
        id: item.id,
        answerId: selectedValue,
      },
    }

    setFoodActions(updatedActions)

    // Sync form with updated state
    form.setFieldsValue({ foodActions: updatedActions })
  }

  return (
    <FormSectionCard
      title={
        'Վերջին 30 օրվա ընթացքում, Ձեր տնային տնտեսության անդամներից որևէ մեկը ստիպված եղե՞լ է կատարել ստորև թվարկված գործողություններից որևէ մեկն այն պատճառով, որ տնային տնտեսությունում չի եղել բավականաչափ սնունդ կամ բավականաչափ փող/գումար սնունդ գնելու համար'
      }
      showRequired={false}
    >
      <Form.Item
        hidden
        name="foodActions"
        initialValue={{ foodActions: foodActions || {} }}
      />
      <Row>
        {questions?.map((item) => (
          <React.Fragment key={item.id}>
            <Form.Item
              name={['foodActions', `FoodAction${item.id}`, 'answerId']}
              rules={[{ required: true }]}
              label={
                <BoldText color={COLORS.PRIMARY.BLUE}>
                  {item.id}. {item.name}
                </BoldText>
              }
            >
              <Radio.Group
                value={foodActions?.[`FoodAction${item.id}`]?.answerId || null}
                onChange={(e) => handleRadioChange(item, e.target.value)}
                disabled={disabled}
                style={{ marginLeft: 25 }}
              >
                <VerticalSpace>
                  <Radio value={1}>Ոչ, որովհետև սննդի պակաս չեմ զգացել</Radio>
                  <Radio value={2}>
                    Ոչ, որովհետև վերջին 12 ամիսների ընթացքում արդեն իրականացրել
                    եմ այդ գործողությունը
                  </Radio>
                  <Radio value={3}>Այո</Radio>
                  <Radio value={4}>Կիրառելի չէ (ՉԿԱՐԴԱԼ)</Radio>
                </VerticalSpace>
              </Radio.Group>
            </Form.Item>
          </React.Fragment>
        ))}
      </Row>
    </FormSectionCard>
  )
}
