import { Button, Form, Modal, message } from 'antd'
import { Title } from '../typography'
import { URL_FORGOT_PASSWORD } from '../../api/auth/constants'
import { client } from '../../api/client'
import { FormItem } from '../form/form-item'
import { Password } from '../input/password'

const ForgotPasswordModal = ({ visible, onClose, username }) => {
  const [form] = Form.useForm()

  const onFinish = async ({ oneTimePassword, newPassword }) => {
    try {
      const response = await client.post(URL_FORGOT_PASSWORD, {
        oneTimePassword,
        newPassword,
        username: username,
      })
      if (response.status === 200) {
        onClose()
        window.location.href = '/'
      }
    } catch (error) {
      message.error('Something went wrong')
    }
  }

  return (
    <Modal
      open={visible}
      closable
      onCancel={onClose}
      maskClosable={false}
      footer={null}
      title={<Title>Խնդրում ենք փոխել Ձեր ծածկագիրը</Title>}
      width={478}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete="off"
      >
        <FormItem
          label="Մեկանգամյա ծածկագիր"
          name="oneTimePassword"
          rules={[{ required: true }]}
        >
          <Password />
        </FormItem>
        <FormItem
          label="Նոր ծածկագիր"
          name="newPassword"
          rules={[
            { required: true },
            { min: 6, message: 'պետք է լինի առնվազն 6 սիմվոլ' },
          ]}
        >
          <Password />
        </FormItem>
        <FormItem
          label="Կրկնել ծածկագիր"
          name="newPassword2"
          dependencies={['newPassword']}
          rules={[
            {
              required: true,
            },
            { min: 6, message: 'պետք է լինի առնվազն 6 սիմվոլ' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('newPassword') === value) {
                  return Promise.resolve()
                }
                return Promise.reject(
                  new Error('Ձեր մուտքագրած նոր գաղտնաբառը չի համընկնում:'),
                )
              },
            }),
          ]}
        >
          <Password />
        </FormItem>

        <FormItem>
          <Button type="primary" block htmlType="submit">
            Հաստատել
          </Button>
        </FormItem>
      </Form>
    </Modal>
  )
}

export default ForgotPasswordModal
