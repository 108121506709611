import React, { useEffect } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import PeopleCommunication from './people-communication'
import LanguagePart from './language-part'
import DrivingLicense from './driving-license'
import RequestMemberName from './request-member-name'
import { Form } from 'antd'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_HOUSE_HOLD_PERSONS } from '../sixth-step/constants'

const EleventhStep = ({ getData, disabled }) => {
  const form = Form.useFormInstance()

  const { data: dataFamily } = useGetQuestions(
    URL_GET_HOUSE_HOLD_PERSONS +
      '?householdId=' +
      parseInt(form.getFieldValue('appId')),
  )
  useEffect(() => {
    if (getData) {
      const mappedData = getData?.householdPersonAbilityQuestions?.map(
        (item) => ({
          ...item,
          ...item?.abilityQuestions?.reduce((acc, radio) => {
            if (radio.id >= 1 && radio.id <= 6) {
              acc[`requestMemberFamilyRadio[${radio.id}][]`] = radio.answerId
            } else if (radio.id >= 7 && radio.id <= 16) {
              acc[`requestMemberFamilyYesNo${radio.id}`] = radio.answerId
            }
            return acc
          }, {}),
        }),
      )

      form.setFieldsValue({
        ...getData,
        requestMemberItems: mappedData,
      })
    }
  }, [form, getData])

  return (
    <VerticalSpace>
      <PeopleCommunication dataFamily={dataFamily} disabled={disabled} />
      <LanguagePart
        dataFamily={dataFamily}
        getData={getData}
        disabled={disabled}
      />
      <DrivingLicense
        dataFamily={dataFamily}
        getData={getData}
        disabled={disabled}
      />
      <RequestMemberName
        dataFamily={dataFamily}
        getData={getData}
        disabled={disabled}
      />
    </VerticalSpace>
  )
}

export default EleventhStep
