import { Checkbox, Form, Space } from 'antd'
import { useEffect, useState } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import { SmallInput } from '../../input/small-input'

const NestedCheckboxes = ({
  row,
  level,
  onCheckboxChange,
  onInputChange,
  form,
  radioName,
  id,
}) => {
  const name = `comment_${id}_${row?.id}`
  const [checked, setChecked] = useState(
    form?.getFieldValue(radioName) || false,
  )
  const hasChildren = !!row.children.length

  useEffect(() => {
    if (!checked) {
      form.resetFields([name])
    }
  }, [checked, name, form])

  return (
    <VerticalSpace>
      <Space>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked)
            onCheckboxChange(e.target.checked, row)
          }}
          value={row.id}
          style={{ marginLeft: 30 * level }}
        >
          {row.name}
        </Checkbox>

        {row.hasComment && checked && (
          <Form.Item name={name} noStyle>
            <SmallInput
              size="small"
              onChange={(e) => onInputChange(e.target.value, row, id)}
            />
          </Form.Item>
        )}
      </Space>

      {hasChildren &&
        checked &&
        row.children.map((child) => (
          <NestedCheckboxes
            key={child.id}
            row={child}
            level={level + 1}
            onCheckboxChange={onCheckboxChange}
            onInputChange={onInputChange}
          />
        ))}
    </VerticalSpace>
  )
}

export default NestedCheckboxes
