import React from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_DATA_OF_CURRENT_RESIDANCE } from './constants'
import { Title } from '../../typography'
import { Form, Radio, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { VerticalSpace } from '../../space/vertical-space'
import { Input } from '../../input'
import { PersonAddress } from '../../form/person-address'
import useGetQuestions from '../../../api/application/use-get-questions'
import useEffectNotFirstRender from '../../../hooks/use-effect-not-first-render'
import { COLORS } from '../../../helpers/constants'

const CurrentResidence = ({ disabled }) => {
  const { data, isLoading } = useGetQuestions(URL_DATA_OF_CURRENT_RESIDANCE)
  const selectedId = Form.useWatch(['accommodationTypeId'])
  const form = Form.useFormInstance()

  useEffectNotFirstRender(() => {
    if (selectedId !== 13) {
      form.resetFields(['accommodationTypeComment'])
    }
  }, [selectedId, form])

  return (
    <FormSectionCard title="Ներկայիս բնակավայրի տվյալներ" showRequired={false}>
      <Spin spinning={isLoading}>
        <PersonAddress disabled={disabled} />

        <Title style={{ marginBottom: '20px' }}>
          Նշեք կացարանի տեսակ
          <span
            style={{
              color: COLORS.ALERT.RED,
              marginLeft: '5px',
              fontWeight: 100,
            }}
          >
            *
          </span>
        </Title>

        <FormItem name="accommodationTypeId" rules={[{ required: true }]}>
          <Radio.Group
            onChange={(e) => {
              if ([4, 9, 10, 11, 12].includes(e.target.value)) {
                form.resetFields(['accommodationUseTypeId'])
              }
              if (e.target.value === 12) {
                form.resetFields([
                  ['accommodationUseTypeId'],
                  ['accommodationStayPeriodId'],
                  ['accommodationRiskTypeId'],
                  ['householdBuildingTypes'],
                  ['safeAccommodation'],
                  ['householdNonSafeReasons'],
                ])
              }
            }}
            disabled={disabled}
          >
            <VerticalSpace>
              {data.map((row) => (
                <Radio key={row.id} value={row.id}>
                  {row.name}
                </Radio>
              ))}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>

        {selectedId === 13 && (
          <FormItem
            name="accommodationTypeComment"
            rules={[{ required: true }]}
          >
            <Input placeholder="Նշել" disabled={disabled} />
          </FormItem>
        )}
      </Spin>
    </FormSectionCard>
  )
}

export default CurrentResidence
