import useGetQuestions from '../../../api/application/use-get-questions'
import React, { useCallback, useEffect, useState } from 'react'
import { Form } from 'antd'
import { FormItem } from '../../form/form-item'
import { Checkbox } from '../../checkbox'
import { FormSectionCard } from '../../card/form-section-card'
import { URL_RECEIPT_SUPPORT_TYPE } from './constants'
import { SmallInput } from '../../input/small-input'
export const SupportWays = ({ getData, disabled }) => {
  const { data } = useGetQuestions(URL_RECEIPT_SUPPORT_TYPE)

  const [checkedIds, setCheckedIds] = useState({})
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      const householdReceiptSupportTypes =
        getData.householdReceiptSupportTypes || []

      const formValues = { householdReceiptSupportTypes: {} }
      const updatedCheckedIds = {}

      householdReceiptSupportTypes.forEach((item) => {
        const itemName = item.id
        formValues.householdReceiptSupportTypes[itemName] = {
          id: item.id,
          comment: item.comment || '',
        }
        updatedCheckedIds[itemName] = true
      })

      form.setFieldsValue(formValues)
      setCheckedIds(updatedCheckedIds)
    }
  }, [getData, form])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['householdReceiptSupportTypes', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: ['householdReceiptSupportTypes', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form],
  )

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[item.id]) {
        return true
      }
      if (item.children && checkedIds[item.id] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = item.id
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={['householdReceiptSupportTypes', itemName]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              disabled={disabled}
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={['householdReceiptSupportTypes', itemName, 'comment']}
              rules={[{ required: true }]}
            >
              <SmallInput disabled={disabled} />
            </FormItem>
          )}
        </React.Fragment>
      )
    })
  }
  return (
    <FormSectionCard
      title={'Աջակցության ստացման ո՞ր եղանակներն են նախընտրելի Ձեզ համար'}
      showRequired={true}
    >
      {renderItems(data)}
    </FormSectionCard>
  )
}

export default SupportWays
