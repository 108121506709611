import React, { useEffect } from 'react'
import { VerticalSpace } from '../../space/vertical-space'
import AccommodationNeeds from './accommodation-needs-form'
import AccommodationUtility from './accommodation-appliances-form'
import AccommodationDamp from './accommodation-damp'
import UtilityFeesHelp from './utility-fees-help-form'
import UtilityNeeds from './utility-needs-form'
import Notes from '../../form/application/notes'
import { Form } from 'antd'

const ForthStepForm = ({ getData, disabled = false }) => {
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      const householdAccommodationRequirements =
        getData?.householdAccommodationRequirements?.reduce((acc, curr) => {
          acc[curr.id] = {
            ...curr,
          }
          return acc
        }, {})

      const utilityServicesList = getData?.utilityServices?.reduce(
        (acc, curr) => {
          acc[`item${curr.id}`] = curr.compensationId
          return acc
        },
        {},
      )

      const productsList = getData?.products?.reduce((acc, curr) => {
        // acc[curr.id] = curr.productId
        acc[curr.id] = {
          ...curr,
        }
        return acc
      }, {})
      // setTimeout(() => {
      form.setFieldsValue({
        ...getData,
        householdAccommodationRequirements: householdAccommodationRequirements,
        accommodationAdaptation: getData?.accommodationAdaptation,
        utilityServices: utilityServicesList,
        page4notes: getData?.notes || '',
        products: productsList,
        prefferedLocation: {
          regionId: getData?.stateId,
          communityId: getData?.communityId,
        },
      })
      // }, 1000)
    }
  }, [getData, form])
  return (
    <VerticalSpace>
      <AccommodationNeeds getData={getData} disabled={disabled} />
      <AccommodationUtility getData={getData} disabled={disabled} />
      <AccommodationDamp disabled={disabled} />
      <UtilityFeesHelp getData={getData} disabled={disabled} />
      <UtilityNeeds getData={getData} disabled={disabled} />
      <Notes formItemName={'page4notes'} disabled={disabled} />
    </VerticalSpace>
  )
}
export default ForthStepForm
