import React, { useEffect, useState } from 'react'
import { FormSectionFullCard } from '../../card/form-section-full-card'
import { VerticalSpace } from '../../space/vertical-space'
import { SecondaryText, Text, Title } from '../../typography'
import { FormItem } from '../../form/form-item'
import { Input } from '../../input'
import { Col, Form, Radio } from 'antd'
import { Checkbox } from '../../checkbox'
import { COLORS } from '../../../helpers/constants'
import { Select } from '../../select'
import { createOptionsFromArray } from '../../../helpers/utils'
import PhoneNumberListForm from './phone-number-list-form'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_GET_FAMILY_MEMBER_TYPE } from './constans'

const PersonContact = ({ getData, disabled }) => {
  const { data } = useGetQuestions(URL_GET_FAMILY_MEMBER_TYPE)
  const [getEmailOwnerName, setEmailOwnerName] = useState(null)
  const hasNotEmail = Form.useWatch('hasNotEmail', {
    preserve: true,
  })
  const isYourEmail = Form.useWatch('isYourEmail', {
    preserve: true,
  })

  const form = Form.useFormInstance()
  const contactPersonId = Form.useWatch('emailFamilyMemberTypeId', {
    preserve: true,
  })

  const onEmailCheck = () => {
    if (!hasNotEmail) {
      form.resetFields([
        ['userEmail'],
        ['isYourEmail'],
        ['emailFamilyMemberTypeId'],
        ['emailFamilyMemberTypeComment'],
        ['emailOwnerName'],
      ])
      setEmailOwnerName(null)
    }
  }

  useEffect(() => {
    if (getData) {
      setEmailOwnerName(getData?.emailOwnerName)
    }
  }, [form, getData])

  const handleEmailOwnerNameChange = (e) => {
    setEmailOwnerName(e.target.value) // Update state when user types
  }

  return (
    <VerticalSpace>
      <FormSectionFullCard>
        <Title style={{ marginBottom: '20px' }}>
          Եթե Դուք կամ ընտանիքի անդամ/ներից որևէ մեկն ունի էլ․ հասցե, խնդրում եմ
          նշել
        </Title>
        <Col span={24}>
          <FormItem
            rules={[
              { required: !hasNotEmail },
              { type: 'email', message: 'Սխալ Էլ․ փոստի ֆորմատ' },
            ]}
            label="Էլ.փոստ"
            name="userEmail"
          >
            <Input disabled={hasNotEmail || disabled} />
          </FormItem>
          <FormItem name="hasNotEmail" valuePropName="checked">
            <Checkbox onChange={onEmailCheck} disabled={disabled}>
              <SecondaryText>Չունի էլ․ հասցե </SecondaryText>
            </Checkbox>
          </FormItem>
        </Col>
        {!hasNotEmail && (
          <>
            <Col>
              <FormItem
                name="isYourEmail"
                rules={[{ required: true }]}
                label={
                  <VerticalSpace>
                    <Text color={COLORS.PRIMARY.BLUE} strong>
                      Նշված է իր էլ․ փոստը
                    </Text>
                  </VerticalSpace>
                }
              >
                <Radio.Group
                  onChange={() => {
                    form.resetFields([
                      'emailFamilyMemberTypeId',
                      'emailFamilyMemberTypeComment',
                      'emailOwnerName',
                    ])
                    setEmailOwnerName(null)
                  }}
                  disabled={disabled}
                >
                  <Radio value={true}>Այո</Radio>
                  <Radio value={false}>Ոչ</Radio>
                </Radio.Group>
              </FormItem>
            </Col>
            {!isYourEmail && (
              <>
                <Col span={24}>
                  <FormItem
                    name="emailFamilyMemberTypeId"
                    rules={[{ required: true }]}
                    label={
                      <VerticalSpace>
                        <Text color={COLORS.PRIMARY.BLUE} strong>
                          Կապը Ձեզ հետ
                        </Text>
                      </VerticalSpace>
                    }
                  >
                    <Select
                      options={createOptionsFromArray(data)}
                      disabled={disabled}
                    />
                  </FormItem>
                </Col>
                {contactPersonId === 31 && (
                  <FormItem
                    name="emailFamilyMemberTypeComment"
                    rules={[{ required: true }]}
                  >
                    <Col span={8}>
                      <Input disabled={disabled} />
                    </Col>
                  </FormItem>
                )}
                <Col span={24}>
                  <FormItem
                    name="emailOwnerName"
                    rules={[{ required: true }]}
                    label={
                      <VerticalSpace>
                        <Text color={COLORS.PRIMARY.BLUE} strong>
                          Նշել էլ․ փոստի տիրոջ անունը
                        </Text>
                      </VerticalSpace>
                    }
                  >
                    <Col span={24}>
                      <Input
                        value={getEmailOwnerName || ''}
                        onChange={handleEmailOwnerNameChange}
                        disabled={disabled}
                      />
                    </Col>
                  </FormItem>
                </Col>
              </>
            )}
          </>
        )}
        <PhoneNumberListForm getData={getData} disabled={disabled} />
      </FormSectionFullCard>
    </VerticalSpace>
  )
}

export default PersonContact
