import React, { useCallback, useEffect, useState } from 'react'
import useGetQuestions from '../../../api/application/use-get-questions'
import { GET_WORKER_ACTION } from './constants'
import { FormSectionCard } from '../../card/form-section-card'
import { Form, Radio, Row, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { SecondaryText, Title } from '../../typography'
import { TextArea } from '../../input'
import { VerticalSpace } from '../../space/vertical-space'
import { Button } from '../../button'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { COLORS } from '../../../helpers/constants'
import { SmallInput } from '../../input/small-input'
import { Checkbox } from '../../checkbox'

export const Summary = ({ backendData, disabled }) => {
  const form = Form.useFormInstance()
  const { data: answers, isLoading } = useGetQuestions(GET_WORKER_ACTION)
  const [checkedIds, setCheckedIds] = useState({})

  const [value, setValue] = useState(null)
  const [textareas, setTextareas] = useState([
    { id: 1, placeholder: 'Նշել', note: '' },
    { id: 2, placeholder: 'Նշել', note: '' },
    { id: 3, placeholder: 'Նշել', note: '' },
  ])
  const [nextId, setNextId] = useState(4) // Track the next unique ID

  const handleTxtAreaNote = (e, id) => {
    const updatedTextareas = textareas.map((textarea) =>
      textarea.id === id ? { ...textarea, note: e.value } : textarea,
    )
    setTextareas(updatedTextareas)
  }
  const deleteTextarea = (id) => {
    setTextareas(textareas.filter((textarea) => textarea.id !== id))
  }
  const addTextarea = () => {
    const newTextareas = [
      ...textareas,
      { id: nextId, placeholder: 'Նշել', note: '' },
    ]
    setTextareas(newTextareas)
    setNextId(nextId + 1)
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['householdWorkerActions', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])

      if (!checked) {
        form.setFields([
          {
            name: ['householdWorkerActions', itemName, 'comment'],
            value: '',
          },
        ])
      }

      if (!checked) {
        const uncheckChildren = (parentId) => {
          const parentItem = answers.find((item) => item.id === parentId)
          if (parentItem && parentItem.children) {
            parentItem.children.forEach((child) => {
              const childName = child.id
              setCheckedIds((prevCheckedIds) => ({
                ...prevCheckedIds,
                [childName]: false,
              }))
              form.setFields([
                {
                  name: ['householdWorkerActions', childName, 'id'],
                  value: false,
                },
                {
                  name: ['householdWorkerActions', childName, 'comment'],
                  value: '',
                },
              ])
              if (child.children && child.children.length > 0) {
                uncheckChildren(childName)
              }
            })
          }
        }
        uncheckChildren(itemName)
      }
    },
    [form, answers],
  )

  useEffect(() => {
    setValue(form.getFieldValue('hasUrgentNeed'))
    const initializeCheckedIds = (items) => {
      const initialChecked = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = item.id
          const isChecked = form.getFieldValue([
            'householdWorkerActions',
            itemName,
          ])
          initialChecked[itemName] = !!isChecked

          if (item.children && item.children.length > 0) {
            traverse(item.children)
          }
        })
      }
      traverse(items)
      return initialChecked
    }
    const initialCheckedIds = initializeCheckedIds(answers)
    setCheckedIds(initialCheckedIds)
  }, [form, answers])

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = item.id
      return (
        <React.Fragment key={item.id}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormItem
              name={['householdWorkerActions', itemName]}
              style={{ marginLeft }}
              rules={[
                {
                  validator: () =>
                    Object.values(checkedIds).some(Boolean)
                      ? Promise.resolve()
                      : Promise.reject(new Error('Պարտադիր դաշտ')),
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Checkbox
                checked={checkedIds[itemName]}
                disabled={disabled}
                onChange={(e) =>
                  handleCheckboxChange(itemName, e.target.checked, item.id)
                }
              >
                {item.name}
              </Checkbox>
            </FormItem>
            {item.hasComment && checkedIds[itemName] && (
              <FormItem
                name={['householdWorkerActions', itemName, 'comment']}
                rules={[{ required: true }]}
              >
                <SmallInput disabled={disabled} size="small" />
              </FormItem>
            )}
          </div>
          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1)}
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionCard title="Ամփոփում" showRequired={true}>
      <Spin spinning={isLoading}>
        <FormItem rules={[{ required: true }]} name="hasUrgentNeed">
          <Radio.Group
            value={value}
            rules={[{ required: true }]}
            disabled={disabled}
            onChange={(e) => {
              setValue(e.target.value)
            }}
          >
            <VerticalSpace>
              <Radio value={true}>Ընտանիքն ունի հրատապ կարիք</Radio>
              <Radio value={false}>Ընտանիքը հրատապ կարիք չունի</Radio>
            </VerticalSpace>
          </Radio.Group>
        </FormItem>
        {value && (
          <VerticalSpace>
            <SecondaryText>
              Խնդրում ենք առաջնահերթությամբ առանձնացնել 3 հրատապ լուծում
              պահանջող կարիքները, որ ունի ընտանիքը
            </SecondaryText>
            {disabled
              ? backendData?.length > 0 &&
                backendData?.map((item) => (
                  <div
                    style={{
                      display: 'grid',
                      width: '100%',
                      alignItems: 'center',
                      gridTemplateColumns: '95% 5%', // 80% for TextArea, 20% for Button
                      gap: '10px',
                    }}
                    key={item.id}
                  >
                    <FormItem rules={[{ required: true }]}>
                      <TextArea
                        disabled={disabled}
                        onChange={(e) => handleTxtAreaNote(e.target, item?.id)}
                        placeholder={item.placeholder}
                        showRequired={true}
                        style={{ width: '100%' }}
                        value={item?.description}
                      />
                    </FormItem>
                    {backendData.length > 3 && (
                      <Button
                        onClick={() => deleteTextarea(item.id)}
                        style={{ borderRadius: '0', marginLeft: '10px' }}
                        disabled={disabled}
                      >
                        <MinusOutlined
                          style={{ color: COLORS.PRIMARY.BLUE, padding: 0 }}
                        />
                      </Button>
                    )}
                  </div>
                ))
              : textareas?.map((item) => (
                  <div
                    style={{
                      display: 'grid',
                      width: '100%',
                      alignItems: 'center',
                      gridTemplateColumns: '95% 5%', // 80% for TextArea, 20% for Button
                      gap: '10px',
                    }}
                    key={item.id}
                  >
                    <FormItem
                      name={['urgentNeeds', item?.id, 'description']}
                      rules={[{ required: true }]}
                    >
                      <TextArea
                        disabled={disabled}
                        onChange={(e) => handleTxtAreaNote(e.target, item?.id)}
                        placeholder={item.placeholder}
                        showRequired={true}
                        style={{ width: '100%' }}
                      />
                    </FormItem>
                    {textareas.length > 3 && (
                      <Button
                        onClick={() => deleteTextarea(item.id)}
                        style={{ borderRadius: '0', marginLeft: '10px' }}
                      >
                        <MinusOutlined
                          style={{ color: COLORS.PRIMARY.BLUE, padding: 0 }}
                        />
                      </Button>
                    )}
                  </div>
                ))}
            <Button
              onClick={(text) => addTextarea(text)}
              style={{ borderRadius: '0' }}
              disabled={disabled}
            >
              <PlusOutlined
                style={{ color: COLORS.PRIMARY.BLUE, padding: 0 }}
              />
            </Button>
          </VerticalSpace>
        )}
        <Row>
          <React.Fragment>
            <FormItem
              rules={[{ required: true }]}
              initialValue={[]}
              name={'householdWorkerActions'}
            >
              <Title>
                Կարիքների գնահատման ընթացքում ի՞նչ գործողություններ եք կատարել
                <span
                  style={{
                    color: COLORS.ALERT.RED,
                    marginLeft: '5px',
                    fontWeight: 100,
                  }}
                >
                  *
                </span>
              </Title>
              <br />
              {renderItems(answers)}
            </FormItem>
          </React.Fragment>
        </Row>
        <FormItem
          name="description"
          label={<Title>Լրացուցիչ մեկնաբանություններ</Title>}
        >
          <TextArea
            disabled={disabled}
            style={{ marginTop: 20 }}
            placeholder="Նշել"
          />
        </FormItem>
      </Spin>
    </FormSectionCard>
  )
}
