import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormItem } from '../../form-item'
import { SecondaryText } from '../../../typography'
import { Form } from '../../form'
import { Checkbox } from '../../../checkbox'
import { Input } from '../../../input'
import { nonResideReasons } from '../../../fill-application/constatns'
import { Select } from '../../../select'
import { createOptionsFromArray } from '../../../../helpers/utils'
import { Radio } from 'antd'
import { VerticalSpace } from '../../../space/vertical-space'
// import RedCrossAgreement from './agree-to-pass-red-cross'

const createName = (id) => `item${id}`

const NonResideReasons = ({ field, parentFormItem, getData }) => {
  const [checkedIds, setCheckedIds] = useState({})
  const form = Form.useFormInstance()

  const initialState = useMemo(() => {
    if (getData) {
      const nonResidePersons = getData?.familyData?.nonResidePersons || []

      const allPersons = [...nonResidePersons]

      const initialCheckedState = allPersons.reduce((acc, item) => {
        item?.personNotResideReasons?.forEach((supportType) => {
          acc[`item${supportType.id}`] = {
            ...supportType,
            agreeRedCross: supportType?.agreeRedCross,
            applyRedCrossTypeId: supportType?.applyRedCrossTypeId,
            comment: supportType?.comment || '',
          }
        })
        return acc
      }, {})

      return {
        initialCheckedState,
      }
    }
    return {}
  }, [getData])

  useEffect(() => {
    if (initialState) {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [field.name]: {
          ...initialState.initialCheckedState,
        },
      }))
      // setCheckedIds(initialState.initialCheckedState || {})
    }
  }, [field.name, initialState])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, fieldName) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [
            ...parentFormItem,
            field.name,
            'personNotResideReasons',
            itemName,
            fieldName,
          ],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [field.name, 'personNotResideReasons', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form, parentFormItem, field.name],
  )

  const handleRadioChange = useCallback(
    (itemName, checked) => {
      const updatedCheckedIds = {
        ...checkedIds,
        [itemName]: {
          ...checkedIds[itemName],
          agreeRedCross: checked,
        },
      }

      setCheckedIds(updatedCheckedIds)

      // form.setFields([
      //   {
      //     name: [
      //       ...parentFormItem,
      //       field.name,
      //       'personNotResideReasons',
      //       itemName,
      //       'agreeRedCross',
      //     ],
      //     value: checked,
      //   },
      // ])
    },
    [checkedIds],
  )

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)
      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[field.name, 'personNotResideReasons', itemName]}
            style={{ marginLeft }}
            label={
              item.title ? <SecondaryText>{item.title}</SecondaryText> : ''
            }
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            {level !== 0 && item.type === 'select' ? (
              <>
                <Select
                  options={createOptionsFromArray(item.options)}
                  value={checkedIds[itemName]?.applyRedCrossTypeId}
                  onChange={(e) =>
                    handleCheckboxChange(
                      itemName,
                      true,
                      e,
                      'applyRedCrossTypeId',
                    )
                  }
                />
                {item?.passRedCross && (
                  // <FormItem
                  //   name={[
                  //     ...parentFormItem,
                  //     field.name,
                  //     'personNotResideReasons',
                  //     itemName,
                  //     'agreeRedCross',
                  //   ]}
                  //   label={
                  //     <SecondaryText>Արդյո՞ք համաձայն եք...</SecondaryText>
                  //   }
                  //   rules={[{ required: true }]}
                  // >
                  <VerticalSpace>
                    <SecondaryText>
                      Արդյո՞ք համաձայն եք, որ այս տեղեկատվությունը փոխանցվի
                      Կարմիր խաչի միջազգային կոմիտե
                    </SecondaryText>
                    <Radio.Group
                      value={checkedIds[itemName]?.agreeRedCross}
                      onChange={(e) =>
                        handleRadioChange(itemName, e.target.value)
                      }
                    >
                      <Radio value={true}>Այո</Radio>
                      <Radio value={false}>Ոչ</Radio>
                    </Radio.Group>
                  </VerticalSpace>
                  // </FormItem>
                  // <RedCrossAgreement
                  //   // formItemProp={[
                  //   //   ...parentFormItem,
                  //   //   field.name,
                  //   //   'personNotResideReasons',
                  //   //   itemName,
                  //   //   'agreeRedCross',
                  //   // ]}
                  //   formItemProp={[
                  //     field.name,
                  //     'personNotResideReasons',
                  //     itemName,
                  //   ]}
                  //   value={checkedIds[itemName]?.agreeRedCross}
                  // />
                )}
              </>
            ) : (
              <Checkbox
                checked={checkedIds[itemName]}
                onChange={(e) =>
                  handleCheckboxChange(
                    itemName,
                    e.target.checked,
                    item.id,
                    'id',
                  )
                }
              >
                {item.label}
              </Checkbox>
            )}
          </FormItem>

          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[field.name, 'personNotResideReasons', itemName, 'comment']}
              // rules={[{ required: true }]}
              style={{ marginLeft }}
              label={item?.inputLabel || ''}
            >
              <Input
                placeholder="Նշել"
                defaultValue={checkedIds[itemName]?.comment || ''}
              />
            </FormItem>
          )}

          {item.children &&
            item.children.length > 0 &&
            checkedIds[itemName] &&
            renderItems(item.children, level + 1, itemName)}
        </React.Fragment>
      )
    })
  }
  return (
    <FormItem
      label={
        <SecondaryText>
          Խնդրում եմ նշել, ձեզ հետ ներկայումս չլինելու (այս կացարանում
          չբնակվելու) պատճառ/ները
        </SecondaryText>
      }
      name={[...parentFormItem, field.name, 'personNotResideReasons']}
    >
      {renderItems(nonResideReasons)}
    </FormItem>
  )
}

export default NonResideReasons
