import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from '../../form'
import { FormItem } from '../../form-item'
import { SecondaryText, Title } from '../../../typography'
import { Input } from '../../../input'
import {
  medicalSupportFrequency,
  nonMedicalSupportReasons,
  physycalSupportOptions,
} from '../../../fill-application/constatns'
import { Checkbox, Radio } from 'antd'
import { VerticalSpace } from '../../../space/vertical-space'
import { FormSectionFullCard } from '../../../card/form-section-full-card'

const createName = (id) => `item${id}`

const PhysicalSupportForm = ({ parentProp, field, getData }) => {
  const [medicalSupportIds, setMedicalSupportIds] = useState({})
  const [supportFrequency, setSupportFrequency] = useState({})
  const [supportFrequencyNumb, setSupportFrequencyNumb] = useState({})
  const [nonSupportReasonIds, setNonSupportReasonIds] = useState({})
  const [guardianName, setGuardianName] = useState('')
  const [hasGuardian, setHasGuardian] = useState(null)
  const [isMember, setIsMember] = useState(null)
  const form = Form.useFormInstance()

  const initialState = useMemo(() => {
    if (getData) {
      const familyMembers = getData?.familyData?.familyMembers || []
      const displacedPersons = getData?.familyData?.displacedPersons || []
      const nonResidePersons = getData?.familyData?.nonResidePersons || []

      const allPersons = [
        ...familyMembers,
        ...displacedPersons,
        ...nonResidePersons,
      ]

      const initialCheckedState = allPersons.reduce((acc, item) => {
        item?.personNonMedicalSupportReasons?.forEach((supportType) => {
          acc[`item${supportType.id}`] = {
            ...supportType,
            comment: supportType?.comment,
          }
        })
        return acc
      }, {})

      const initialCheckedStateTypes = allPersons.reduce((acc, item) => {
        item?.personMedicalSupportTypes?.forEach((supportType) => {
          acc[`item${supportType.id}`] = {
            ...supportType,
            comment: supportType?.comment,
          }
        })
        return acc
      }, {})

      const initialGuardianValue = allPersons.find(
        (member) => member.hasGuardian !== undefined,
      )?.hasGuardian

      const initialGuardianFamilyMemberValue = allPersons.find(
        (member) => member.guardianFamilyMember !== undefined,
      )?.guardianFamilyMember

      form.setFields([
        {
          name: [...parentProp, field.name, 'hasGuardian'],
          value: initialGuardianValue,
        },
        {
          name: [...parentProp, field.name, 'guardianFamilyMember'],
          value: initialGuardianFamilyMemberValue,
        },
      ])

      const initialGuardianNameValue = allPersons.find(
        (member) => member.guardianName !== undefined,
      )?.guardianName

      const initialmedicalFrequency = allPersons.find(
        (member) => member.medicalFrequencyTypeId !== null,
      )?.medicalFrequencyTypeId

      setSupportFrequencyNumb(initialmedicalFrequency)

      setGuardianName(initialGuardianNameValue)

      return {
        initialCheckedState,
        initialCheckedStateTypes,
        initialGuardianValue,
        initialGuardianFamilyMemberValue,
      }
    }
    return {}
  }, [field, form, getData, parentProp])

  useEffect(() => {
    if (initialState) {
      setNonSupportReasonIds(initialState.initialCheckedState || {})
      setMedicalSupportIds(initialState.initialCheckedStateTypes || {})
      setHasGuardian(initialState.initialGuardianValue)
      setIsMember(initialState.initialGuardianFamilyMemberValue)
    }
  }, [form, field, initialState])

  const physicalInjury = Form.useWatch(
    [...parentProp, field.name, 'physicalInjury'],
    {
      preserve: true,
    },
  )

  const hadAppliedToDoctor = Form.useWatch(
    [...parentProp, field.name, 'medicalSupport'],
    {
      preserve: true,
    },
  )
  const continueMedicalSupport = Form.useWatch(
    [...parentProp, field.name, 'continueMedicalSupport'],
    {
      preserve: true,
    },
  )

  // const handleCheckboxChange = (
  //   itemName,
  //   checked,
  //   itemId,
  //   setState,
  //   itemProp,
  // ) => {
  //   setState((prevCheckedIds) => ({
  //     ...prevCheckedIds,
  //     [itemName]: checked,
  //   }))
  //   form.setFieldsValue({
  //     [itemProp]: { [itemName]: { id: checked ? itemId : false } },
  //   })
  // }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId, setState, itemProp) => {
      setState((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [...parentProp, field.name, itemProp, itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: [...parentProp, field.name, itemProp, itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form, parentProp, field.name],
  )
  const validateCheckboxGroup = (items, state) => {
    for (const item of items) {
      if (state[createName(item.id)]) {
        return true
      }
      if (item.children && state[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = useMemo(
    () =>
      (items, level = 0, state, setState, itemProp) => {
        const marginLeft = level * 20
        return items?.map((item) => {
          const itemName = createName(item.id)
          return (
            <React.Fragment key={item.id}>
              <FormItem
                name={[field.name, itemProp, itemName]}
                style={{ marginLeft }}
                rules={[
                  {
                    validator: () =>
                      validateCheckboxGroup(items, state)
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(
                              'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                            ),
                          ),
                    validateTrigger: 'onSubmit',
                  },
                ]}
              >
                <Checkbox
                  checked={state[itemName]}
                  onChange={(e) =>
                    handleCheckboxChange(
                      itemName,
                      e.target.checked,
                      item.id,
                      setState,
                      itemProp,
                    )
                  }
                >
                  {item.label}
                </Checkbox>
              </FormItem>
              {item.hasComment && state[itemName] && (
                <FormItem
                  name={[field.name, itemProp, itemName, 'comment']}
                  // rules={[{ required: true }]}
                  style={{ marginLeft }}
                >
                  <Input defaultValue={state[itemName]?.comment || ''} />
                </FormItem>
              )}
              {/* Checking if user selected first option (հիվանդանոցային պայմաններում) */}
              {!!(
                medicalSupportIds?.item1 &&
                item.id === 1 &&
                hadAppliedToDoctor &&
                physicalInjury
              ) && (
                <>
                  <FormItem
                    rules={[{ required: true }]}
                    label={
                      <SecondaryText>
                        Արդյոք նա ունի խնամող (գիշերակացով կամ առանց գիշերակացի)
                      </SecondaryText>
                    }
                    name={[field.name, 'hasGuardian']}
                  >
                    <Radio.Group
                      value={hasGuardian}
                      onChange={(e) => {
                        const value = e.target.value
                        setHasGuardian(e.target.value)
                        form.setFields([
                          {
                            name: [...parentProp, field.name, 'hasGuardian'],
                            value: value,
                          },
                        ])
                      }}
                    >
                      <Radio value={true}>Այո</Radio>
                      <Radio value={false}>Ոչ</Radio>
                    </Radio.Group>
                  </FormItem>
                  {hasGuardian && (
                    <FormItem
                      label={
                        <SecondaryText>
                          Արդյոք խնամողն ընտանիքի անդամ է
                        </SecondaryText>
                      }
                      style={{ marginLeft: '20px' }}
                      rules={[{ required: true }]}
                      name={[
                        field.name,
                        // itemProp,
                        // itemName,
                        'guardianFamilyMember',
                      ]}
                    >
                      <Radio.Group
                        value={isMember}
                        onChange={(e) => setIsMember(e.target.value)}
                      >
                        <Radio value={true}>Այո</Radio>
                        <Radio value={false}>Ոչ</Radio>
                      </Radio.Group>
                    </FormItem>
                  )}
                  {!!(isMember && hasGuardian) && (
                    <FormItem
                      style={{ marginLeft: '20px' }}
                      rules={[{ required: true }]}
                      name={[field.name, 'guardianName']}
                      label={'Նշել անունը'}
                    >
                      <Input defaultValue={guardianName} />
                    </FormItem>
                  )}
                </>
              )}
              {item.children &&
                item.children.length > 0 &&
                state[itemName] &&
                renderItems(item.children, level + 1, itemName)}
            </React.Fragment>
          )
        })
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      medicalSupportIds,
      hadAppliedToDoctor,
      physicalInjury,
      form,
      parentProp,
      field.name,
      hasGuardian,
      isMember,
      handleCheckboxChange,
    ],
  )
  const handleRadioChange = (option, e, setState) => {
    setState({
      id: e.target.value,
      ...(option.hasComment ? { comment: '' } : null),
    })
    //
    // // Resetting comment field if the radio changes
    // form.setFieldsValue({
    //   [field.name]: {
    //     ...form.getFieldValue([field.name]),
    //     [option.id]: e.target.value ? '' : undefined,
    //   },
    // })
  }

  return (
    <FormSectionFullCard>
      <FormItem
        name={[field.name, 'physicalInjury']}
        label={
          <Title>
            Արդյո՞ք բռնի տեղահանման հետևանքով/ ընթացքում ստացել եք ֆիզիկական
            վիրավորում/վնասվածք
          </Title>
        }
        rules={[{ required: true }]}
      >
        <Radio.Group>
          <Radio value={true}>Այո</Radio>
          <Radio value={false}>Ոչ</Radio>
        </Radio.Group>
      </FormItem>
      {physicalInjury && (
        <FormItem
          name={[field.name, 'medicalSupport']}
          rules={[{ required: true }]}
          label={<Title>Արդյո՞ք ստացել եք բժշկական աջակցություն</Title>}
        >
          <Radio.Group>
            <Radio value={true}>Այո</Radio>
            <Radio value={false}>Ոչ</Radio>
          </Radio.Group>
        </FormItem>
      )}

      {hadAppliedToDoctor && physicalInjury && (
        <>
          <FormItem name={[field.name, 'personMedicalSupportTypes']}>
            {renderItems(
              physycalSupportOptions,
              0,
              medicalSupportIds,
              setMedicalSupportIds,
              'personMedicalSupportTypes',
            )}
          </FormItem>
          <FormItem
            name={[field.name, 'continueMedicalSupport']}
            label={
              <SecondaryText>
                Արդյո՞ք նա շարունակում է օգտվել այդ բժշկական օգնությունից և
                սպասարկումից
              </SecondaryText>
            }
            rules={[{ required: true }]}
          >
            <Radio.Group>
              <Radio value={true}>Այո</Radio>
              <Radio value={false}>Ոչ</Radio>
            </Radio.Group>
          </FormItem>
          {continueMedicalSupport && (
            <>
              <FormItem
                name={[field.name, 'medicalFrequencyTypeId']}
                rules={[{ required: true }]}
              >
                <Radio.Group>
                  <VerticalSpace>
                    {medicalSupportFrequency.map((option) => {
                      return (
                        <Radio
                          value={option.id}
                          key={option.id}
                          onChange={(e) =>
                            handleRadioChange(option, e, setSupportFrequency)
                          }
                        >
                          {option.label}
                        </Radio>
                      )
                    })}
                  </VerticalSpace>
                </Radio.Group>
              </FormItem>
              {(Object.prototype.hasOwnProperty.call(
                supportFrequency,
                'comment',
              ) ||
                supportFrequencyNumb === 5) && (
                <FormItem
                  name={[field.name, 'medicalFrequencyTypeComment']}
                  // rules={[{ required: true }]}
                >
                  <Input />
                </FormItem>
              )}
            </>
          )}
        </>
      )}
      {hadAppliedToDoctor === false && physicalInjury && (
        <FormItem
          label={
            <SecondaryText>
              Ո՞րն է բուժում չստանալու (կամ բուժումը չշարունակելու) պատճառը
            </SecondaryText>
          }
          name={[field.name, 'personNonMedicalSupportReasons']}
        >
          {renderItems(
            nonMedicalSupportReasons,
            0,
            nonSupportReasonIds,
            setNonSupportReasonIds,
            'personNonMedicalSupportReasons',
          )}
        </FormItem>
      )}
    </FormSectionFullCard>
  )
}

export default PhysicalSupportForm
