import React from 'react'
import useGetQuestions, {
  URL_GET_WORK_TYPES,
} from '../../../api/application/use-get-questions'
import { Form } from '../../form/form'
import { Radio, Spin } from 'antd'
import { FormItem } from '../../form/form-item'
import { Title } from '../../typography'
import CurrentWorkDescription from './current-work-description'
import NonWorkForm from './non-work-form'
import { VerticalSpace } from '../../space/vertical-space'
import { Info } from '../../typography/info'

const CurrentWorkForm = ({ field, disabled }) => {
  const form = Form.useFormInstance()
  const { data: questions, isLoading } = useGetQuestions(URL_GET_WORK_TYPES)

  const afterHasWork = Form.useWatch(
    ['personWorks', field.name, 'afterHasWork'],
    {
      preserve: true,
    },
  )

  const onChangeAfterHasWork = () => {
    const fieldPath = ['personWorks', field.name, 'afterWorkTypes']
    form.setFieldsValue({
      personWorks: {
        [field.name]: {
          afterWorkTypes: {},
        },
      },
    })
    form.resetFields([fieldPath, ['personWorks', field.name, 'wantsToWork']])
  }

  return (
    <Spin spinning={isLoading}>
      <FormItem
        label={
          <VerticalSpace>
            <Title>Ներկայումս աշխատու՞մ եք</Title>
            <Info
              text={'ՑՈՒՑՈՒՄ. Նշեք, թե ինչ աշխատանք/աշխատանքներ է իրականացնում'}
            />
          </VerticalSpace>
        }
        name={[field.name, 'afterHasWork']}
        rules={[{ required: true }]}
      >
        <Radio.Group
          disabled={disabled}
          onChange={() => onChangeAfterHasWork()}
        >
          <Radio value={1}>Այո</Radio>
          <Radio value={2}>Ոչ</Radio>
          <Radio value={3}>Հրաժարվում է պատասխանել</Radio>
        </Radio.Group>
      </FormItem>
      {afterHasWork === 1 && (
        <CurrentWorkDescription
          field={field}
          questions={questions || []}
          disabled={disabled}
        />
      )}
      {/* Check always for false value, because initially checkbox value is undefined */}
      {afterHasWork === 2 && (
        <NonWorkForm
          field={field}
          questions={questions || []}
          disabled={disabled}
        />
      )}
    </Spin>
  )
}

export default CurrentWorkForm
