/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { Radio } from 'antd'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_BUILDING_TYPE } from './constants'
import { Form } from '../../form/form'
import { SecondaryText, Title } from '../../typography'
import { Input } from '../../input'
import Difficulties from './difficulties-to-get'

const BuildingsNearby = ({ disabled }) => {
  const form = Form.useFormInstance()
  const { data: buildingTypes } = useGetQuestions(URL_BUILDING_TYPE)
  const data =
    Form.useWatch('householdBuildingTypes', {
      preserve: true,
    }) || []
  // const data = form.getFieldValue('householdBuildingTypes')
  const dataArray = Object.values(data)
  const onCHangeCanGoThere = (value, form, typeId) => {
    if (value === true) {
      form.resetFields([['householdBuildingTypes', typeId, 'hbtNcgtrs']])
    }
  }

  const onCHangeExistsId = (value, form, typeId) => {
    form.resetFields([
      ['householdBuildingTypes', typeId, 'whereItIs'],
      ['householdBuildingTypes', typeId, 'isPlaygroundSafe'],
      ['householdBuildingTypes', typeId, 'canGoThere'],
      ['householdBuildingTypes', typeId, 'hbtNcgtrs'],
    ])

    if (typeId === 19) {
      form.resetFields([['householdBuildingTypes', typeId, 'comment']])
    }
  }

  return (
    <FormSectionCard
      title="Հիմնական կառույցների առկայությունը և հասանելիությունը բնակավայրում"
      showRequired={false}
    >
      <FormItem
        name="householdBuildingTypes"
        label={<Title>Արդյո՞ք Ձեր բնակավայրում կա</Title>}
      >
        {buildingTypes.map((type) => {
          return (
            <React.Fragment key={type.id}>
              <FormItem name={['householdBuildingTypes', type.id]}>
                <FormItem
                  name={['householdBuildingTypes', type.id, 'buildingTypeId']}
                  initialValue={type.id}
                  hidden
                />
                <FormItem
                  name={['householdBuildingTypes', type.id, 'existsId']}
                  // rules={[{ required: true }]}
                  label={<SecondaryText>{type.name}</SecondaryText>}
                >
                  <Radio.Group
                    onChange={(e) =>
                      onCHangeExistsId(e.target.value, form, type.id)
                    }
                    disabled={disabled}
                  >
                    <Radio value={1}>Այո</Radio>
                    <Radio value={2}>Ոչ</Radio>
                    {type.id !== 19 && <Radio value={3}>Չգիտեմ</Radio>}
                  </Radio.Group>
                </FormItem>
                {!!(
                  dataArray?.some(
                    (item) =>
                      item?.existsId === 1 && item?.buildingTypeId === type.id,
                  ) && type.hasComment
                ) && (
                  <FormItem
                    name={['householdBuildingTypes', type.id, 'comment']}
                  >
                    <Input disabled={disabled} />
                  </FormItem>
                )}
                {dataArray?.some(
                  (item) =>
                    item?.existsId === 1 && item?.buildingTypeId === type.id,
                ) &&
                  type.id !== 19 && (
                    <div style={{ marginLeft: 20 }}>
                      <FormItem
                        name={['householdBuildingTypes', type.id, 'whereItIs']}
                        label={<Title>Գիտե՞ք թե որտեղ է գտնվում այն</Title>}
                        rules={[{ required: true }]}
                      >
                        <Radio.Group disabled={disabled}>
                          <Radio value={true}>Այո</Radio>
                          <Radio value={false}>Ոչ</Radio>
                        </Radio.Group>
                      </FormItem>
                      {type.id === 6 && (
                        <FormItem
                          rules={[{ required: true }]}
                          name={[
                            'householdBuildingTypes',
                            type.id,
                            'isPlaygroundSafe',
                          ]}
                          label={
                            <Title>
                              Արդյո՞ք խաղահրապարակը անվտանգ է երեխա/ների համար
                            </Title>
                          }
                        >
                          <Radio.Group disabled={disabled}>
                            <Radio value={1}>Այո</Radio>
                            <Radio value={2}>Ոչ</Radio>
                            <Radio value={3}>Դժվարանում եմ պատասխանել</Radio>
                          </Radio.Group>
                        </FormItem>
                      )}
                      <FormItem
                        rules={[{ required: true }]}
                        name={['householdBuildingTypes', type.id, 'canGoThere']}
                        label={
                          <Title>
                            Եթե կարիքն ունենաք այդտեղ գնալու, կկարողանաք առանց
                            դժվարությունների հասնել այնտեղ
                          </Title>
                        }
                      >
                        <Radio.Group
                          onChange={(e) =>
                            onCHangeCanGoThere(e.target.value, form, type.id)
                          }
                          disabled={disabled}
                        >
                          <Radio value={true}>Այո</Radio>
                          <Radio value={false}>Ոչ</Radio>
                        </Radio.Group>
                      </FormItem>
                      {dataArray?.some(
                        (item) =>
                          item?.canGoThere === false &&
                          item?.buildingTypeId === type.id,
                      ) && (
                        <FormItem
                          name={[
                            'householdBuildingTypes',
                            type.id,
                            'hbtNcgtrs',
                          ]}
                        >
                          <Difficulties
                            parentFormItem={[
                              'householdBuildingTypes',
                              type.id,
                              'hbtNcgtrs',
                            ]}
                            disabled={disabled}
                          />
                        </FormItem>
                      )}
                    </div>
                  )}
                <FormItem hidden />
              </FormItem>
            </React.Fragment>
          )
        })}
      </FormItem>
    </FormSectionCard>
  )
}

export default BuildingsNearby
