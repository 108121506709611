import React, { useCallback, useState } from 'react'
import { useApplicationSteps } from '../../context/applicaton-steps-context'
import { Form, Input } from 'antd'
import { SubmitButton } from '../button'
import { VerticalFormSpace } from '../space/vertical-form-space'
import {
  createUrlForCurrentStep,
  useSaveApplication,
} from '../../api/application/use-save-application'
import { errorMessage, successModal } from '../../helpers/utils'
import { FormItem } from '../form/form-item'
import {
  modifyPage10Data,
  modifyPage12Data,
  modifyPage14Data,
  modifyPage2Data,
  modifyPage3Data,
  modifyPage7Data,
  transformBeforeDataToArray,
  transformDataArray,
  transformDataToArray,
  transformObjectToList,
} from './utils'
import { useLocation } from 'react-router-dom'

const FillApplicationFormWrapper = ({ children, ...props }) => {
  const { step, jump } = useApplicationSteps()
  const [optionPath, setOptionPath] = useState(null)
  const location = useLocation()
  const data = location.state
  const [form] = Form.useForm()
  const id = form.getFieldValue('appId')
  const { mutate } = useSaveApplication(
    createUrlForCurrentStep(optionPath, step + 1),
    {
      onSuccess: (data) => {
        if (data.data.serialNumber) {
          successModal(data.data.serialNumber)
          return
        }
        if (data.data) {
          form.setFieldsValue({
            appId: data.data,
          })
          if (step === 0) {
            form.setFieldsValue({
              appId: data.data.householdId,
            })
          }
        }
        if (step === 11) jump(13)
        if (step !== 14) jump(step + 1)
      },
      onError: (error) => {
        errorMessage(error)
      },
    },
  )

  const onFinish = useCallback(
    (values) => {
      if (step === 0) {
        const dataToSave = {
          householdId: data?.householdId || null,
          location: values?.location || {
            longitude: null,
            latitude: null,
            address: '',
          },
          personAgreement: values?.personAgreementHidden,
          userEmail: values?.userEmail,
          personName: values.personName,
          personSurname: values?.personSurname,
          personPatronymic: values?.personPatronymic,
          periodId: values?.possibleDate || null,
          periodComment: values?.possibleDateComment || null,
          isYourEmail: values?.isYourEmail || false,
          emailOwnerName: values?.emailOwnerName || null,
          emailFamilyMemberTypeId: values?.emailFamilyMemberTypeId || null,
          emailFamilyMemberTypeComment:
            values?.emailFamilyMemberTypeComment || null,
          rejectionReason: {
            ...values?.rejectionReasonHidden,
            comment: values?.rejectionReasonComment || '',
          },
          document: values?.fileBase64 || null,
          phoneNumbers: values?.phoneNumberList || [],
          note: values?.page1notes || '',
        }
        setOptionPath('PersonConsent') // 1
        mutate(dataToSave)
      }

      if (step === 1) {
        let dataToSave = modifyPage2Data(values, id)
        setOptionPath('FamilyInfo') // 2
        mutate({
          ...dataToSave,
        })
      }

      if (step === 2) {
        const dataToSave = modifyPage3Data(values)
        setOptionPath('CurrentLocality') // 3
        mutate({ ...dataToSave, householdId: id })
      }

      if (step === 3) {
        const itemsProducts = values.products
          ? Object.fromEntries(
              Object.entries(values.products).filter(([key]) =>
                key.startsWith('item'),
              ),
            )
          : {}

        let dataToSave = {
          householdId: id,
          accommodationAdaptation: values?.accommodationAdaptation || {
            bathroom: null,
            comment: '',
            door: null,
            handles: null,
            isActive: null,
            kitchen: null,
            other: null,
            ramp: null,
          },
          stateId: values?.prefferedLocation?.regionId || null,
          communityId: values?.prefferedLocation?.communityId || null,
          isDamp: values.isDamp || null,
          notes: values?.page4notes || '',
          householdAccommodationRequirements:
            values?.householdAccommodationRequirements
              ? Object.values(values?.householdAccommodationRequirements)
                  .filter((item) => item?.id)
                  .map((item) => {
                    return {
                      id: item.id,
                      comment: item.comment ? item.comment.toString() : '',
                    }
                  })
              : [],
          ...(values.state ? { stateId: values.state } : {}),
          ...(values.community ? { communityId: values.community } : {}),
          ...(values.address ? { addressId: values.address } : {}),
          addressId: values.addressId || null,
          rentCompAmount: values.rentCompAmount || null,
          hasUtilityServiceSupport: values?.hasUtilityServiceSupport || null,
          hasHousekeepingNeeds: values?.hasHousekeepingNeeds,
          changeAccommodationReason: values?.changeAccommodationReason || '',
          utilityServices: values.utilityServices
            ? Object.entries(values.utilityServices).map(([key, value]) => ({
                id: parseInt(key.replace('item', '')),
                compensationId: value,
              }))
            : [],
          // ? Object.values(values.utilityServices)
          //     .filter((item) => item?.id)
          //     .map((item) => {
          //       return {
          //         id: item.id,
          //         compensationId: item.compensationId,
          //         uniqueId: item.uniqueId,
          //         isActive: item.isActive,
          //       }
          //     })
          // : [],
          products: itemsProducts
            ? Object.values(itemsProducts)
                .filter((item) => item?.id)
                .map((item) => {
                  return {
                    id: item.id,
                    productId: item.productId,
                    uniqueId: item.uniqueId,
                    isActive: item.isActive,
                    comment: `${item.comment || ''}`,
                  }
                })
            : [],
          accommodationAppliances: {
            ...values?.accommodationAppliances,
            wc: values?.accommodationAppliances?.WC,
          },
        }
        setOptionPath('CommunalLiving') // 4
        mutate({ ...dataToSave })
      }

      if (step === 4) {
        let dataToSave = {
          householdId: id,
          foodSuppliesPeriodId: values.foodSuppliesPeriodId,
          useCash: values.useCashValue || false,
          useCashless: values.useCashlessValue || false,
          useCredit: values.useCreditValue || false,
          foodActions: Object.values(values.foodActions).filter(
            (item) => Object.keys(item).length > 0,
          ),
          foodSource: Object.values(values.foodSource).filter(
            (item) => item !== null && item !== undefined && item?.id !== false,
          ),
          foodStrategies: Object.values(values.foodStrategies),
        }
        setOptionPath('NutritionAssistance') // 5
        mutate(dataToSave)
      }

      if (step === 5) {
        let dataToSave = {
          householdId: id,
          // 1 in block
          personHealthNeeds: values.personHealthNeeds,
          // 2 rd block
          personHealthSituations: values.personHealthSituations,
          // 3 rd block
          visitDoctor: values.visitDoctor,
          householdNonVisitDoctorReasons:
            values.visitDoctor === true
              ? values.householdNonVisitDoctorReasons
              : [],
          registeredPolyclinicId:
            values.visitDoctor === false ? values.registeredPolyclinicId : null,
          isSatisfiedPolyclinic:
            values.registeredPolyclinicId === 1
              ? values.isSatisfiedPolyclinic === 1
                ? true
                : false
              : null,
          householdNonSatisfiedPolyclinicReasons:
            values.isSatisfiedPolyclinic === 1
              ? values.householdNonSatisfiedPolyclinicReasons
              : [],
          householdNonRegisteredPolyclinicReasons:
            values?.registeredPolyclinicId === 2 ||
            values?.registeredPolyclinicId === 3
              ? values?.householdNonRegisteredPolyclinicReasons?.filter(
                  (item) => item?.personId,
                )
              : [],
          // 4 rd block
          hasChildrenHealthProblem: values.hasChildrenHealthProblem,
          childrenVisitPolyclinic:
            values.hasChildrenHealthProblem === 2 ||
            values.hasChildrenHealthProblem === 3
              ? null
              : values.childrenVisitPolyclinic,
          householdNonChildrenVisitPolyclinicReasons:
            values.hasChildrenHealthProblem === 1 ||
            values.hasChildrenHealthProblem === 3
              ? []
              : values.householdNonChildrenVisitPolyclinicReasons,
          reasonsForNotBeingChildren: values.reasonsForNotBeingChildren,
          reasonsForNotBeingAdult: values.reasonsForNotBeingAdult,
          // 5 rd block
          householdPharmacyTypes: values.householdPharmacyTypes,
        }
        setOptionPath('ProfessionalHealthcare') // 6
        mutate(dataToSave)
      }

      if (step === 6) {
        const dataToSave = modifyPage7Data(
          values?.householdPersonEducationTypes,
        )

        setOptionPath('EducationalOrgAndCare') // 7
        mutate({ ...dataToSave, householdId: id })
      }

      if (step === 7) {
        let dataToSave = {
          householdId: id,
          personWorks: transformDataArray(values?.personWorks),
        }

        setOptionPath('Activity') // 8
        mutate(dataToSave)
      }

      if (step === 8) {
        let dataToSave = {
          householdId: id,
          beforeMonthlyIncomePeriodId: values?.beforeMonthlyIncomePeriodId,
          afterMonthlyIncomePeriodId: values?.afterMonthlyIncomePeriodId,
          householdBeforeIncomeTypes: transformBeforeDataToArray(
            values?.householdBeforeIncomeTypes,
          ),
          householdAfterIncomeTypes: transformDataToArray(
            values?.householdAfterIncomeTypes,
          ),
          // householdHaveToActions: values?.householdHaveTypeValue,
          householdHaveToActions: Object.values(
            values?.householdHaveToActions,
          ).filter(
            (item) =>
              item !== null &&
              item !== undefined &&
              item?.id !== false &&
              item?.id !== null,
          ),
          householdGoods: values?.householdGoodsValues,
        }

        setOptionPath('FamilyCoping') // 9
        mutate(dataToSave)
      }

      if (step === 9) {
        const dataToSave = modifyPage10Data(values)

        setOptionPath('SupportNetwork') // 10
        mutate({ ...dataToSave, householdId: id })
      }

      if (step === 10) {
        let dataToSave = {
          householdId: id,
          isDifficultyLanguage: values.isDifficultyLanguage,
          householdDifficultyLanguages:
            values.isDifficultyLanguage === true
              ? values.householdDifficultyLanguages
              : [],
          hasForeignLanguage: values.hasForeignLanguage,
          hasDrivingLicense: values.hasDrivingLicense,
          householdDrivingLicenses:
            values.hasDrivingLicense === true
              ? values.householdDrivingLicenses
              : [],
          householdLanguages:
            values.hasForeignLanguage === true ? values.householdLanguages : [],
          // householdPersonAbilityQuestions: Object.values(
          //     values.householdPersonAbilityQuestions.filter(
          //         (item) => item.rowId !== undefined,
          //     ),
          // ),
          householdPersonAbilityQuestions:
            values.householdPersonAbilityQuestions,
        }

        setOptionPath('FamilyAbilities') // 11
        mutate(dataToSave)
      }

      if (step === 11) {
        const dataToSave = {
          ...modifyPage12Data(values),
          familyRelationshipId: values?.familyRelationshipId?.map((item) => ({
            id: item,
          })),
          homeRelationshipId: values?.homeRelationshipId?.map((item) => ({
            id: item,
          })),
          familyRelationshipComment: values?.familyRelationshipComment,
          homeRelationshipComment: values?.homeRelationshipComment,
          householdNonSafetyPeople: values?.householdNonSafetyPeople?.map(
            (person) => ({
              id: person.personId,
              personNonSafetyTypes: person.personNonSafetyTypes.map((id) => ({
                id,
                comment: person[`comment_${id}`] || null,
              })),
            }),
          ),
        }
        setOptionPath('Relationship') // 12
        mutate(
          { ...dataToSave, householdId: id },
          {
            onSuccess: () => jump(13),
          },
        )
      }

      if (step === 12) {
        // let dataToSave = {
        //   householdId: id,
        //   question132AnswareId: values.question132AnswareId,
        //   question132AnswareComment: values.question132AnswareComment
        //     ? values.question132AnswareComment
        //     : null,
        //   question134AnswareId: values.question134AnswareId,
        //   question134AnswareComment: values.question134AnswareComment
        //     ? values.question134AnswareComment
        //     : null,
        //   question135AnswareId: values.question135AnswareId,
        //   householdCitizenshipCircumstances:
        //     values.citizenshipCircumstancesValue,
        //   states: values.states ? values.states : null,
        //   area: values.area ? values.area : null,
        // }
        // // setOptionPath('StatusAndMovement') // 13
        // setOptionPath('Questionnaire') // 13
        // mutate(dataToSave)
      }

      if (step === 13) {
        const dataToSave = modifyPage14Data(values)

        setOptionPath('AwarenessPreferences') // 14
        mutate({ ...dataToSave, householdId: id })
      }

      if (step === 14) {
        let dataToSave = {
          ...values,
          householdId: id,
          householdObservationDirections: values.householdObservationDirections,
          householdResidenceConditions:
            values?.householdResidenceConditions.filter(
              (item) => item?.answerId !== undefined,
            ),
          householdInformationAffectedFactors:
            values?.householdInformationAffectedFactors.filter(
              (item) => item?.id !== undefined,
            ),
          householdTerminationReasons:
            values?.householdTerminationReasons?.filter(
              (item) => item?.id != null,
            ),
          householdWorkerActions: transformObjectToList(
            values.householdWorkerActions,
          ).filter((item) => item !== null || undefined),
          householdAnyFamilyMember: values.householdAnyFamilyMember.filter(
            (item) => item != null && item.id !== false,
          ),
          comment: values?.comment || '',
          urgentNeeds:
            values &&
            (values?.urgentNeeds || [])
              .filter((item) => item?.description !== undefined)
              .map((item, index) => ({
                id: index + 1,
                description: item.description,
              })),
        }

        setOptionPath('Summary') // 15
        mutate(dataToSave)
      }

      localStorage.setItem('jumpValue', step + 1)
    },
    [data, id, jump, mutate, step],
  )

  return (
    <Form
      name="application-fill-form"
      layout="vertical"
      onFinish={onFinish}
      form={form}
      {...props}
      disabled={data?.disabled}
    >
      {children}
      <VerticalFormSpace>
        <SubmitButton
          submitText={step === 14 ? 'Ավարտել' : 'Հաջորդ'}
          canceltext="Նախորդ"
          showCancelButton={step === 0 ? false : true}
          onCancel={() => {
            if (step === 13) {
              jump(step - 2)
            } else {
              jump(step - 1)
            }
          }}
          step={step}
        />
      </VerticalFormSpace>

      <FormItem name={'appId'}>
        <Input type={'hidden'} />
      </FormItem>
    </Form>
  )
}

export default FillApplicationFormWrapper
