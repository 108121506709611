import React, { useCallback, useEffect, useState } from 'react'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { Form, Radio, Checkbox } from 'antd'
import { VerticalSpace } from '../../space/vertical-space'
import useGetQuestions from '../../../api/application/use-get-questions'
import { URL_FRIEND_SUPPORT_TYPE } from './constants'
import { SmallInput } from '../../input/small-input'

const SupportFriend = ({ getData, disabled }) => {
  const { data } = useGetQuestions(URL_FRIEND_SUPPORT_TYPE)
  const [checkedIds, setCheckedIds] = useState({})
  const [hasSupportFriend, setSupportFriend] = useState({})
  const form = Form.useFormInstance()

  useEffect(() => {
    if (getData) {
      const householdFriendSupportTypeIds =
        getData.householdFriendSupportTypeIds || []

      const formValues = { householdFriendSupportTypeIds: {} }
      const updatedCheckedIds = {}

      householdFriendSupportTypeIds.forEach((item) => {
        const itemName = item.id
        formValues.householdFriendSupportTypeIds[itemName] = {
          id: item.id,
          comment: item.comment || '',
        }
        updatedCheckedIds[itemName] = true
      })

      form.setFieldsValue(formValues)
      setCheckedIds(updatedCheckedIds)
    }
  }, [getData, form])

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: ['householdFriendSupportTypeIds', itemName, 'id'],
          value: checked ? itemId : false,
        },
      ])
      // Resetting comment when parent is unchecked
      if (!checked) {
        form.setFields([
          {
            name: ['householdFriendSupportTypeIds', itemName, 'comment'],
            value: '',
          },
        ])
      }
    },
    [form],
  )

  const onChangeFriend = (e) => {
    setSupportFriend(e.target.value)
    form.resetFields(['householdFriendSupportTypeIds'])
  }

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[item.id]) {
        return true
      }
      if (item.children && checkedIds[item.id] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const renderItems = (items, level = 2) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = item.id
      return (
        <React.Fragment key={item.id}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormItem
              name={['householdFriendSupportTypeIds', itemName]}
              style={{ marginLeft }}
              rules={[
                {
                  validator: () =>
                    validateCheckboxGroup(items)
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                          ),
                        ),
                  validateTrigger: 'onSubmit',
                },
              ]}
            >
              <Checkbox
                disabled={disabled}
                checked={checkedIds[itemName]}
                onChange={(e) =>
                  handleCheckboxChange(itemName, e.target.checked, item.id)
                }
              >
                {item.name}
              </Checkbox>
            </FormItem>
            {item.hasComment && checkedIds[itemName] && (
              <FormItem
                name={['householdFriendSupportTypeIds', itemName, 'comment']}
                style={{ marginLeft, width: '200px' }}
                rules={[{ required: true }]}
              >
                <SmallInput size={'small'} disabled={disabled} />
              </FormItem>
            )}
          </div>
        </React.Fragment>
      )
    })
  }

  return (
    <FormSectionCard
      title="Արդյոք Դուք կամ Ձեր ընտանիքի անդամները Հայաստանում ունեն ընկեր, հարազատ, ծանոթ անձ, ով Ձեզ աջակցում է:"
      showRequired={true}
    >
      <FormItem name="hasSupportFriend" rules={[{ required: true }]}>
        <Radio.Group onChange={(e) => onChangeFriend(e)} disabled={disabled}>
          <VerticalSpace>
            <Radio value={1}>Այո</Radio>
            {(hasSupportFriend === 1 ||
              form.getFieldValue('hasSupportFriend') === 1) &&
              renderItems(data)}
            <Radio value={2}>Ոչ</Radio>
            <Radio value={3}>Չի ցանկանում պատասխանել</Radio>
          </VerticalSpace>
        </Radio.Group>
      </FormItem>
    </FormSectionCard>
  )
}

export default SupportFriend
