/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react'
import useGetQuestions from '../../../../api/application/use-get-questions'
import { Form } from '../../../form/form'
import { FormItem } from '../../../form/form-item'
import { Input } from '../../../input'
import { VerticalSpace } from '../../../space/vertical-space'
import {
  URL_BAD_ATTITUDE_DEMONSTRATION_TYPES,
  URL_BAD_ATTITUDE_TYPES,
} from '../constants'
import { Checkbox } from 'antd'
import { Title } from '../../../typography'

const createName = (id) => `item${id}`

const AttitudeManifestation = ({ parentFormItem }) => {
  const { data: types } = useGetQuestions(URL_BAD_ATTITUDE_DEMONSTRATION_TYPES)

  const form = Form.useFormInstance()

  const [checkedIds, setCheckedIds] = useState({})

  const validateCheckboxGroup = (items) => {
    for (const item of items) {
      if (checkedIds[createName(item.id)]) {
        return true
      }
      if (item.children && checkedIds[createName(item.id)] !== undefined) {
        if (validateCheckboxGroup(item.children)) {
          return true
        }
      }
    }
    return false
  }

  const handleCheckboxChange = useCallback(
    (itemName, checked, itemId) => {
      setCheckedIds((prevCheckedIds) => ({
        ...prevCheckedIds,
        [itemName]: checked,
      }))

      form.setFields([
        {
          name: [
            ...parentFormItem,
            'householdBadAttitudeDemonstrationTypes',
            itemName,
            'id',
          ],
          value: checked ? itemId : false,
        },
      ])
    },
    [form, parentFormItem],
  )

  useEffect(() => {
    if (!types) return // Ensure data is loaded
    const setInitialCheckedState = (items) => {
      const initialCheckedIds = {}
      const traverse = (itemList) => {
        itemList.forEach((item) => {
          const itemName = createName(item.id)
          const isChecked = form.getFieldValue([
            ...parentFormItem,
            'householdBadAttitudeDemonstrationTypes',
            itemName,
            'isActive',
          ])
          initialCheckedIds[itemName] = !!isChecked
        })
      }
      traverse(items)
      return initialCheckedIds
    }

    const initialCheckedIds = setInitialCheckedState(types)
    setCheckedIds(initialCheckedIds)
  }, [form, parentFormItem, types])

  const renderItems = (items, level = 0) => {
    const marginLeft = level * 20
    return items.map((item) => {
      const itemName = createName(item.id)

      return (
        <React.Fragment key={item.id}>
          <FormItem
            name={[
              ...parentFormItem,
              'householdBadAttitudeDemonstrationTypes',
              itemName,
            ]}
            style={{ marginLeft }}
            rules={[
              {
                validator: () =>
                  validateCheckboxGroup(items)
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Խնդրում ենք ընտրել տարբերակներից առնվազն մեկը',
                        ),
                      ),
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Checkbox
              checked={checkedIds[itemName]}
              onChange={(e) =>
                handleCheckboxChange(itemName, e.target.checked, item.id)
              }
            >
              {item.name}
            </Checkbox>
          </FormItem>
          {item.hasComment && checkedIds[itemName] && (
            <FormItem
              name={[
                ...parentFormItem,
                'householdBadAttitudeDemonstrationTypes',
                itemName,
                'comment',
              ]}
              label={'Նշել'}
              rules={[{ required: true }]}
              style={{ marginLeft }}
            >
              <Input />
            </FormItem>
          )}
        </React.Fragment>
      )
    })
  }

  return (
    <>
      <div style={{ paddingLeft: 30 }}>
        <Title>Ի՞նչպես է դրսևորվել</Title>
        <FormItem
          name={[...parentFormItem, 'householdBadAttitudeDemonstrationTypes']}
        >
          {renderItems(types)}
        </FormItem>
      </div>
    </>
  )
}

export default AttitudeManifestation
