import React, { useState } from 'react'
import { Form, Radio, Row } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { FormItem } from '../../form/form-item'
import { VerticalSpace } from '../../space/vertical-space'
import ReasonForNotSeeingDoctor from './reason-for-not-seeing-doctor'
import PolyclinicServingTheArea from './polyclinic-serving-the-area'

const FamilyNeededToDoctor = ({ getData }) => {
  const form = Form.useFormInstance()
  const [value, setValue] = useState(null)

  // eslint-disable-next-line no-unused-vars
  const selectFamilyNeededToDoctor = Form.useWatch('visitDoctor', {
    preserve: true,
  })

  return (
    <FormSectionCard
      title="Վերջին մեկ ամսվա ընթացքում, եղե՞լ են դեպքեր, երբ Դուք, կամ Ձեր ընտանիքից որևէ մեկը կարիք է ունեցել դիմելու բժշկի, բայց չի դիմել"
      showRequired={true}
    >
      <Row>
        <FormItem
          name={'visitDoctor'}
          style={{ width: '100%' }}
          rules={[{ required: true }]}
        >
          <Radio.Group
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              form.resetFields([
                ['householdNonVisitDoctorReasons'],
                ['householdNonSatisfiedPolyclinicReasons'],
                ['reasonsForNotBeingAdult'],
                ['requestMemberItemsreasonsForNotBeingAdult'],
                ['reasonsForNotBeingChildren'],
                ['requestMemberItemsselectChildren'],
                ['registeredPolyclinicId'],
                ['reasonForNotSeeingDoctor'],
              ])
            }}
            style={{ width: '100%' }}
          >
            <VerticalSpace>
              <Radio value={true}>Այո</Radio>
              {selectFamilyNeededToDoctor === true && (
                <ReasonForNotSeeingDoctor getData={getData} />
              )}
              <Radio value={false}>Ոչ</Radio>
              {selectFamilyNeededToDoctor === false && (
                <PolyclinicServingTheArea getData={getData} />
              )}
            </VerticalSpace>
          </Radio.Group>
        </FormItem>
      </Row>
    </FormSectionCard>
  )
}

export default FamilyNeededToDoctor
