import React from 'react'
import { Radio } from 'antd'
import { FormSectionCard } from '../../card/form-section-card'
import { VerticalSpace } from '../../space/vertical-space'
import { FormItem } from '../../form/form-item'
import { foodSupplies } from './constant'

export const HowLongFoodSupplies = ({ disabled }) => {
  return (
    <FormSectionCard
      title={'Ձեր սննդի պաշարները դեռևս ինչքան կբավարարեն'}
      showRequired={true}
    >
      <FormItem name="foodSuppliesPeriodId" rules={[{ required: true }]}>
        <Radio.Group disabled={disabled}>
          <VerticalSpace>
            {foodSupplies.map((item) => {
              return (
                <Radio key={item.id} value={item.id}>
                  {item.label}
                </Radio>
              )
            })}
          </VerticalSpace>
        </Radio.Group>
      </FormItem>
    </FormSectionCard>
  )
}
